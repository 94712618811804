import { useEffect, useState } from 'react'

import { isEmpty, isPlainObject, without } from 'lodash'

import { PeachOverrideId, peachOverridesOptions } from './config'
import { PeachOverrideText, PeachOverrideVariant } from './lib/Override'

const KEY = 'PEACH_OVERRIDES'

type StoredOverrides = Record<keyof typeof peachOverridesOptions, PeachOverrideVariant['value']>
type CallbackFn = (val: PeachOverrideVariant['value'] | undefined) => void

export const setOverrides = (newOverrides: StoredOverrides) => {
  try {
    if (isEmpty(newOverrides)) {
      localStorage.removeItem(KEY)
    } else {
      localStorage.setItem(KEY, JSON.stringify(newOverrides))
    }
  } catch {
  } finally {
    triggerAll()
  }
}

export const getOverrides = (): StoredOverrides => {
  try {
    const val = JSON.parse(localStorage.getItem(KEY) ?? '{}')
    return isPlainObject(val) ? val : {}
  } catch {
    return {} as StoredOverrides
  }
}

export const getOverride = (id: PeachOverrideId): PeachOverrideText['value'] | undefined => {
  const overrides = getOverrides()
  return overrides[id] as PeachOverrideText['value'] | undefined
}

export const useOverride = (id: PeachOverrideId, fallback?: PeachOverrideVariant['value']) => {
  const [val, setVal] = useState<PeachOverrideVariant['value'] | undefined>(() => getOverride(id))

  useEffect(() => {
    const onChange: CallbackFn = (val) => setVal(val)
    bind(id, onChange)
    return () => unbind(id, onChange)
  }, [id])

  return overridesEnabled && exists(val) ? val : fallback
}

export const overridesEnabled = localStorage ? !!localStorage.getItem('ENABLE_PEACH_OVERRIDES') : false

const callbacks: Record<PeachOverrideId, CallbackFn[]> = {} as Record<PeachOverrideId, CallbackFn[]>

const exists = (val: any) => val !== null && val !== undefined

const triggerAll = () => {
  for (const [id, callbacksForId] of Object.entries(callbacks)) {
    if (callbacksForId && callbacksForId.length) {
      for (const callback of callbacksForId) {
        callback(getOverride(id as PeachOverrideId))
      }
    }
  }
}

const bind = (id: PeachOverrideId, fn: CallbackFn) => {
  if (!callbacks[id]) callbacks[id] = []
  callbacks[id] = callbacks[id].concat(fn)
}

const unbind = (id: PeachOverrideId, fn: CallbackFn) => {
  callbacks[id] = without(callbacks[id], fn)
}
