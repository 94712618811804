import React, { useState } from 'react'

import { redirectToApp } from 'app/loginRedirect/loginRedirectHelpers'
import { AuthTypes, isAuthType } from 'noauth/pages/Login/LoginWrapper/LoginInfoProvider'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import {
  getSessionExpired,
  getStoredToken,
  decodeToken,
  setSessionExpired,
  setStoredToken,
  isTokenExpired,
  isTokenValid,
  setSessionExpiredIfRecent,
} from 'core/actions/helpers/tokenHelpers'
import { Alert, Spinner } from 'core/components'
import { useOnMount, useSetPageTitle } from 'core/hooks'
import variables from 'core/styles/variables'
import { useValidateAndSendToken } from 'core/types'

import BasicPasswordLogin from './Basic/BasicPasswordLogin'
import CodeLogin from './CodeLogin'
import { loginWithPassword } from './loginApi'
import LoginErrorMessage from './LoginErrorMessage'
import { useLoginInfo } from './LoginWrapper'
import SamlLogin from './SAML/SamlLogin'
import useSamlParams from './SAML/useSamlParams'

const AlertWrapper = styled.div`
  max-width: 560px;
  margin: 0 auto 18px;
`

export const storeAuthData = (token: string, username?: string) => {
  const { personId, userType } = decodeToken(token)
  setStoredToken(token)
  localStorage.setItem('personIdFromToken', JSON.stringify(personId))
  localStorage.setItem('userTypeFromToken', userType)
  if (username) {
    localStorage.setItem('username', username)
  } else {
    localStorage.removeItem('username')
  }
}

const LoginManager = () => {
  const { hasSamlCreds, immediatelyRedirect } = useSamlParams()
  const { userType, companyId, authType } = useLoginInfo()
  useSetPageTitle({ userType })

  const { search } = useLocation()
  const exchangeToken = new URLSearchParams(search).get('token')

  const [showSessionExpired, setShowSessionExpired] = useState(false)

  const {
    mutateAsync: sendToken,
    isLoading: isExchangeTokenLoading,
    error: exchangeTokenError,
  } = useValidateAndSendToken({
    query: { companyId, userType },
    options: {
      onError: undefined,
    },
  })

  const dispatch = useDispatch()

  const onSuccess = (token: string, username?: string) => {
    storeAuthData(token, username)
    dispatch({ type: 'GENERATE_TOKEN_SUCCESS' })
    redirectToApp()
  }

  const onLogin = async ({
    username,
    password,
    selectedAuthType = authType,
  }: {
    username: string
    password: string
    selectedAuthType?: AuthTypes
  }) => {
    const resp = await loginWithPassword({
      username,
      password,
      companyId,
      userType,
      authType: selectedAuthType,
    })
    onSuccess(resp.token, username)
    return resp
  }

  const token = getStoredToken()
  const isValidAuthType = isAuthType(authType)

  useOnMount(async () => {
    // Handle SAML login in <SamlLogin />
    if (authType === 'SAML' && (hasSamlCreds || immediatelyRedirect)) return

    // Token. Remove token from localStorage if expired
    if (isValidAuthType) {
      if (token && isTokenExpired(token)) {
        setSessionExpiredIfRecent()
        setStoredToken('')
      } else if (isTokenValid(token)) {
        onSuccess(token)
      }
    }
    // show session expiry message only once and remove flag from storage
    if (getSessionExpired()) {
      setSessionExpired(false)
      setShowSessionExpired(true)
    }

    if (exchangeToken) {
      try {
        const resp = await sendToken({ exchangeToken })
        onSuccess(resp.token)
      } catch {} // Error is handled using `exchangeTokenError`
    }
  })

  return (
    exchangeToken ?
      <LoginErrorMessage>
        {exchangeTokenError ?
          (exchangeTokenError as any)?.message
        : isExchangeTokenLoading ?
          <Spinner />
        : undefined}
      </LoginErrorMessage>
    : isValidAuthType ?
      <>
        {showSessionExpired && (
          <AlertWrapper>
            <Alert
              icon='hourglass_empty'
              iconFill={variables.colorBlack80}
              header='Session Timed Out'
              description='Your session timed out. Please log in again to access your account.'
              bgColor={variables.colorYellowLighten}
              margin='0'
            />
          </AlertWrapper>
        )}
        {authType === 'basic' && <BasicPasswordLogin onLogin={onLogin} />}
        {(authType === 'oneTimeCodeText' || authType === 'oneTimeCodeEmail') && <CodeLogin onLogin={onLogin} />}
        {authType === 'SAML' && <SamlLogin onLogin={onLogin} />}
      </>
    : <LoginErrorMessage>Unsupported Auth Type</LoginErrorMessage>
  )
}

export default LoginManager
