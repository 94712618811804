import { createSelector, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'

import { AppState } from 'core/store'

export type ToastProps = {
  id: string
  message: string
  type: 'error' | 'warning'
}

export type ToastAddedPayload = Omit<ToastProps, 'id'>

type ToastsState = ToastProps[]

const initialState: ToastsState = []

const { actions, reducer } = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    toastAdded: (state, action: PayloadAction<ToastAddedPayload>) => {
      return [
        ...state,
        {
          id: nanoid(),
          ...action.payload,
        },
      ]
    },
    toastRemoved: (state, action: PayloadAction<ToastProps['id']>) => {
      return state.filter((t) => t.id !== action.payload)
    },
  },
})

export default reducer
export const { toastAdded, toastRemoved } = actions

export const selectAllToasts = createSelector(
  (state: AppState) => state.global.toasts,
  (value) => value,
)
