import { FC } from 'react'

import styled from 'styled-components'

import Textarea, { TextareaProps } from './Textarea'

const TextareaAutoHeight: FC<Omit<TextareaProps, 'height'>> = ({ value, rows = 3, ...props }) => (
  <Box rows={rows}>
    <StyledTextarea value={value} {...props} />
    <GrowBox>{value}&#8203;</GrowBox>
  </Box>
)

export default TextareaAutoHeight

const Box = styled.div<{ rows: number }>`
  position: relative;
  width: 100%;
  min-height: ${(p) => p.rows * 24 + 18}px;
`

const StyledTextarea = styled(Textarea)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const GrowBox = styled.div`
  padding: 8px;
  font-size: 16px;
  white-space: pre-wrap;
  visibility: hidden;
  border: 1px solid transparent;
`
