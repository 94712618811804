/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { useQueryClient } from 'react-query'

import {
  useTypedQuery,
  QueryHookArguments,
  useTypedMutation,
  MutationHook,
  GetOptionsFunction,
} from 'core/system/queries'
import { isError, useErrorHandler } from 'core/types/utils'

import * as api from './api'
import * as Types from './types'

export const getPeachyOptions: GetOptionsFunction<Types.Peachy> = ({ query, options }) => ({
  queryKey: ['peachy', query ?? {}],
  queryFn: () => api.peachy({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Is it peachy?`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const usePeachy = (args: QueryHookArguments<Types.Peachy>) => useTypedQuery<Types.Peachy>(getPeachyOptions(args))

export const getPitOptions: GetOptionsFunction<Types.Pit> = ({ query, options }) => ({
  queryKey: ['peachy', 'pit', query ?? {}],
  queryFn: () => api.pit({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Check the pit`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const usePit = (args: QueryHookArguments<Types.Pit>) => useTypedQuery<Types.Pit>(getPitOptions(args))

export const getInternalGetEventsOptions: GetOptionsFunction<Types.InternalGetEvents> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'events', query ?? {}],
  queryFn: () => api.internalGetEvents({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Retrieve events`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetEvents = (args: QueryHookArguments<Types.InternalGetEvents>) =>
  useTypedQuery<Types.InternalGetEvents>(getInternalGetEventsOptions(args))

export const useInternalCreateEvent: MutationHook<Types.InternalCreateEvent> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create event`)

  return useTypedMutation<Types.InternalCreateEvent>({
    mutationKey: ['companies', companyId, 'events', 'post'],
    mutationFn: (body) => api.internalCreateEvent({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'events'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalCreateEventsBulk: MutationHook<Types.InternalCreateEventsBulk> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create many events`)

  return useTypedMutation<Types.InternalCreateEventsBulk>({
    mutationKey: ['companies', companyId, 'events', 'bulk', 'post'],
    mutationFn: (body) => api.internalCreateEventsBulk({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'events', 'bulk'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalUpdateEvent: MutationHook<Types.InternalUpdateEvent> = ({
  companyId,
  personId,
  loanId,
  eventId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update event`)

  return useTypedMutation<Types.InternalUpdateEvent>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'events', eventId, 'put'],
    mutationFn: (body) => api.internalUpdateEvent({ companyId, personId, loanId, eventId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'events'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoansGetByCompanyOptions: GetOptionsFunction<Types.InternalLoansGetByCompany> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loans', query ?? {}],
  queryFn: () => api.internalLoansGetByCompany({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loans`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoansGetByCompany = (args: QueryHookArguments<Types.InternalLoansGetByCompany>) =>
  useTypedQuery<Types.InternalLoansGetByCompany>(getInternalLoansGetByCompanyOptions(args))

export const getInternalLoansGetByCompanyAbridgedOptions: GetOptionsFunction<
  Types.InternalLoansGetByCompanyAbridged
> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'loans-abridged', query ?? {}],
  queryFn: () => api.internalLoansGetByCompanyAbridged({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loans`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoansGetByCompanyAbridged = (
  args: QueryHookArguments<Types.InternalLoansGetByCompanyAbridged>,
) => useTypedQuery<Types.InternalLoansGetByCompanyAbridged>(getInternalLoansGetByCompanyAbridgedOptions(args))

export const getInternalLoansGetCountOptions: GetOptionsFunction<Types.InternalLoansGetCount> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-count', query ?? {}],
  queryFn: () => api.internalLoansGetCount({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan count`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoansGetCount = (args: QueryHookArguments<Types.InternalLoansGetCount>) =>
  useTypedQuery<Types.InternalLoansGetCount>(getInternalLoansGetCountOptions(args))

export const getCompanyLoanConfigurationsGetAllOptions: GetOptionsFunction<Types.CompanyLoanConfigurationsGetAll> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', query ?? {}],
  queryFn: () => api.companyLoanConfigurationsGetAll({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan types`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCompanyLoanConfigurationsGetAll = (args: QueryHookArguments<Types.CompanyLoanConfigurationsGetAll>) =>
  useTypedQuery<Types.CompanyLoanConfigurationsGetAll>(getCompanyLoanConfigurationsGetAllOptions(args))

export const useCompanyLoanConfigurationsAdd: MutationHook<Types.CompanyLoanConfigurationsAdd> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create loan type`)

  return useTypedMutation<Types.CompanyLoanConfigurationsAdd>({
    mutationKey: ['companies', companyId, 'loan-types', 'post'],
    mutationFn: (body) => api.companyLoanConfigurationsAdd({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCompanyLoanConfigurationsGetOptions: GetOptionsFunction<Types.CompanyLoanConfigurationsGet> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, query ?? {}],
  queryFn: () => api.companyLoanConfigurationsGet({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan type by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCompanyLoanConfigurationsGet = (args: QueryHookArguments<Types.CompanyLoanConfigurationsGet>) =>
  useTypedQuery<Types.CompanyLoanConfigurationsGet>(getCompanyLoanConfigurationsGetOptions(args))

export const useCompanyLoanConfigurationsDelete: MutationHook<Types.CompanyLoanConfigurationsDelete> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete loan type by ID`)

  return useTypedMutation<Types.CompanyLoanConfigurationsDelete>({
    mutationKey: ['companies', companyId, 'loan-types', loanTypeId, 'delete'],
    mutationFn: (body) => api.companyLoanConfigurationsDelete({ companyId, loanTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getLoansGetByLoanTypeOptions: GetOptionsFunction<Types.LoansGetByLoanType> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'loans', query ?? {}],
  queryFn: () => api.loansGetByLoanType({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all loans by loan type`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useLoansGetByLoanType = (args: QueryHookArguments<Types.LoansGetByLoanType>) =>
  useTypedQuery<Types.LoansGetByLoanType>(getLoansGetByLoanTypeOptions(args))

export const getInternalBorrowerPaymentInstrumentGetAllOptions: GetOptionsFunction<
  Types.InternalBorrowerPaymentInstrumentGetAll
> = ({ companyId, personId, query, options }) => ({
  queryKey: ['companies', companyId, 'people', personId, 'payment-instruments', query ?? {}],
  queryFn: () => api.internalBorrowerPaymentInstrumentGetAll({ companyId, personId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get payment instruments`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalBorrowerPaymentInstrumentGetAll = (
  args: QueryHookArguments<Types.InternalBorrowerPaymentInstrumentGetAll>,
) =>
  useTypedQuery<Types.InternalBorrowerPaymentInstrumentGetAll>(getInternalBorrowerPaymentInstrumentGetAllOptions(args))

export const useInternalBorrowerPaymentInstrumentCreate: MutationHook<
  Types.InternalBorrowerPaymentInstrumentCreate
> = ({ companyId, personId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create payment instrument`)

  return useTypedMutation<Types.InternalBorrowerPaymentInstrumentCreate>({
    mutationKey: ['companies', companyId, 'people', personId, 'payment-instruments', 'post'],
    mutationFn: (body) => api.internalBorrowerPaymentInstrumentCreate({ companyId, personId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'payment-instruments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalBorrowerPaymentInstrumentGetOptions: GetOptionsFunction<
  Types.InternalBorrowerPaymentInstrumentGet
> = ({ companyId, personId, paymentInstrumentId, query, options }) => ({
  queryKey: ['companies', companyId, 'people', personId, 'payment-instruments', paymentInstrumentId, query ?? {}],
  queryFn: () => api.internalBorrowerPaymentInstrumentGet({ companyId, personId, paymentInstrumentId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get payment instrument by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalBorrowerPaymentInstrumentGet = (
  args: QueryHookArguments<Types.InternalBorrowerPaymentInstrumentGet>,
) => useTypedQuery<Types.InternalBorrowerPaymentInstrumentGet>(getInternalBorrowerPaymentInstrumentGetOptions(args))

export const useInternalBorrowerPaymentInstrumentUpdate: MutationHook<
  Types.InternalBorrowerPaymentInstrumentUpdate
> = ({ companyId, personId, paymentInstrumentId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update payment instrument`)

  return useTypedMutation<Types.InternalBorrowerPaymentInstrumentUpdate>({
    mutationKey: ['companies', companyId, 'people', personId, 'payment-instruments', paymentInstrumentId, 'put'],
    mutationFn: (body) =>
      api.internalBorrowerPaymentInstrumentUpdate({ companyId, personId, paymentInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'payment-instruments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalBorrowerPaymentInstrumentDelete: MutationHook<
  Types.InternalBorrowerPaymentInstrumentDelete
> = ({ companyId, personId, paymentInstrumentId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete payment instrument`)

  return useTypedMutation<Types.InternalBorrowerPaymentInstrumentDelete>({
    mutationKey: ['companies', companyId, 'people', personId, 'payment-instruments', paymentInstrumentId, 'delete'],
    mutationFn: (body) =>
      api.internalBorrowerPaymentInstrumentDelete({ companyId, personId, paymentInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'payment-instruments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalAccountLinkPost: MutationHook<Types.InternalAccountLinkPost> = ({
  companyId,
  personId,
  paymentInstrumentId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create account link`)

  return useTypedMutation<Types.InternalAccountLinkPost>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'payment-instruments',
      paymentInstrumentId,
      'account-link',
      'post',
    ],
    mutationFn: (body) => api.internalAccountLinkPost({ companyId, personId, paymentInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'payment-instruments',
        paymentInstrumentId,
        'account-link',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalAccountLinkDelete: MutationHook<Types.InternalAccountLinkDelete> = ({
  companyId,
  personId,
  paymentInstrumentId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete account link`)

  return useTypedMutation<Types.InternalAccountLinkDelete>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'payment-instruments',
      paymentInstrumentId,
      'account-link',
      'delete',
    ],
    mutationFn: (body) => api.internalAccountLinkDelete({ companyId, personId, paymentInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'payment-instruments',
        paymentInstrumentId,
        'account-link',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalBalancesGetOptions: GetOptionsFunction<Types.InternalBalancesGet> = ({
  companyId,
  personId,
  paymentInstrumentId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'payment-instruments',
    paymentInstrumentId,
    'balance',
    query ?? {},
  ],
  queryFn: () => api.internalBalancesGet({ companyId, personId, paymentInstrumentId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get balance data`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalBalancesGet = (args: QueryHookArguments<Types.InternalBalancesGet>) =>
  useTypedQuery<Types.InternalBalancesGet>(getInternalBalancesGetOptions(args))

export const useInternalBalancesPost: MutationHook<Types.InternalBalancesPost> = ({
  companyId,
  personId,
  paymentInstrumentId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh balance data`)

  return useTypedMutation<Types.InternalBalancesPost>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'payment-instruments',
      paymentInstrumentId,
      'balance',
      'post',
    ],
    mutationFn: (body) => api.internalBalancesPost({ companyId, personId, paymentInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'payment-instruments',
        paymentInstrumentId,
        'balance',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoansGetOptions: GetOptionsFunction<Types.InternalLoansGet> = ({
  companyId,
  personId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', query ?? {}],
  queryFn: () => api.internalLoansGet({ companyId, personId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loans`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoansGet = (args: QueryHookArguments<Types.InternalLoansGet>) =>
  useTypedQuery<Types.InternalLoansGet>(getInternalLoansGetOptions(args))

export const useInternalLoansAdd: MutationHook<Types.InternalLoansAdd> = ({ companyId, personId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create loan`)

  return useTypedMutation<Types.InternalLoansAdd>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', 'post'],
    mutationFn: (body) => api.internalLoansAdd({ companyId, personId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanGetOptions: GetOptionsFunction<Types.InternalLoanGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, query ?? {}],
  queryFn: () => api.internalLoanGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanGet = (args: QueryHookArguments<Types.InternalLoanGet>) =>
  useTypedQuery<Types.InternalLoanGet>(getInternalLoanGetOptions(args))

export const useInternalLoanUpdate: MutationHook<Types.InternalLoanUpdate> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan`)

  return useTypedMutation<Types.InternalLoanUpdate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'put'],
    mutationFn: (body) => api.internalLoanUpdate({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalSyncPayoffStatus: MutationHook<Types.InternalSyncPayoffStatus> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan`)

  return useTypedMutation<Types.InternalSyncPayoffStatus>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'sync-payoff-status', 'post'],
    mutationFn: (body) => api.internalSyncPayoffStatus({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'sync-payoff-status'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useLoggingDebugger: MutationHook<Types.LoggingDebugger> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Debug loan`)

  return useTypedMutation<Types.LoggingDebugger>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'logging-debugger', 'post'],
    mutationFn: (body) => api.loggingDebugger({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'logging-debugger'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getLedgerDebuggerOptions: GetOptionsFunction<Types.LedgerDebugger> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'ledger-debugger', query ?? {}],
  queryFn: () => api.ledgerDebugger({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Debug a loan's ledger`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useLedgerDebugger = (args: QueryHookArguments<Types.LedgerDebugger>) =>
  useTypedQuery<Types.LedgerDebugger>(getLedgerDebuggerOptions(args))

export const useInternalLoanRatesDelete: MutationHook<Types.InternalLoanRatesDelete> = ({
  companyId,
  personId,
  loanId,
  rateId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Remove loan rate`)

  return useTypedMutation<Types.InternalLoanRatesDelete>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'rates', rateId, 'delete'],
    mutationFn: (body) => api.internalLoanRatesDelete({ companyId, personId, loanId, rateId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'rates'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalForecastInterestOptions: GetOptionsFunction<Types.InternalForecastInterest> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'forecast-interest', query ?? {}],
  queryFn: () => api.internalForecastInterest({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get interest forecast`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalForecastInterest = (args: QueryHookArguments<Types.InternalForecastInterest>) =>
  useTypedQuery<Types.InternalForecastInterest>(getInternalForecastInterestOptions(args))

export const getInternalLoanRatesGetOptions: GetOptionsFunction<Types.InternalLoanRatesGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'rates', query ?? {}],
  queryFn: () => api.internalLoanRatesGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan rates`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanRatesGet = (args: QueryHookArguments<Types.InternalLoanRatesGet>) =>
  useTypedQuery<Types.InternalLoanRatesGet>(getInternalLoanRatesGetOptions(args))

export const useInternalLoanRatesAdd: MutationHook<Types.InternalLoanRatesAdd> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Add loan rate`)

  return useTypedMutation<Types.InternalLoanRatesAdd>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'rates', 'post'],
    mutationFn: (body) => api.internalLoanRatesAdd({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'rates'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalGetLoanPeriodsOptions: GetOptionsFunction<Types.InternalGetLoanPeriods> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'periods', query ?? {}],
  queryFn: () => api.internalGetLoanPeriods({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan periods`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetLoanPeriods = (args: QueryHookArguments<Types.InternalGetLoanPeriods>) =>
  useTypedQuery<Types.InternalGetLoanPeriods>(getInternalGetLoanPeriodsOptions(args))

export const useInternalUpdateLoanPeriod: MutationHook<Types.InternalUpdateLoanPeriod> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan period`)

  return useTypedMutation<Types.InternalUpdateLoanPeriod>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'periods', 'put'],
    mutationFn: (body) => api.internalUpdateLoanPeriod({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'periods'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalCreateNextLoanPeriod: MutationHook<Types.InternalCreateNextLoanPeriod> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create loan period`)

  return useTypedMutation<Types.InternalCreateNextLoanPeriod>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'periods', 'post'],
    mutationFn: (body) => api.internalCreateNextLoanPeriod({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'periods'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanExpectedPaymentsGetOptions: GetOptionsFunction<Types.InternalLoanExpectedPaymentsGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'expected-payments', query ?? {}],
  queryFn: () => api.internalLoanExpectedPaymentsGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get expected payments`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanExpectedPaymentsGet = (args: QueryHookArguments<Types.InternalLoanExpectedPaymentsGet>) =>
  useTypedQuery<Types.InternalLoanExpectedPaymentsGet>(getInternalLoanExpectedPaymentsGetOptions(args))

export const useInternalLoanExpectedPaymentsPost: MutationHook<Types.InternalLoanExpectedPaymentsPost> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Change due dates schedule`)

  return useTypedMutation<Types.InternalLoanExpectedPaymentsPost>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'expected-payments', 'post'],
    mutationFn: (body) => api.internalLoanExpectedPaymentsPost({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'expected-payments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanExpectedPaymentsGetOneOptions: GetOptionsFunction<
  Types.InternalLoanExpectedPaymentsGetOne
> = ({ companyId, personId, loanId, expectedPaymentId, query, options }) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'expected-payments',
    expectedPaymentId,
    query ?? {},
  ],
  queryFn: () => api.internalLoanExpectedPaymentsGetOne({ companyId, personId, loanId, expectedPaymentId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get expected payment by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanExpectedPaymentsGetOne = (
  args: QueryHookArguments<Types.InternalLoanExpectedPaymentsGetOne>,
) => useTypedQuery<Types.InternalLoanExpectedPaymentsGetOne>(getInternalLoanExpectedPaymentsGetOneOptions(args))

export const getInternalLoanAutopayGetOptions: GetOptionsFunction<Types.InternalLoanAutopayGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'autopay', query ?? {}],
  queryFn: () => api.internalLoanAutopayGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get Autopay plan`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanAutopayGet = (args: QueryHookArguments<Types.InternalLoanAutopayGet>) =>
  useTypedQuery<Types.InternalLoanAutopayGet>(getInternalLoanAutopayGetOptions(args))

export const useInternalLoanAutopayPut: MutationHook<Types.InternalLoanAutopayPut> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update Autopay plan`)

  return useTypedMutation<Types.InternalLoanAutopayPut>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'autopay', 'put'],
    mutationFn: (body) => api.internalLoanAutopayPut({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'autopay'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAutopayPost: MutationHook<Types.InternalLoanAutopayPost> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create Autopay plan`)

  return useTypedMutation<Types.InternalLoanAutopayPost>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'autopay', 'post'],
    mutationFn: (body) => api.internalLoanAutopayPost({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'autopay'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAutopayDelete: MutationHook<Types.InternalLoanAutopayDelete> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel Autopay plan`)

  return useTypedMutation<Types.InternalLoanAutopayDelete>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'autopay', 'delete'],
    mutationFn: (body) => api.internalLoanAutopayDelete({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'autopay'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAutopayCancelPayments: MutationHook<Types.InternalLoanAutopayCancelPayments> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel autopay expected payments`)

  return useTypedMutation<Types.InternalLoanAutopayCancelPayments>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'autopay', 'cancel-payments', 'post'],
    mutationFn: (body) => api.internalLoanAutopayCancelPayments({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'autopay',
        'cancel-payments',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAutopayReschedulePayments: MutationHook<Types.InternalLoanAutopayReschedulePayments> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel autopay expected payments`)

  return useTypedMutation<Types.InternalLoanAutopayReschedulePayments>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'autopay',
      'reschedule-payments',
      'post',
    ],
    mutationFn: (body) => api.internalLoanAutopayReschedulePayments({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'autopay',
        'reschedule-payments',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAutopayCreateNextAutopay: MutationHook<Types.InternalLoanAutopayCreateNextAutopay> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create the next autopay for LOC`)

  return useTypedMutation<Types.InternalLoanAutopayCreateNextAutopay>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'autopay',
      'create-next-autopay',
      'post',
    ],
    mutationFn: (body) => api.internalLoanAutopayCreateNextAutopay({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'autopay',
        'create-next-autopay',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAutopayUpdateExpectedPaymentAmount: MutationHook<
  Types.InternalLoanAutopayUpdateExpectedPaymentAmount
> = ({ companyId, personId, loanId, autopayExpectedPaymentId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update an autopay expected payment amount`)

  return useTypedMutation<Types.InternalLoanAutopayUpdateExpectedPaymentAmount>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'autopay',
      autopayExpectedPaymentId,
      'update-amount',
      'put',
    ],
    mutationFn: (body) =>
      api.internalLoanAutopayUpdateExpectedPaymentAmount({
        companyId,
        personId,
        loanId,
        autopayExpectedPaymentId,
        body,
        query,
      }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'autopay',
        autopayExpectedPaymentId,
        'update-amount',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanBalancesGetOptions: GetOptionsFunction<Types.InternalLoanBalancesGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'balances', query ?? {}],
  queryFn: () => api.internalLoanBalancesGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan balances`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanBalancesGet = (args: QueryHookArguments<Types.InternalLoanBalancesGet>) =>
  useTypedQuery<Types.InternalLoanBalancesGet>(getInternalLoanBalancesGetOptions(args))

export const getInternalLoanFutureBalancesGetOptions: GetOptionsFunction<Types.InternalLoanFutureBalancesGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'future_balances', query ?? {}],
  queryFn: () => api.internalLoanFutureBalancesGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan future balances`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanFutureBalancesGet = (args: QueryHookArguments<Types.InternalLoanFutureBalancesGet>) =>
  useTypedQuery<Types.InternalLoanFutureBalancesGet>(getInternalLoanFutureBalancesGetOptions(args))

export const useInternalPromoProgramsExercise: MutationHook<Types.InternalPromoProgramsExercise> = ({
  companyId,
  personId,
  loanId,
  promoProgramId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Daily check (and exercising) promo program`)

  return useTypedMutation<Types.InternalPromoProgramsExercise>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'promo-programs',
      promoProgramId,
      'exercise',
      'post',
    ],
    mutationFn: (body) =>
      api.internalPromoProgramsExercise({ companyId, personId, loanId, promoProgramId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'promo-programs',
        promoProgramId,
        'exercise',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalPromoProgramsReverse: MutationHook<Types.InternalPromoProgramsReverse> = ({
  companyId,
  personId,
  loanId,
  promoProgramId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Reverse promo program`)

  return useTypedMutation<Types.InternalPromoProgramsReverse>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'promo-programs',
      promoProgramId,
      'reverse',
      'post',
    ],
    mutationFn: (body) =>
      api.internalPromoProgramsReverse({ companyId, personId, loanId, promoProgramId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'promo-programs',
        promoProgramId,
        'reverse',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalPromoProgramsGetAllOptions: GetOptionsFunction<Types.InternalPromoProgramsGetAll> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'promo-programs', query ?? {}],
  queryFn: () => api.internalPromoProgramsGetAll({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get promo programs`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalPromoProgramsGetAll = (args: QueryHookArguments<Types.InternalPromoProgramsGetAll>) =>
  useTypedQuery<Types.InternalPromoProgramsGetAll>(getInternalPromoProgramsGetAllOptions(args))

export const getInternalPromoProgramsGetOptions: GetOptionsFunction<Types.InternalPromoProgramsGet> = ({
  companyId,
  personId,
  loanId,
  promoProgramId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'promo-programs',
    promoProgramId,
    query ?? {},
  ],
  queryFn: () => api.internalPromoProgramsGet({ companyId, personId, loanId, promoProgramId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get promo program by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalPromoProgramsGet = (args: QueryHookArguments<Types.InternalPromoProgramsGet>) =>
  useTypedQuery<Types.InternalPromoProgramsGet>(getInternalPromoProgramsGetOptions(args))

export const getInternalLoanTypeIndexRateGetAllOptions: GetOptionsFunction<Types.InternalLoanTypeIndexRateGetAll> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'index-rates', query ?? {}],
  queryFn: () => api.internalLoanTypeIndexRateGetAll({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get index interest rates`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanTypeIndexRateGetAll = (args: QueryHookArguments<Types.InternalLoanTypeIndexRateGetAll>) =>
  useTypedQuery<Types.InternalLoanTypeIndexRateGetAll>(getInternalLoanTypeIndexRateGetAllOptions(args))

export const useInternalLoanTypeIndexRateAdd: MutationHook<Types.InternalLoanTypeIndexRateAdd> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Add index interest rate`)

  return useTypedMutation<Types.InternalLoanTypeIndexRateAdd>({
    mutationKey: ['companies', companyId, 'loan-types', loanTypeId, 'index-rates', 'post'],
    mutationFn: (body) => api.internalLoanTypeIndexRateAdd({ companyId, loanTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types', loanTypeId, 'index-rates'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanTypeIndexRateGetOptions: GetOptionsFunction<Types.InternalLoanTypeIndexRateGet> = ({
  companyId,
  loanTypeId,
  indexRateId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'index-rates', indexRateId, query ?? {}],
  queryFn: () => api.internalLoanTypeIndexRateGet({ companyId, loanTypeId, indexRateId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get index interest rate by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanTypeIndexRateGet = (args: QueryHookArguments<Types.InternalLoanTypeIndexRateGet>) =>
  useTypedQuery<Types.InternalLoanTypeIndexRateGet>(getInternalLoanTypeIndexRateGetOptions(args))

export const useInternalLoanTypeIndexRateUpdate: MutationHook<Types.InternalLoanTypeIndexRateUpdate> = ({
  companyId,
  loanTypeId,
  indexRateId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update index interest rate`)

  return useTypedMutation<Types.InternalLoanTypeIndexRateUpdate>({
    mutationKey: ['companies', companyId, 'loan-types', loanTypeId, 'index-rates', indexRateId, 'put'],
    mutationFn: (body) => api.internalLoanTypeIndexRateUpdate({ companyId, loanTypeId, indexRateId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types', loanTypeId, 'index-rates'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanTypeIndexRateDelete: MutationHook<Types.InternalLoanTypeIndexRateDelete> = ({
  companyId,
  loanTypeId,
  indexRateId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel index interest rate`)

  return useTypedMutation<Types.InternalLoanTypeIndexRateDelete>({
    mutationKey: ['companies', companyId, 'loan-types', loanTypeId, 'index-rates', indexRateId, 'delete'],
    mutationFn: (body) => api.internalLoanTypeIndexRateDelete({ companyId, loanTypeId, indexRateId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types', loanTypeId, 'index-rates'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalObligationsGetAllOptions: GetOptionsFunction<Types.InternalObligationsGetAll> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'obligations', query ?? {}],
  queryFn: () => api.internalObligationsGetAll({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get obligations`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalObligationsGetAll = (args: QueryHookArguments<Types.InternalObligationsGetAll>) =>
  useTypedQuery<Types.InternalObligationsGetAll>(getInternalObligationsGetAllOptions(args))

export const useInternalObligationCreate: MutationHook<Types.InternalObligationCreate> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create obligation`)

  return useTypedMutation<Types.InternalObligationCreate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'obligations', 'post'],
    mutationFn: (body) => api.internalObligationCreate({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'obligations'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetLoanOptions: GetOptionsFunction<Types.GetLoan> = ({ companyId, loanId, query, options }) => ({
  queryKey: ['companies', companyId, 'loans', loanId, query ?? {}],
  queryFn: () => api.getLoan({ companyId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan without person ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetLoan = (args: QueryHookArguments<Types.GetLoan>) =>
  useTypedQuery<Types.GetLoan>(getGetLoanOptions(args))

export const getGetCompanyLoanObligationsOptions: GetOptionsFunction<Types.GetCompanyLoanObligations> = ({
  companyId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loans', loanId, 'obligations', query ?? {}],
  queryFn: () => api.getCompanyLoanObligations({ companyId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get obligations without person ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetCompanyLoanObligations = (args: QueryHookArguments<Types.GetCompanyLoanObligations>) =>
  useTypedQuery<Types.GetCompanyLoanObligations>(getGetCompanyLoanObligationsOptions(args))

export const getObligationsRefreshPreviewForTimestampOptions: GetOptionsFunction<
  Types.ObligationsRefreshPreviewForTimestamp
> = ({ companyId, personId, loanId, query, options }) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'obligations',
    'refresh-preview',
    query ?? {},
  ],
  queryFn: () => api.obligationsRefreshPreviewForTimestamp({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Refresh obligations preview`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useObligationsRefreshPreviewForTimestamp = (
  args: QueryHookArguments<Types.ObligationsRefreshPreviewForTimestamp>,
) => useTypedQuery<Types.ObligationsRefreshPreviewForTimestamp>(getObligationsRefreshPreviewForTimestampOptions(args))

export const useInternalObligationsRefresh: MutationHook<Types.InternalObligationsRefresh> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh obligations`)

  return useTypedMutation<Types.InternalObligationsRefresh>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'obligations', 'refresh', 'post'],
    mutationFn: (body) => api.internalObligationsRefresh({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'obligations',
        'refresh',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanAgentDashboardToolsGetOptions: GetOptionsFunction<
  Types.InternalLoanAgentDashboardToolsGet
> = ({ companyId, personId, loanId, query, options }) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'agent-dashboard-tools', query ?? {}],
  queryFn: () => api.internalLoanAgentDashboardToolsGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get agent dashboard tools`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanAgentDashboardToolsGet = (
  args: QueryHookArguments<Types.InternalLoanAgentDashboardToolsGet>,
) => useTypedQuery<Types.InternalLoanAgentDashboardToolsGet>(getInternalLoanAgentDashboardToolsGetOptions(args))

export const useInternalLoanCustomPaymentPlanRefresh: MutationHook<Types.InternalLoanCustomPaymentPlanRefresh> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh custom payment plan`)

  return useTypedMutation<Types.InternalLoanCustomPaymentPlanRefresh>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'custom-payment-plans',
      'refresh',
      'post',
    ],
    mutationFn: (body) => api.internalLoanCustomPaymentPlanRefresh({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'custom-payment-plans',
        'refresh',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useObligationsRefresh: MutationHook<Types.ObligationsRefresh> = ({ personId, loanId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh obligations`)

  return useTypedMutation<Types.ObligationsRefresh>({
    mutationKey: ['people', personId, 'loans', loanId, 'obligations', 'refresh', 'post'],
    mutationFn: (body) => api.obligationsRefresh({ personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'loans', loanId, 'obligations', 'refresh'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanTransactionsGetAllOptions: GetOptionsFunction<Types.InternalLoanTransactionsGetAll> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'transactions', query ?? {}],
  queryFn: () => api.internalLoanTransactionsGetAll({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get transactions`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanTransactionsGetAll = (args: QueryHookArguments<Types.InternalLoanTransactionsGetAll>) =>
  useTypedQuery<Types.InternalLoanTransactionsGetAll>(getInternalLoanTransactionsGetAllOptions(args))

export const useInternalTransactionsCreate: MutationHook<Types.InternalTransactionsCreate> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create transaction`)

  return useTypedMutation<Types.InternalTransactionsCreate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'transactions', 'post'],
    mutationFn: (body) => api.internalTransactionsCreate({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'transactions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalTransactionsGetByIdOptions: GetOptionsFunction<Types.InternalTransactionsGetById> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'transactions', transactionId, query ?? {}],
  queryFn: () => api.internalTransactionsGetById({ companyId, personId, loanId, transactionId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get transaction by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalTransactionsGetById = (args: QueryHookArguments<Types.InternalTransactionsGetById>) =>
  useTypedQuery<Types.InternalTransactionsGetById>(getInternalTransactionsGetByIdOptions(args))

export const useInternalTransactionsUpdate: MutationHook<Types.InternalTransactionsUpdate> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update transaction`)

  return useTypedMutation<Types.InternalTransactionsUpdate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'transactions', transactionId, 'put'],
    mutationFn: (body) => api.internalTransactionsUpdate({ companyId, personId, loanId, transactionId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'transactions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalTransactionCancel: MutationHook<Types.InternalTransactionCancel> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel transaction`)

  return useTypedMutation<Types.InternalTransactionCancel>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'transactions',
      transactionId,
      'cancel',
      'post',
    ],
    mutationFn: (body) => api.internalTransactionCancel({ companyId, personId, loanId, transactionId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'transactions',
        transactionId,
        'cancel',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalTransactionsDeleteChildren: MutationHook<Types.InternalTransactionsDeleteChildren> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete child virtual transactions`)

  return useTypedMutation<Types.InternalTransactionsDeleteChildren>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'transactions',
      transactionId,
      'delete-children',
      'post',
    ],
    mutationFn: (body) =>
      api.internalTransactionsDeleteChildren({ companyId, personId, loanId, transactionId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'transactions',
        transactionId,
        'delete-children',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalTransactionReverse: MutationHook<Types.InternalTransactionReverse> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Reverse transaction`)

  return useTypedMutation<Types.InternalTransactionReverse>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'transactions',
      transactionId,
      'reverse',
      'post',
    ],
    mutationFn: (body) => api.internalTransactionReverse({ companyId, personId, loanId, transactionId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'transactions',
        transactionId,
        'reverse',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalGetChargebacksOptions: GetOptionsFunction<Types.InternalGetChargebacks> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    query ?? {},
  ],
  queryFn: () => api.internalGetChargebacks({ companyId, personId, loanId, transactionId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get transaction chargebacks`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetChargebacks = (args: QueryHookArguments<Types.InternalGetChargebacks>) =>
  useTypedQuery<Types.InternalGetChargebacks>(getInternalGetChargebacksOptions(args))

export const useInternalTransactionChargeback: MutationHook<Types.InternalTransactionChargeback> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create transaction chargeback`)

  return useTypedMutation<Types.InternalTransactionChargeback>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'transactions',
      transactionId,
      'chargebacks',
      'post',
    ],
    mutationFn: (body) =>
      api.internalTransactionChargeback({ companyId, personId, loanId, transactionId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'transactions',
        transactionId,
        'chargebacks',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalGetChargebackByIdOptions: GetOptionsFunction<Types.InternalGetChargebackById> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  chargebackId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'transactions',
    transactionId,
    'chargebacks',
    chargebackId,
    query ?? {},
  ],
  queryFn: () => api.internalGetChargebackById({ companyId, personId, loanId, transactionId, chargebackId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get chargeback by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetChargebackById = (args: QueryHookArguments<Types.InternalGetChargebackById>) =>
  useTypedQuery<Types.InternalGetChargebackById>(getInternalGetChargebackByIdOptions(args))

export const useInternalUpdateChargeback: MutationHook<Types.InternalUpdateChargeback> = ({
  companyId,
  personId,
  loanId,
  transactionId,
  chargebackId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update chargeback`)

  return useTypedMutation<Types.InternalUpdateChargeback>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'transactions',
      transactionId,
      'chargebacks',
      chargebackId,
      'put',
    ],
    mutationFn: (body) =>
      api.internalUpdateChargeback({ companyId, personId, loanId, transactionId, chargebackId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'transactions',
        transactionId,
        'chargebacks',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getLoanTypeDynamicFeeGetAllOptions: GetOptionsFunction<Types.LoanTypeDynamicFeeGetAll> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'dynamic-fee-types', query ?? {}],
  queryFn: () => api.loanTypeDynamicFeeGetAll({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get dynamic fees by loan type`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useLoanTypeDynamicFeeGetAll = (args: QueryHookArguments<Types.LoanTypeDynamicFeeGetAll>) =>
  useTypedQuery<Types.LoanTypeDynamicFeeGetAll>(getLoanTypeDynamicFeeGetAllOptions(args))

export const useLoanTypeDynamicFeeTypeAdd: MutationHook<Types.LoanTypeDynamicFeeTypeAdd> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create dynamic fee type`)

  return useTypedMutation<Types.LoanTypeDynamicFeeTypeAdd>({
    mutationKey: ['companies', companyId, 'loan-types', loanTypeId, 'dynamic-fee-types', 'post'],
    mutationFn: (body) => api.loanTypeDynamicFeeTypeAdd({ companyId, loanTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types', loanTypeId, 'dynamic-fee-types'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanChargeOff: MutationHook<Types.InternalLoanChargeOff> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Charge-off loan`)

  return useTypedMutation<Types.InternalLoanChargeOff>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'charge-off', 'post'],
    mutationFn: (body) => api.internalLoanChargeOff({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'charge-off'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanDueDateDefer: MutationHook<Types.InternalLoanDueDateDefer> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Defer single due date`)

  return useTypedMutation<Types.InternalLoanDueDateDefer>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'defer-due-date', 'post'],
    mutationFn: (body) => api.internalLoanDueDateDefer({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'defer-due-date'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanScheduleDefer: MutationHook<Types.InternalLoanScheduleDefer> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Defer schedule`)

  return useTypedMutation<Types.InternalLoanScheduleDefer>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'defer-schedule', 'post'],
    mutationFn: (body) => api.internalLoanScheduleDefer({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'defer-schedule'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanChangeTermsScra: MutationHook<Types.InternalLoanChangeTermsScra> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Change loan terms SCRA`)

  return useTypedMutation<Types.InternalLoanChangeTermsScra>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'payment-plan-scra', 'post'],
    mutationFn: (body) => api.internalLoanChangeTermsScra({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'payment-plan-scra'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAccelerate: MutationHook<Types.InternalLoanAccelerate> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Accelerate loan`)

  return useTypedMutation<Types.InternalLoanAccelerate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'accelerate', 'post'],
    mutationFn: (body) => api.internalLoanAccelerate({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'accelerate'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanRefresh: MutationHook<Types.InternalLoanRefresh> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh loan`)

  return useTypedMutation<Types.InternalLoanRefresh>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refresh', 'post'],
    mutationFn: (body) => api.internalLoanRefresh({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'refresh'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanScheduleOptionsGetOptions: GetOptionsFunction<Types.InternalLoanScheduleOptionsGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'schedule-options', query ?? {}],
  queryFn: () => api.internalLoanScheduleOptionsGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan schedule options`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanScheduleOptionsGet = (args: QueryHookArguments<Types.InternalLoanScheduleOptionsGet>) =>
  useTypedQuery<Types.InternalLoanScheduleOptionsGet>(getInternalLoanScheduleOptionsGetOptions(args))

export const getLoanTypeDynamicFeeTypeGetOptions: GetOptionsFunction<Types.LoanTypeDynamicFeeTypeGet> = ({
  companyId,
  loanTypeId,
  dynamicFeeTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'dynamic-fee-types', dynamicFeeTypeId, query ?? {}],
  queryFn: () => api.loanTypeDynamicFeeTypeGet({ companyId, loanTypeId, dynamicFeeTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get dynamic fee type by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useLoanTypeDynamicFeeTypeGet = (args: QueryHookArguments<Types.LoanTypeDynamicFeeTypeGet>) =>
  useTypedQuery<Types.LoanTypeDynamicFeeTypeGet>(getLoanTypeDynamicFeeTypeGetOptions(args))

export const useInternalLoanLateFeeCharge: MutationHook<Types.InternalLoanLateFeeCharge> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Charge late fee`)

  return useTypedMutation<Types.InternalLoanLateFeeCharge>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'charge-late-fee', 'post'],
    mutationFn: (body) => api.internalLoanLateFeeCharge({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'charge-late-fee'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanServiceFeeCharge: MutationHook<Types.InternalLoanServiceFeeCharge> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Charge service fee`)

  return useTypedMutation<Types.InternalLoanServiceFeeCharge>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'charge-service-fee', 'post'],
    mutationFn: (body) => api.internalLoanServiceFeeCharge({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'charge-service-fee'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanNsfFeesCharge: MutationHook<Types.InternalLoanNsfFeesCharge> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Charge service fee`)

  return useTypedMutation<Types.InternalLoanNsfFeesCharge>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'charge-nsf-fees', 'post'],
    mutationFn: (body) => api.internalLoanNsfFeesCharge({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'charge-nsf-fees'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalFeeTypesGetOptions: GetOptionsFunction<Types.InternalFeeTypesGet> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'fee-types', query ?? {}],
  queryFn: () => api.internalFeeTypesGet({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get fee types`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalFeeTypesGet = (args: QueryHookArguments<Types.InternalFeeTypesGet>) =>
  useTypedQuery<Types.InternalFeeTypesGet>(getInternalFeeTypesGetOptions(args))

export const getInternalDynamicFeeGetOptions: GetOptionsFunction<Types.InternalDynamicFeeGet> = ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'fees', loanFeeId, query ?? {}],
  queryFn: () => api.internalDynamicFeeGet({ companyId, personId, loanId, loanFeeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get dynamic fee by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalDynamicFeeGet = (args: QueryHookArguments<Types.InternalDynamicFeeGet>) =>
  useTypedQuery<Types.InternalDynamicFeeGet>(getInternalDynamicFeeGetOptions(args))

export const useInternalDynamicFeeUpdate: MutationHook<Types.InternalDynamicFeeUpdate> = ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update dynamic fee`)

  return useTypedMutation<Types.InternalDynamicFeeUpdate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'fees', loanFeeId, 'put'],
    mutationFn: (body) => api.internalDynamicFeeUpdate({ companyId, personId, loanId, loanFeeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'fees'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalMarkFeeBooked: MutationHook<Types.InternalMarkFeeBooked> = ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Mark fee booked`)

  return useTypedMutation<Types.InternalMarkFeeBooked>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'fees',
      loanFeeId,
      'mark-fee-booked',
      'post',
    ],
    mutationFn: (body) => api.internalMarkFeeBooked({ companyId, personId, loanId, loanFeeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'fees',
        loanFeeId,
        'mark-fee-booked',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalFeesGetOptions: GetOptionsFunction<Types.InternalFeesGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'fees', query ?? {}],
  queryFn: () => api.internalFeesGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan fees`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalFeesGet = (args: QueryHookArguments<Types.InternalFeesGet>) =>
  useTypedQuery<Types.InternalFeesGet>(getInternalFeesGetOptions(args))

export const useInternalFeesPost: MutationHook<Types.InternalFeesPost> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create dynamic fee`)

  return useTypedMutation<Types.InternalFeesPost>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'fees', 'post'],
    mutationFn: (body) => api.internalFeesPost({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'fees'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalFeesCancelOne: MutationHook<Types.InternalFeesCancelOne> = ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel dynamic fee`)

  return useTypedMutation<Types.InternalFeesCancelOne>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'fees', loanFeeId, 'cancel', 'post'],
    mutationFn: (body) => api.internalFeesCancelOne({ companyId, personId, loanId, loanFeeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'fees',
        loanFeeId,
        'cancel',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalFeesCancel: MutationHook<Types.InternalFeesCancel> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Cancel dynamic fees`)

  return useTypedMutation<Types.InternalFeesCancel>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'cancel-fees', 'post'],
    mutationFn: (body) => api.internalFeesCancel({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'cancel-fees'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanAccrue: MutationHook<Types.InternalLoanAccrue> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Accrue daily interest`)

  return useTypedMutation<Types.InternalLoanAccrue>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'accrue', 'post'],
    mutationFn: (body) => api.internalLoanAccrue({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'accrue'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useLoanPayoff: MutationHook<Types.LoanPayoff> = ({ personId, loanId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan status to paidOff status based on the outstanding balance`)

  return useTypedMutation<Types.LoanPayoff>({
    mutationKey: ['people', personId, 'loans', loanId, 'payoff', 'post'],
    mutationFn: (body) => api.loanPayoff({ personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'loans', loanId, 'payoff'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanPayoff: MutationHook<Types.InternalLoanPayoff> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan status to paidOff status based on the outstanding balance`)

  return useTypedMutation<Types.InternalLoanPayoff>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'payoff', 'post'],
    mutationFn: (body) => api.internalLoanPayoff({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'payoff'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanPayoffReverse: MutationHook<Types.InternalLoanPayoffReverse> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan status to a non paidOff status based on the outstanding balance`)

  return useTypedMutation<Types.InternalLoanPayoffReverse>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'reverse-payoff', 'post'],
    mutationFn: (body) => api.internalLoanPayoffReverse({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'reverse-payoff'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useLoanPayoffReverse: MutationHook<Types.LoanPayoffReverse> = ({ personId, loanId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan status to a non paidOff status based on the outstanding balance`)

  return useTypedMutation<Types.LoanPayoffReverse>({
    mutationKey: ['people', personId, 'loans', loanId, 'reverse-payoff', 'post'],
    mutationFn: (body) => api.loanPayoffReverse({ personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'loans', loanId, 'reverse-payoff'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanExpectedPaymentsRefresh: MutationHook<Types.InternalLoanExpectedPaymentsRefresh> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh expected payments`)

  return useTypedMutation<Types.InternalLoanExpectedPaymentsRefresh>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'expected-payments', 'refresh', 'post'],
    mutationFn: (body) => api.internalLoanExpectedPaymentsRefresh({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'expected-payments',
        'refresh',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useLoanExpectedPaymentsRefresh: MutationHook<Types.LoanExpectedPaymentsRefresh> = ({
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh expected payments`)

  return useTypedMutation<Types.LoanExpectedPaymentsRefresh>({
    mutationKey: ['people', personId, 'loans', loanId, 'expected-payments', 'refresh', 'post'],
    mutationFn: (body) => api.loanExpectedPaymentsRefresh({ personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'loans', loanId, 'expected-payments', 'refresh'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanExpectedPaymentOverride: MutationHook<Types.InternalLoanExpectedPaymentOverride> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update an expected payment`)

  return useTypedMutation<Types.InternalLoanExpectedPaymentOverride>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'expected-payments', 'override', 'put'],
    mutationFn: (body) => api.internalLoanExpectedPaymentOverride({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'expected-payments',
        'override',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalFeeAdjustForLoc: MutationHook<Types.InternalFeeAdjustForLoc> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Adjust fees for LOC`)

  return useTypedMutation<Types.InternalFeeAdjustForLoc>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'adjust-fees', 'post'],
    mutationFn: (body) => api.internalFeeAdjustForLoc({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'adjust-fees'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalFinalizeMinPaymentsForLoc: MutationHook<Types.InternalFinalizeMinPaymentsForLoc> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Finalise min payments for LOC`)

  return useTypedMutation<Types.InternalFinalizeMinPaymentsForLoc>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'min-payment', 'post'],
    mutationFn: (body) => api.internalFinalizeMinPaymentsForLoc({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'min-payment'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanExpectedPaymentsOverLimit: MutationHook<Types.InternalLoanExpectedPaymentsOverLimit> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create OverLimitPayment`)

  return useTypedMutation<Types.InternalLoanExpectedPaymentsOverLimit>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'expected-payments',
      'over-limit',
      'post',
    ],
    mutationFn: (body) => api.internalLoanExpectedPaymentsOverLimit({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'expected-payments',
        'over-limit',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useEmitScheduledInteractionEvents: MutationHook<Types.EmitScheduledInteractionEvents> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Emit events for scheduled interactions`)

  return useTypedMutation<Types.EmitScheduledInteractionEvents>({
    mutationKey: ['rpc', 'companies', companyId, 'emit-scheduled-interaction-events', 'post'],
    mutationFn: (body) => api.emitScheduledInteractionEvents({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['rpc', 'companies', companyId, 'emit-scheduled-interaction-events'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useEmitExpiredCaseSnoozeEvents: MutationHook<Types.EmitExpiredCaseSnoozeEvents> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Emit events for snoozed cases`)

  return useTypedMutation<Types.EmitExpiredCaseSnoozeEvents>({
    mutationKey: ['rpc', 'companies', companyId, 'emit-expired-case-snooze-events', 'post'],
    mutationFn: (body) => api.emitExpiredCaseSnoozeEvents({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['rpc', 'companies', companyId, 'emit-expired-case-snooze-events'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useEmitExpiredCaseDniEvents: MutationHook<Types.EmitExpiredCaseDniEvents> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Emit events for expired DNIs`)

  return useTypedMutation<Types.EmitExpiredCaseDniEvents>({
    mutationKey: ['rpc', 'companies', companyId, 'emit-expired-case-dni-events', 'post'],
    mutationFn: (body) => api.emitExpiredCaseDniEvents({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['rpc', 'companies', companyId, 'emit-expired-case-dni-events'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalCaseTypeGetAllOptions: GetOptionsFunction<Types.InternalCaseTypeGetAll> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'case-types', query ?? {}],
  queryFn: () => api.internalCaseTypeGetAll({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get case types for a specific company`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalCaseTypeGetAll = (args: QueryHookArguments<Types.InternalCaseTypeGetAll>) =>
  useTypedQuery<Types.InternalCaseTypeGetAll>(getInternalCaseTypeGetAllOptions(args))

export const useInternalCaseTypeCreateSmartReviewTasks: MutationHook<Types.InternalCaseTypeCreateSmartReviewTasks> = ({
  companyId,
  caseTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create case type review settings for ID`)

  return useTypedMutation<Types.InternalCaseTypeCreateSmartReviewTasks>({
    mutationKey: ['companies', companyId, 'case-types', caseTypeId, 'create-smart-review-tasks', 'post'],
    mutationFn: (body) => api.internalCaseTypeCreateSmartReviewTasks({ companyId, caseTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'case-types', caseTypeId, 'create-smart-review-tasks'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getServicesGetAllOptions: GetOptionsFunction<Types.ServicesGetAll> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'monitoring', 'services', query ?? {}],
  queryFn: () => api.servicesGetAll({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all monitoring services`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useServicesGetAll = (args: QueryHookArguments<Types.ServicesGetAll>) =>
  useTypedQuery<Types.ServicesGetAll>(getServicesGetAllOptions(args))

export const useServiceCreate: MutationHook<Types.ServiceCreate> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a monitoring service.`)

  return useTypedMutation<Types.ServiceCreate>({
    mutationKey: ['companies', companyId, 'monitoring', 'services', 'post'],
    mutationFn: (body) => api.serviceCreate({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'monitoring', 'services'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getServicesGetOptions: GetOptionsFunction<Types.ServicesGet> = ({
  companyId,
  serviceId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'monitoring', 'services', serviceId, query ?? {}],
  queryFn: () => api.servicesGet({ companyId, serviceId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get a specific monitoring service`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useServicesGet = (args: QueryHookArguments<Types.ServicesGet>) =>
  useTypedQuery<Types.ServicesGet>(getServicesGetOptions(args))

export const getDueStatusesOptions: GetOptionsFunction<Types.DueStatuses> = ({
  companyId,
  serviceId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'monitoring', 'services', serviceId, 'due-statuses', query ?? {}],
  queryFn: () => api.dueStatuses({ companyId, serviceId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get due monitoring statuses. For internal use only!`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useDueStatuses = (args: QueryHookArguments<Types.DueStatuses>) =>
  useTypedQuery<Types.DueStatuses>(getDueStatusesOptions(args))

export const getAllStatusesOptions: GetOptionsFunction<Types.AllStatuses> = ({ query, options }) => ({
  queryKey: ['monitoring-status', query ?? {}],
  queryFn: () => api.allStatuses({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all monitoring statuses irrespective of company or person,
for internal use only!`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useAllStatuses = (args: QueryHookArguments<Types.AllStatuses>) =>
  useTypedQuery<Types.AllStatuses>(getAllStatusesOptions(args))

export const useUpdateStatuses: MutationHook<Types.UpdateStatuses> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update multiple monitoring statuses irrespective of company or person,
for internal use only!`)

  return useTypedMutation<Types.UpdateStatuses>({
    mutationKey: ['monitoring-status', 'patch'],
    mutationFn: (body) => api.updateStatuses({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['monitoring-status'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalCaseSnooze: MutationHook<Types.InternalCaseSnooze> = ({
  companyId,
  personId,
  caseId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Snooze case`)

  return useTypedMutation<Types.InternalCaseSnooze>({
    mutationKey: ['companies', companyId, 'people', personId, 'cases', caseId, 'snooze', 'post'],
    mutationFn: (body) => api.internalCaseSnooze({ companyId, personId, caseId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'cases', caseId, 'snooze'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetAddressesInFipsCodeOptions: GetOptionsFunction<Types.GetAddressesInFipsCode> = ({
  state,
  fipsCode,
  query,
  options,
}) => ({
  queryKey: ['uscounties', state, fipsCode, 'addressesinCounty', query ?? {}],
  queryFn: () => api.getAddressesInFipsCode({ state, fipsCode, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all addresses within a given fips code for a given company,
for internal use only!`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetAddressesInFipsCode = (args: QueryHookArguments<Types.GetAddressesInFipsCode>) =>
  useTypedQuery<Types.GetAddressesInFipsCode>(getGetAddressesInFipsCodeOptions(args))

export const getInternalStatusGetAllOptions: GetOptionsFunction<Types.InternalStatusGetAll> = ({
  companyId,
  personId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'monitoring-status', query ?? {}],
  queryFn: () => api.internalStatusGetAll({ companyId, personId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all monitoring statuses`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalStatusGetAll = (args: QueryHookArguments<Types.InternalStatusGetAll>) =>
  useTypedQuery<Types.InternalStatusGetAll>(getInternalStatusGetAllOptions(args))

export const useInternalStatusUpdate: MutationHook<Types.InternalStatusUpdate> = ({
  companyId,
  personId,
  statusId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update a specific monitoring status`)

  return useTypedMutation<Types.InternalStatusUpdate>({
    mutationKey: ['companies', companyId, 'people', personId, 'monitoring-status', statusId, 'put'],
    mutationFn: (body) => api.internalStatusUpdate({ companyId, personId, statusId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'monitoring-status'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getStatusGetAllOptions: GetOptionsFunction<Types.StatusGetAll> = ({ personId, query, options }) => ({
  queryKey: ['people', personId, 'monitoring-status', query ?? {}],
  queryFn: () => api.statusGetAll({ personId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all monitoring statuses`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useStatusGetAll = (args: QueryHookArguments<Types.StatusGetAll>) =>
  useTypedQuery<Types.StatusGetAll>(getStatusGetAllOptions(args))

export const useStatusCreate: MutationHook<Types.StatusCreate> = ({ personId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a monitoring status.`)

  return useTypedMutation<Types.StatusCreate>({
    mutationKey: ['people', personId, 'monitoring-status', 'post'],
    mutationFn: (body) => api.statusCreate({ personId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'monitoring-status'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getStatusGetOptions: GetOptionsFunction<Types.StatusGet> = ({ personId, statusId, query, options }) => ({
  queryKey: ['people', personId, 'monitoring-status', statusId, query ?? {}],
  queryFn: () => api.statusGet({ personId, statusId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get a specific monitoring status`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useStatusGet = (args: QueryHookArguments<Types.StatusGet>) =>
  useTypedQuery<Types.StatusGet>(getStatusGetOptions(args))

export const useStatusUpdate: MutationHook<Types.StatusUpdate> = ({ personId, statusId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update a specific monitoring status`)

  return useTypedMutation<Types.StatusUpdate>({
    mutationKey: ['people', personId, 'monitoring-status', statusId, 'put'],
    mutationFn: (body) => api.statusUpdate({ personId, statusId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'monitoring-status'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetAllDisastersOptions: GetOptionsFunction<Types.GetAllDisasters> = ({ query, options }) => ({
  queryKey: ['disasters', query ?? {}],
  queryFn: () => api.getAllDisasters({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all FEMA Disasters.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetAllDisasters = (args: QueryHookArguments<Types.GetAllDisasters>) =>
  useTypedQuery<Types.GetAllDisasters>(getGetAllDisastersOptions(args))

export const useCreateDisaster: MutationHook<Types.CreateDisaster> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a FEMA Disaster.`)

  return useTypedMutation<Types.CreateDisaster>({
    mutationKey: ['disasters', 'post'],
    mutationFn: (body) => api.createDisaster({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['disasters'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetDisasterImpactedAreasCsvOptions: GetOptionsFunction<Types.GetDisasterImpactedAreasCsv> = ({
  disasterNumber,
  query,
  options,
}) => ({
  queryKey: ['disasters', disasterNumber, 'impacted-areas.csv', query ?? {}],
  queryFn: () => api.getDisasterImpactedAreasCsv({ disasterNumber, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get disaster impacted area information in CSV format.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetDisasterImpactedAreasCsv = (args: QueryHookArguments<Types.GetDisasterImpactedAreasCsv>) =>
  useTypedQuery<Types.GetDisasterImpactedAreasCsv>(getGetDisasterImpactedAreasCsvOptions(args))

export const useFindNewUpdatedDisasters: MutationHook<Types.FindNewUpdatedDisasters> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update FEMA Disasters and returns new ones that should be created.`)

  return useTypedMutation<Types.FindNewUpdatedDisasters>({
    mutationKey: ['disasters', 'new-updated', 'post'],
    mutationFn: (body) => api.findNewUpdatedDisasters({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['disasters', 'new-updated'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCompanyRoleGetAllOptions: GetOptionsFunction<Types.CompanyRoleGetAll> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'roles', query ?? {}],
  queryFn: () => api.companyRoleGetAll({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Retrieve all company roles`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCompanyRoleGetAll = (args: QueryHookArguments<Types.CompanyRoleGetAll>) =>
  useTypedQuery<Types.CompanyRoleGetAll>(getCompanyRoleGetAllOptions(args))

export const useCompanyRoleAdd: MutationHook<Types.CompanyRoleAdd> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Add a company role`)

  return useTypedMutation<Types.CompanyRoleAdd>({
    mutationKey: ['companies', companyId, 'roles', 'post'],
    mutationFn: (body) => api.companyRoleAdd({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'roles'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCompanyRoleGetOptions: GetOptionsFunction<Types.CompanyRoleGet> = ({
  companyId,
  roleId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'roles', roleId, query ?? {}],
  queryFn: () => api.companyRoleGet({ companyId, roleId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Retrieve one company role`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCompanyRoleGet = (args: QueryHookArguments<Types.CompanyRoleGet>) =>
  useTypedQuery<Types.CompanyRoleGet>(getCompanyRoleGetOptions(args))

export const useCompanyRoleUpdate: MutationHook<Types.CompanyRoleUpdate> = ({ companyId, roleId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update a company role`)

  return useTypedMutation<Types.CompanyRoleUpdate>({
    mutationKey: ['companies', companyId, 'roles', roleId, 'put'],
    mutationFn: (body) => api.companyRoleUpdate({ companyId, roleId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'roles'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCompanyRoleDelete: MutationHook<Types.CompanyRoleDelete> = ({ companyId, roleId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete a company role`)

  return useTypedMutation<Types.CompanyRoleDelete>({
    mutationKey: ['companies', companyId, 'roles', roleId, 'delete'],
    mutationFn: (body) => api.companyRoleDelete({ companyId, roleId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'roles'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCompanyRolePermissionsElevate: MutationHook<Types.CompanyRolePermissionsElevate> = ({
  companyId,
  roleId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Elevate permissions`)

  return useTypedMutation<Types.CompanyRolePermissionsElevate>({
    mutationKey: ['companies', companyId, 'roles', roleId, 'permissions', 'post'],
    mutationFn: (body) => api.companyRolePermissionsElevate({ companyId, roleId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'roles', roleId, 'permissions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCompanyRolePermissionsRevoke: MutationHook<Types.CompanyRolePermissionsRevoke> = ({
  companyId,
  roleId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Revoke permissions`)

  return useTypedMutation<Types.CompanyRolePermissionsRevoke>({
    mutationKey: ['companies', companyId, 'roles', roleId, 'permissions', 'delete'],
    mutationFn: (body) => api.companyRolePermissionsRevoke({ companyId, roleId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'roles', roleId, 'permissions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCompanyRolePermissionsRevokeOne: MutationHook<Types.CompanyRolePermissionsRevokeOne> = ({
  companyId,
  roleId,
  permissionId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Revoke a single permission from a role`)

  return useTypedMutation<Types.CompanyRolePermissionsRevokeOne>({
    mutationKey: ['companies', companyId, 'roles', roleId, 'permissions', permissionId, 'delete'],
    mutationFn: (body) => api.companyRolePermissionsRevokeOne({ companyId, roleId, permissionId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'roles', roleId, 'permissions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCompanyPermissionsGetOptions: GetOptionsFunction<Types.CompanyPermissionsGet> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'permissions', query ?? {}],
  queryFn: () => api.companyPermissionsGet({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get a company's available permissions`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCompanyPermissionsGet = (args: QueryHookArguments<Types.CompanyPermissionsGet>) =>
  useTypedQuery<Types.CompanyPermissionsGet>(getCompanyPermissionsGetOptions(args))

export const getInternalUsersGetOptions: GetOptionsFunction<Types.InternalUsersGet> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'users', query ?? {}],
  queryFn: () => api.internalUsersGet({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get a company's users`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalUsersGet = (args: QueryHookArguments<Types.InternalUsersGet>) =>
  useTypedQuery<Types.InternalUsersGet>(getInternalUsersGetOptions(args))

export const useInternalUserCreate: MutationHook<Types.InternalUserCreate> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create company user`)

  return useTypedMutation<Types.InternalUserCreate>({
    mutationKey: ['companies', companyId, 'users', 'post'],
    mutationFn: (body) => api.internalUserCreate({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'users'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalUserGetOptions: GetOptionsFunction<Types.InternalUserGet> = ({
  companyId,
  userId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'users', userId, query ?? {}],
  queryFn: () => api.internalUserGet({ companyId, userId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get company user`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalUserGet = (args: QueryHookArguments<Types.InternalUserGet>) =>
  useTypedQuery<Types.InternalUserGet>(getInternalUserGetOptions(args))

export const useInternalUserUpdate: MutationHook<Types.InternalUserUpdate> = ({
  companyId,
  userId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update company user`)

  return useTypedMutation<Types.InternalUserUpdate>({
    mutationKey: ['companies', companyId, 'users', userId, 'put'],
    mutationFn: (body) => api.internalUserUpdate({ companyId, userId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'users'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalUserGetPermissionsOptions: GetOptionsFunction<Types.InternalUserGetPermissions> = ({
  companyId,
  userId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'users', userId, 'permissions', query ?? {}],
  queryFn: () => api.internalUserGetPermissions({ companyId, userId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get user permissions`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalUserGetPermissions = (args: QueryHookArguments<Types.InternalUserGetPermissions>) =>
  useTypedQuery<Types.InternalUserGetPermissions>(getInternalUserGetPermissionsOptions(args))

export const getInternalUserRoleGetOptions: GetOptionsFunction<Types.InternalUserRoleGet> = ({
  companyId,
  userId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'users', userId, 'roles', query ?? {}],
  queryFn: () => api.internalUserRoleGet({ companyId, userId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get roles by user`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalUserRoleGet = (args: QueryHookArguments<Types.InternalUserRoleGet>) =>
  useTypedQuery<Types.InternalUserRoleGet>(getInternalUserRoleGetOptions(args))

export const useInternalUserRoleUpdate: MutationHook<Types.InternalUserRoleUpdate> = ({
  companyId,
  userId,
  roleId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Add role to user`)

  return useTypedMutation<Types.InternalUserRoleUpdate>({
    mutationKey: ['companies', companyId, 'users', userId, 'roles', roleId, 'put'],
    mutationFn: (body) => api.internalUserRoleUpdate({ companyId, userId, roleId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'users', userId, 'roles'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalUserRoleDelete: MutationHook<Types.InternalUserRoleDelete> = ({
  companyId,
  userId,
  roleId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Remove role from user`)

  return useTypedMutation<Types.InternalUserRoleDelete>({
    mutationKey: ['companies', companyId, 'users', userId, 'roles', roleId, 'delete'],
    mutationFn: (body) => api.internalUserRoleDelete({ companyId, userId, roleId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'users', userId, 'roles'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInvestorGetAllOptions: GetOptionsFunction<Types.InvestorGetAll> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'investors', query ?? {}],
  queryFn: () => api.investorGetAll({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get investors`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInvestorGetAll = (args: QueryHookArguments<Types.InvestorGetAll>) =>
  useTypedQuery<Types.InvestorGetAll>(getInvestorGetAllOptions(args))

export const useInvestorCreate: MutationHook<Types.InvestorCreate> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create investor`)

  return useTypedMutation<Types.InvestorCreate>({
    mutationKey: ['companies', companyId, 'investors', 'post'],
    mutationFn: (body) => api.investorCreate({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'investors'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanInvestorsGetAllOptions: GetOptionsFunction<Types.InternalLoanInvestorsGetAll> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'investors', query ?? {}],
  queryFn: () => api.internalLoanInvestorsGetAll({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan investors`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanInvestorsGetAll = (args: QueryHookArguments<Types.InternalLoanInvestorsGetAll>) =>
  useTypedQuery<Types.InternalLoanInvestorsGetAll>(getInternalLoanInvestorsGetAllOptions(args))

export const getInvestorGetOptions: GetOptionsFunction<Types.InvestorGet> = ({
  companyId,
  investorId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'investors', investorId, query ?? {}],
  queryFn: () => api.investorGet({ companyId, investorId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get investor by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInvestorGet = (args: QueryHookArguments<Types.InvestorGet>) =>
  useTypedQuery<Types.InvestorGet>(getInvestorGetOptions(args))

export const useInvestorUpdate: MutationHook<Types.InvestorUpdate> = ({ companyId, investorId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update investor`)

  return useTypedMutation<Types.InvestorUpdate>({
    mutationKey: ['companies', companyId, 'investors', investorId, 'put'],
    mutationFn: (body) => api.investorUpdate({ companyId, investorId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'investors'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getSettlementInstrumentGetAllOptions: GetOptionsFunction<Types.SettlementInstrumentGetAll> = ({
  companyId,
  investorId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'investors', investorId, 'settlement-instruments', query ?? {}],
  queryFn: () => api.settlementInstrumentGetAll({ companyId, investorId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get settlement instruments`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useSettlementInstrumentGetAll = (args: QueryHookArguments<Types.SettlementInstrumentGetAll>) =>
  useTypedQuery<Types.SettlementInstrumentGetAll>(getSettlementInstrumentGetAllOptions(args))

export const useSettlementInstrumentCreate: MutationHook<Types.SettlementInstrumentCreate> = ({
  companyId,
  investorId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create settlement instrument`)

  return useTypedMutation<Types.SettlementInstrumentCreate>({
    mutationKey: ['companies', companyId, 'investors', investorId, 'settlement-instruments', 'post'],
    mutationFn: (body) => api.settlementInstrumentCreate({ companyId, investorId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'investors', investorId, 'settlement-instruments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getSettlementInstrumentGetOptions: GetOptionsFunction<Types.SettlementInstrumentGet> = ({
  companyId,
  investorId,
  settlementInstrumentId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'investors',
    investorId,
    'settlement-instruments',
    settlementInstrumentId,
    query ?? {},
  ],
  queryFn: () => api.settlementInstrumentGet({ companyId, investorId, settlementInstrumentId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get settlement instrument by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useSettlementInstrumentGet = (args: QueryHookArguments<Types.SettlementInstrumentGet>) =>
  useTypedQuery<Types.SettlementInstrumentGet>(getSettlementInstrumentGetOptions(args))

export const useSettlementInstrumentUpdate: MutationHook<Types.SettlementInstrumentUpdate> = ({
  companyId,
  investorId,
  settlementInstrumentId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update settlement instrument`)

  return useTypedMutation<Types.SettlementInstrumentUpdate>({
    mutationKey: [
      'companies',
      companyId,
      'investors',
      investorId,
      'settlement-instruments',
      settlementInstrumentId,
      'put',
    ],
    mutationFn: (body) =>
      api.settlementInstrumentUpdate({ companyId, investorId, settlementInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'investors', investorId, 'settlement-instruments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getFundingInstrumentGetAllOptions: GetOptionsFunction<Types.FundingInstrumentGetAll> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'funding-instruments', query ?? {}],
  queryFn: () => api.fundingInstrumentGetAll({ companyId, paymentProcessorId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get funding instruments`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useFundingInstrumentGetAll = (args: QueryHookArguments<Types.FundingInstrumentGetAll>) =>
  useTypedQuery<Types.FundingInstrumentGetAll>(getFundingInstrumentGetAllOptions(args))

export const useFundingInstrumentCreate: MutationHook<Types.FundingInstrumentCreate> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create funding instrument`)

  return useTypedMutation<Types.FundingInstrumentCreate>({
    mutationKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'funding-instruments', 'post'],
    mutationFn: (body) => api.fundingInstrumentCreate({ companyId, paymentProcessorId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'payment-processors',
        paymentProcessorId,
        'funding-instruments',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getFundingInstrumentGetOptions: GetOptionsFunction<Types.FundingInstrumentGet> = ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    query ?? {},
  ],
  queryFn: () => api.fundingInstrumentGet({ companyId, paymentProcessorId, fundingInstrumentId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get funding instrument by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useFundingInstrumentGet = (args: QueryHookArguments<Types.FundingInstrumentGet>) =>
  useTypedQuery<Types.FundingInstrumentGet>(getFundingInstrumentGetOptions(args))

export const useFundingInstrumentUpdate: MutationHook<Types.FundingInstrumentUpdate> = ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update funding instrument`)

  return useTypedMutation<Types.FundingInstrumentUpdate>({
    mutationKey: [
      'companies',
      companyId,
      'payment-processors',
      paymentProcessorId,
      'funding-instruments',
      fundingInstrumentId,
      'put',
    ],
    mutationFn: (body) =>
      api.fundingInstrumentUpdate({ companyId, paymentProcessorId, fundingInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'payment-processors',
        paymentProcessorId,
        'funding-instruments',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetRecentFundingAccountBalancesOptions: GetOptionsFunction<Types.GetRecentFundingAccountBalances> = ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'payment-processors',
    paymentProcessorId,
    'funding-instruments',
    fundingInstrumentId,
    'balance',
    query ?? {},
  ],
  queryFn: () => api.getRecentFundingAccountBalances({ companyId, paymentProcessorId, fundingInstrumentId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Fetch balances for the funding instrument`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetRecentFundingAccountBalances = (args: QueryHookArguments<Types.GetRecentFundingAccountBalances>) =>
  useTypedQuery<Types.GetRecentFundingAccountBalances>(getGetRecentFundingAccountBalancesOptions(args))

export const useFundingInstrumentCreateBalance: MutationHook<Types.FundingInstrumentCreateBalance> = ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Add a new balance to the funding instrument`)

  return useTypedMutation<Types.FundingInstrumentCreateBalance>({
    mutationKey: [
      'companies',
      companyId,
      'payment-processors',
      paymentProcessorId,
      'funding-instruments',
      fundingInstrumentId,
      'balance',
      'post',
    ],
    mutationFn: (body) =>
      api.fundingInstrumentCreateBalance({ companyId, paymentProcessorId, fundingInstrumentId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'payment-processors',
        paymentProcessorId,
        'funding-instruments',
        fundingInstrumentId,
        'balance',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetAllTransferSettlementsForAllCompaniesOptions: GetOptionsFunction<
  Types.GetAllTransferSettlementsForAllCompanies
> = ({ query, options }) => ({
  queryKey: ['settlements', query ?? {}],
  queryFn: () => api.getAllTransferSettlementsForAllCompanies({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all the transfer settlements for all companies`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetAllTransferSettlementsForAllCompanies = (
  args: QueryHookArguments<Types.GetAllTransferSettlementsForAllCompanies>,
) =>
  useTypedQuery<Types.GetAllTransferSettlementsForAllCompanies>(
    getGetAllTransferSettlementsForAllCompaniesOptions(args),
  )

export const getGetAllTransferSettlementsOptions: GetOptionsFunction<Types.GetAllTransferSettlements> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'settlements', query ?? {}],
  queryFn: () => api.getAllTransferSettlements({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all the transfer settlements`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetAllTransferSettlements = (args: QueryHookArguments<Types.GetAllTransferSettlements>) =>
  useTypedQuery<Types.GetAllTransferSettlements>(getGetAllTransferSettlementsOptions(args))

export const useCreateTransferSettlements: MutationHook<Types.CreateTransferSettlements> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a new transfer settlement`)

  return useTypedMutation<Types.CreateTransferSettlements>({
    mutationKey: ['companies', companyId, 'settlements', 'post'],
    mutationFn: (body) => api.createTransferSettlements({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'settlements'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetTransferSettlementOptions: GetOptionsFunction<Types.GetTransferSettlement> = ({
  companyId,
  transferSettlementId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'settlements', transferSettlementId, query ?? {}],
  queryFn: () => api.getTransferSettlement({ companyId, transferSettlementId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get a transfer settlement by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetTransferSettlement = (args: QueryHookArguments<Types.GetTransferSettlement>) =>
  useTypedQuery<Types.GetTransferSettlement>(getGetTransferSettlementOptions(args))

export const useUpdateTransferSettlement: MutationHook<Types.UpdateTransferSettlement> = ({
  companyId,
  transferSettlementId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update a transfer settlement by ID`)

  return useTypedMutation<Types.UpdateTransferSettlement>({
    mutationKey: ['companies', companyId, 'settlements', transferSettlementId, 'put'],
    mutationFn: (body) => api.updateTransferSettlement({ companyId, transferSettlementId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'settlements'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useUpdateTransfersExpectingFunding: MutationHook<Types.UpdateTransfersExpectingFunding> = ({
  companyId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update the transfers expecting funding`)

  return useTypedMutation<Types.UpdateTransfersExpectingFunding>({
    mutationKey: ['companies', companyId, 'loans', loanId, 'transfers', 'put'],
    mutationFn: (body) => api.updateTransfersExpectingFunding({ companyId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loans', loanId, 'transfers'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useEndSession: MutationHook<Types.EndSession> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`End a user's session`)

  return useTypedMutation<Types.EndSession>({
    mutationKey: ['auth', 'endsession', 'post'],
    mutationFn: (body) => api.endSession({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['auth', 'endsession'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useRenewJwtToken: MutationHook<Types.RenewJwtToken> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Exchange current token for a new one`)

  return useTypedMutation<Types.RenewJwtToken>({
    mutationKey: ['auth', 'token', 'put'],
    mutationFn: (body) => api.renewJwtToken({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['auth', 'token'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useValidateAndSendToken: MutationHook<Types.ValidateAndSendToken> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Exchange username and password for JWT token`)

  return useTypedMutation<Types.ValidateAndSendToken>({
    mutationKey: ['auth', 'token', 'post'],
    mutationFn: (body) => api.validateAndSendToken({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['auth', 'token'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useLogout: MutationHook<Types.Logout> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`End login session, invalidating current login token.`)

  return useTypedMutation<Types.Logout>({
    mutationKey: ['auth', 'token', 'delete'],
    mutationFn: (body) => api.logout({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['auth', 'token'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useShortLivedToken: MutationHook<Types.ShortLivedToken> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a short-lived token`)

  return useTypedMutation<Types.ShortLivedToken>({
    mutationKey: ['auth', 'token', 'short-lived', 'post'],
    mutationFn: (body) => api.shortLivedToken({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['auth', 'token', 'short-lived'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const usePasswordPost: MutationHook<Types.PasswordPost> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Change password`)

  return useTypedMutation<Types.PasswordPost>({
    mutationKey: ['auth', 'password', 'post'],
    mutationFn: (body) => api.passwordPost({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['auth', 'password'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getUserPermissions_getOptions: GetOptionsFunction<Types.UserPermissions_get> = ({ query, options }) => ({
  queryKey: ['auth', 'permissions', query ?? {}],
  queryFn: () => api.userPermissions_get({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all permissions for the requesting user`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useUserPermissions_get = (args: QueryHookArguments<Types.UserPermissions_get>) =>
  useTypedQuery<Types.UserPermissions_get>(getUserPermissions_getOptions(args))

export const useSendOneTimeCode: MutationHook<Types.SendOneTimeCode> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Send one time code.`)

  return useTypedMutation<Types.SendOneTimeCode>({
    mutationKey: ['companies', companyId, 'auth', 'send-one-time-code', 'post'],
    mutationFn: (body) => api.sendOneTimeCode({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'auth', 'send-one-time-code'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useIdpInitiated: MutationHook<Types.IdpInitiated> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`SAML callback endpoint`)

  return useTypedMutation<Types.IdpInitiated>({
    mutationKey: ['companies', companyId, 'auth', 'saml', 'callback', 'post'],
    mutationFn: (body) => api.idpInitiated({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'auth', 'saml', 'callback'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getSpInitiatedOptions: GetOptionsFunction<Types.SpInitiated> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'auth', 'saml', 'login', query ?? {}],
  queryFn: () => api.spInitiated({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Redirect to the SAML login endpoint`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useSpInitiated = (args: QueryHookArguments<Types.SpInitiated>) =>
  useTypedQuery<Types.SpInitiated>(getSpInitiatedOptions(args))

export const getSpMetadataXmlOptions: GetOptionsFunction<Types.SpMetadataXml> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'auth', 'saml', 'metadata.xml', query ?? {}],
  queryFn: () => api.spMetadataXml({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Display Peach SP Metadata`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useSpMetadataXml = (args: QueryHookArguments<Types.SpMetadataXml>) =>
  useTypedQuery<Types.SpMetadataXml>(getSpMetadataXmlOptions(args))

export const getIdpSetupHelpOptions: GetOptionsFunction<Types.IdpSetupHelp> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'auth', 'saml', 'help', query ?? {}],
  queryFn: () => api.idpSetupHelp({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Display IdP setup help`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useIdpSetupHelp = (args: QueryHookArguments<Types.IdpSetupHelp>) =>
  useTypedQuery<Types.IdpSetupHelp>(getIdpSetupHelpOptions(args))

export const getUserInfoGetOptions: GetOptionsFunction<Types.UserInfoGet> = ({ query, options }) => ({
  queryKey: ['auth', 'user', query ?? {}],
  queryFn: () => api.userInfoGet({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get logged-in user's info.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useUserInfoGet = (args: QueryHookArguments<Types.UserInfoGet>) =>
  useTypedQuery<Types.UserInfoGet>(getUserInfoGetOptions(args))

export const usePlaidAuthLinkToken: MutationHook<Types.PlaidAuthLinkToken> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a Plaid link token`)

  return useTypedMutation<Types.PlaidAuthLinkToken>({
    mutationKey: ['plaid', 'auth', 'link-token', 'post'],
    mutationFn: (body) => api.plaidAuthLinkToken({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['plaid', 'auth', 'link-token'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCodeGetAllOptions: GetOptionsFunction<Types.CodeGetAll> = ({ query, options }) => ({
  queryKey: ['code', query ?? {}],
  queryFn: () => api.codeGetAll({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get codes`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCodeGetAll = (args: QueryHookArguments<Types.CodeGetAll>) =>
  useTypedQuery<Types.CodeGetAll>(getCodeGetAllOptions(args))

export const useCodeCreate: MutationHook<Types.CodeCreate> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create code`)

  return useTypedMutation<Types.CodeCreate>({
    mutationKey: ['code', 'post'],
    mutationFn: (body) => api.codeCreate({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['code'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getExportCodesOptions: GetOptionsFunction<Types.ExportCodes> = ({ query, options }) => ({
  queryKey: ['code', 'export', query ?? {}],
  queryFn: () => api.exportCodes({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Download all rule codes in JSON format`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useExportCodes = (args: QueryHookArguments<Types.ExportCodes>) =>
  useTypedQuery<Types.ExportCodes>(getExportCodesOptions(args))

export const useImportCodes: MutationHook<Types.ImportCodes> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update codes from exported JSON file`)

  return useTypedMutation<Types.ImportCodes>({
    mutationKey: ['code', 'import', 'post'],
    mutationFn: (body) => api.importCodes({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['code', 'import'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalImportCodes: MutationHook<Types.InternalImportCodes> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update codes from exported JSON file`)

  return useTypedMutation<Types.InternalImportCodes>({
    mutationKey: ['companies', companyId, 'compliance', 'code', 'import', 'post'],
    mutationFn: (body) => api.internalImportCodes({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'compliance', 'code', 'import'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCodeGetOptions: GetOptionsFunction<Types.CodeGet> = ({ codeId, query, options }) => ({
  queryKey: ['code', codeId, query ?? {}],
  queryFn: () => api.codeGet({ codeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get code by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCodeGet = (args: QueryHookArguments<Types.CodeGet>) =>
  useTypedQuery<Types.CodeGet>(getCodeGetOptions(args))

export const useCodeUpdate: MutationHook<Types.CodeUpdate> = ({ codeId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update code`)

  return useTypedMutation<Types.CodeUpdate>({
    mutationKey: ['code', codeId, 'put'],
    mutationFn: (body) => api.codeUpdate({ codeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['code'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCodeExecute: MutationHook<Types.CodeExecute> = ({ codeId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Execute code`)

  return useTypedMutation<Types.CodeExecute>({
    mutationKey: ['code', codeId, 'post'],
    mutationFn: (body) => api.codeExecute({ codeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['code', codeId])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getTransactionsGetInternalOptions: GetOptionsFunction<Types.TransactionsGetInternal> = ({
  query,
  options,
}) => ({
  queryKey: ['transactions', query ?? {}],
  queryFn: () => api.transactionsGetInternal({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all transactions`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useTransactionsGetInternal = (args: QueryHookArguments<Types.TransactionsGetInternal>) =>
  useTypedQuery<Types.TransactionsGetInternal>(getTransactionsGetInternalOptions(args))

export const useTransactionsUpdateInternal: MutationHook<Types.TransactionsUpdateInternal> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update processed transactions`)

  return useTypedMutation<Types.TransactionsUpdateInternal>({
    mutationKey: ['transactions', 'post'],
    mutationFn: (body) => api.transactionsUpdateInternal({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['transactions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getTransactionsGetScheduledAchInternalOptions: GetOptionsFunction<
  Types.TransactionsGetScheduledAchInternal
> = ({ query, options }) => ({
  queryKey: ['transactions', 'scheduled', query ?? {}],
  queryFn: () => api.transactionsGetScheduledAchInternal({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get scheduled ACH transactions.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useTransactionsGetScheduledAchInternal = (
  args: QueryHookArguments<Types.TransactionsGetScheduledAchInternal>,
) => useTypedQuery<Types.TransactionsGetScheduledAchInternal>(getTransactionsGetScheduledAchInternalOptions(args))

export const useTransactionsUpdateScheduledAchInternal: MutationHook<Types.TransactionsUpdateScheduledAchInternal> = ({
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update processed ACH transactions.`)

  return useTypedMutation<Types.TransactionsUpdateScheduledAchInternal>({
    mutationKey: ['transactions', 'scheduled', 'post'],
    mutationFn: (body) => api.transactionsUpdateScheduledAchInternal({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['transactions', 'scheduled'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useTransactionsCreateRefundServiceCreditInternal: MutationHook<
  Types.TransactionsCreateRefundServiceCreditInternal
> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create service credit of type refund for LOC purchase`)

  return useTypedMutation<Types.TransactionsCreateRefundServiceCreditInternal>({
    mutationKey: ['transactions', 'refund', 'post'],
    mutationFn: (body) => api.transactionsCreateRefundServiceCreditInternal({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['transactions', 'refund'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getMicrodepositsGetScheduledInternalOptions: GetOptionsFunction<
  Types.MicrodepositsGetScheduledInternal
> = ({ query, options }) => ({
  queryKey: ['transactions', 'microdeposits', query ?? {}],
  queryFn: () => api.microdepositsGetScheduledInternal({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get scheduled microdeposits`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useMicrodepositsGetScheduledInternal = (
  args: QueryHookArguments<Types.MicrodepositsGetScheduledInternal>,
) => useTypedQuery<Types.MicrodepositsGetScheduledInternal>(getMicrodepositsGetScheduledInternalOptions(args))

export const useTransactionsUpdateMicrodepositsInternal: MutationHook<
  Types.TransactionsUpdateMicrodepositsInternal
> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update processed microdeposits.`)

  return useTypedMutation<Types.TransactionsUpdateMicrodepositsInternal>({
    mutationKey: ['transactions', 'microdeposits', 'post'],
    mutationFn: (body) => api.transactionsUpdateMicrodepositsInternal({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['transactions', 'microdeposits'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useMicrodepositsSendReminders: MutationHook<Types.MicrodepositsSendReminders> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Send microdeposit reminders.`)

  return useTypedMutation<Types.MicrodepositsSendReminders>({
    mutationKey: ['transactions', 'microdeposits', 'reminders', 'post'],
    mutationFn: (body) => api.microdepositsSendReminders({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['transactions', 'microdeposits', 'reminders'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalTransactionsGetLoanIds: MutationHook<Types.InternalTransactionsGetLoanIds> = ({
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError =
    useErrorHandler(`Get the loans associated with a list of transaction. Returns a map from transaction public ID to loan public ID.
Null values represent transactions with no associated loan.`)

  return useTypedMutation<Types.InternalTransactionsGetLoanIds>({
    mutationKey: ['transactions', 'loan-ids', 'post'],
    mutationFn: (body) => api.internalTransactionsGetLoanIds({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['transactions', 'loan-ids'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getTransactionsGetRawInternalHandlerOptions: GetOptionsFunction<
  Types.TransactionsGetRawInternalHandler
> = ({ companyId, loanTypeId, query, options }) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'transactions', query ?? {}],
  queryFn: () => api.transactionsGetRawInternalHandler({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all scheduled transactions`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useTransactionsGetRawInternalHandler = (
  args: QueryHookArguments<Types.TransactionsGetRawInternalHandler>,
) => useTypedQuery<Types.TransactionsGetRawInternalHandler>(getTransactionsGetRawInternalHandlerOptions(args))

export const getGetReconciliationTransactionsByCompanyOptions: GetOptionsFunction<
  Types.GetReconciliationTransactionsByCompany
> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'transactions', query ?? {}],
  queryFn: () => api.getReconciliationTransactionsByCompany({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Generic endpoint for getting transactions related to a company. Currently used for getting reconciliation transactions.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetReconciliationTransactionsByCompany = (
  args: QueryHookArguments<Types.GetReconciliationTransactionsByCompany>,
) => useTypedQuery<Types.GetReconciliationTransactionsByCompany>(getGetReconciliationTransactionsByCompanyOptions(args))

export const useCreateReconciliation: MutationHook<Types.CreateReconciliation> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(
    `Create transactions related to settlements and investors, such as a reconciliation transaction.`,
  )

  return useTypedMutation<Types.CreateReconciliation>({
    mutationKey: ['companies', companyId, 'transactions', 'post'],
    mutationFn: (body) => api.createReconciliation({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'transactions'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getCompanyTransactionsGetTransitionalOptions: GetOptionsFunction<
  Types.CompanyTransactionsGetTransitional
> = ({ companyId, query, options }) => ({
  queryKey: ['companies', companyId, 'transactions', 'auto-transition', query ?? {}],
  queryFn: () => api.companyTransactionsGetTransitional({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Transactions that may be due for automatic transitioning from pending`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useCompanyTransactionsGetTransitional = (
  args: QueryHookArguments<Types.CompanyTransactionsGetTransitional>,
) => useTypedQuery<Types.CompanyTransactionsGetTransitional>(getCompanyTransactionsGetTransitionalOptions(args))

export const getTransactionsGetAllExpiredHoldDaysOptions: GetOptionsFunction<
  Types.TransactionsGetAllExpiredHoldDays
> = ({ query, options }) => ({
  queryKey: ['transactions', 'expired-hold-days', query ?? {}],
  queryFn: () => api.transactionsGetAllExpiredHoldDays({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Transactions that have an expired hold_days_end_date and are either initiated or pending status.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useTransactionsGetAllExpiredHoldDays = (
  args: QueryHookArguments<Types.TransactionsGetAllExpiredHoldDays>,
) => useTypedQuery<Types.TransactionsGetAllExpiredHoldDays>(getTransactionsGetAllExpiredHoldDaysOptions(args))

export const getGetRoutingNumberOptions: GetOptionsFunction<Types.GetRoutingNumber> = ({
  routingNumber,
  query,
  options,
}) => ({
  queryKey: ['routing-numbers', routingNumber, query ?? {}],
  queryFn: () => api.getRoutingNumber({ routingNumber, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get routing number details`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetRoutingNumber = (args: QueryHookArguments<Types.GetRoutingNumber>) =>
  useTypedQuery<Types.GetRoutingNumber>(getGetRoutingNumberOptions(args))

export const useGetBinNumber: MutationHook<Types.GetBinNumber> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Get BIN details from card number`)

  return useTypedMutation<Types.GetBinNumber>({
    mutationKey: ['bin-numbers', 'post'],
    mutationFn: (body) => api.getBinNumber({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['bin-numbers'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getAutopayGetAllInternalCompanyOptions: GetOptionsFunction<Types.AutopayGetAllInternalCompany> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'autopays', query ?? {}],
  queryFn: () => api.autopayGetAllInternalCompany({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all active autopays`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useAutopayGetAllInternalCompany = (args: QueryHookArguments<Types.AutopayGetAllInternalCompany>) =>
  useTypedQuery<Types.AutopayGetAllInternalCompany>(getAutopayGetAllInternalCompanyOptions(args))

export const getAutopayGetAllInternalOptions: GetOptionsFunction<Types.AutopayGetAllInternal> = ({
  query,
  options,
}) => ({
  queryKey: ['autopays', query ?? {}],
  queryFn: () => api.autopayGetAllInternal({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all active autopays`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useAutopayGetAllInternal = (args: QueryHookArguments<Types.AutopayGetAllInternal>) =>
  useTypedQuery<Types.AutopayGetAllInternal>(getAutopayGetAllInternalOptions(args))

export const getGetNotificationDataInternalOptions: GetOptionsFunction<Types.GetNotificationDataInternal> = ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    'reminders',
    query ?? {},
  ],
  queryFn: () => api.getNotificationDataInternal({ companyId, loanTypeId, repaymentNotificationTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get reminders that should be sent today.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetNotificationDataInternal = (args: QueryHookArguments<Types.GetNotificationDataInternal>) =>
  useTypedQuery<Types.GetNotificationDataInternal>(getGetNotificationDataInternalOptions(args))

export const getGetNotificationTypesByCompanyOptions: GetOptionsFunction<Types.GetNotificationTypesByCompany> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'repayment-engine', 'notifications', query ?? {}],
  queryFn: () => api.getNotificationTypesByCompany({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all company notification types`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetNotificationTypesByCompany = (args: QueryHookArguments<Types.GetNotificationTypesByCompany>) =>
  useTypedQuery<Types.GetNotificationTypesByCompany>(getGetNotificationTypesByCompanyOptions(args))

export const getGetProductsWithNotificationsOptions: GetOptionsFunction<Types.GetProductsWithNotifications> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'repayment-engine', 'products-with-notifications', query ?? {}],
  queryFn: () => api.getProductsWithNotifications({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all products with notifications for a company`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetProductsWithNotifications = (args: QueryHookArguments<Types.GetProductsWithNotifications>) =>
  useTypedQuery<Types.GetProductsWithNotifications>(getGetProductsWithNotificationsOptions(args))

export const getGetSubjectsWithNotificationsOptions: GetOptionsFunction<Types.GetSubjectsWithNotifications> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'repayment-engine', 'subjects-with-notifications', query ?? {}],
  queryFn: () => api.getSubjectsWithNotifications({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get distinct subjects with notifications for a company`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetSubjectsWithNotifications = (args: QueryHookArguments<Types.GetSubjectsWithNotifications>) =>
  useTypedQuery<Types.GetSubjectsWithNotifications>(getGetSubjectsWithNotificationsOptions(args))

export const getGetTriggersWithNotificationsOptions: GetOptionsFunction<Types.GetTriggersWithNotifications> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'repayment-engine', 'triggers-with-notifications', query ?? {}],
  queryFn: () => api.getTriggersWithNotifications({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get distinct triggers with notifications for a company`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetTriggersWithNotifications = (args: QueryHookArguments<Types.GetTriggersWithNotifications>) =>
  useTypedQuery<Types.GetTriggersWithNotifications>(getGetTriggersWithNotificationsOptions(args))

export const getGetNotificationTypesInternalOptions: GetOptionsFunction<Types.GetNotificationTypesInternal> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loan-types', loanTypeId, 'repayment-engine', 'notifications', query ?? {}],
  queryFn: () => api.getNotificationTypesInternal({ companyId, loanTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get product notification types`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetNotificationTypesInternal = (args: QueryHookArguments<Types.GetNotificationTypesInternal>) =>
  useTypedQuery<Types.GetNotificationTypesInternal>(getGetNotificationTypesInternalOptions(args))

export const useCreateNotificationTypeInternal: MutationHook<Types.CreateNotificationTypeInternal> = ({
  companyId,
  loanTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a notification type`)

  return useTypedMutation<Types.CreateNotificationTypeInternal>({
    mutationKey: ['companies', companyId, 'loan-types', loanTypeId, 'repayment-engine', 'notifications', 'post'],
    mutationFn: (body) => api.createNotificationTypeInternal({ companyId, loanTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'loan-types',
        loanTypeId,
        'repayment-engine',
        'notifications',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetNotificationTypeByIdOptions: GetOptionsFunction<Types.GetNotificationTypeById> = ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'loan-types',
    loanTypeId,
    'repayment-engine',
    repaymentNotificationTypeId,
    query ?? {},
  ],
  queryFn: () => api.getNotificationTypeById({ companyId, loanTypeId, repaymentNotificationTypeId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get notification type by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetNotificationTypeById = (args: QueryHookArguments<Types.GetNotificationTypeById>) =>
  useTypedQuery<Types.GetNotificationTypeById>(getGetNotificationTypeByIdOptions(args))

export const useUpdateNotificationTypeInternal: MutationHook<Types.UpdateNotificationTypeInternal> = ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update a notification type`)

  return useTypedMutation<Types.UpdateNotificationTypeInternal>({
    mutationKey: [
      'companies',
      companyId,
      'loan-types',
      loanTypeId,
      'repayment-engine',
      repaymentNotificationTypeId,
      'put',
    ],
    mutationFn: (body) =>
      api.updateNotificationTypeInternal({ companyId, loanTypeId, repaymentNotificationTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types', loanTypeId, 'repayment-engine'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useDeleteNotificationTypeInternal: MutationHook<Types.DeleteNotificationTypeInternal> = ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete a notification type`)

  return useTypedMutation<Types.DeleteNotificationTypeInternal>({
    mutationKey: [
      'companies',
      companyId,
      'loan-types',
      loanTypeId,
      'repayment-engine',
      repaymentNotificationTypeId,
      'delete',
    ],
    mutationFn: (body) =>
      api.deleteNotificationTypeInternal({ companyId, loanTypeId, repaymentNotificationTypeId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'loan-types', loanTypeId, 'repayment-engine'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetRepaymentEngineInfoOptions: GetOptionsFunction<Types.GetRepaymentEngineInfo> = ({
  companyId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loans', loanId, 'repayment-engine-info', query ?? {}],
  queryFn: () => api.getRepaymentEngineInfo({ companyId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get all RPE fields for a given loan`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetRepaymentEngineInfo = (args: QueryHookArguments<Types.GetRepaymentEngineInfo>) =>
  useTypedQuery<Types.GetRepaymentEngineInfo>(getGetRepaymentEngineInfoOptions(args))

export const useHasher: MutationHook<Types.Hasher> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`The hasher`)

  return useTypedMutation<Types.Hasher>({
    mutationKey: ['internal', 'hasher', 'post'],
    mutationFn: (body) => api.hasher({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['internal', 'hasher'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetPeriodsOptions: GetOptionsFunction<Types.GetPeriods> = ({ query, options }) => ({
  queryKey: ['loan-periods', query ?? {}],
  queryFn: () => api.getPeriods({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan periods for all companies`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetPeriods = (args: QueryHookArguments<Types.GetPeriods>) =>
  useTypedQuery<Types.GetPeriods>(getGetPeriodsOptions(args))

export const useCreateStatement: MutationHook<Types.CreateStatement> = ({ periodId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create a statement`)

  return useTypedMutation<Types.CreateStatement>({
    mutationKey: ['loan-periods', periodId, 'statement', 'post'],
    mutationFn: (body) => api.createStatement({ periodId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['loan-periods', periodId, 'statement'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalSaveAutopay: MutationHook<Types.InternalSaveAutopay> = ({
  personId,
  loanId,
  statementId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Save statement autopay`)

  return useTypedMutation<Types.InternalSaveAutopay>({
    mutationKey: ['people', personId, 'loans', loanId, 'statements', statementId, 'save-autopay', 'post'],
    mutationFn: (body) => api.internalSaveAutopay({ personId, loanId, statementId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['people', personId, 'loans', loanId, 'statements', statementId, 'save-autopay'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalGenerateStatement: MutationHook<Types.InternalGenerateStatement> = ({
  companyId,
  personId,
  loanId,
  periodId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Generate statement`)

  return useTypedMutation<Types.InternalGenerateStatement>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'periods',
      periodId,
      'generate-statement',
      'post',
    ],
    mutationFn: (body) => api.internalGenerateStatement({ companyId, personId, loanId, periodId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'periods',
        periodId,
        'generate-statement',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useRevokeOrReinstateGrace: MutationHook<Types.RevokeOrReinstateGrace> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Revoke or reinstate grace period`)

  return useTypedMutation<Types.RevokeOrReinstateGrace>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'revoke-or-reinstate-grace', 'post'],
    mutationFn: (body) => api.revokeOrReinstateGrace({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'revoke-or-reinstate-grace',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalComputeBalancesForGrace: MutationHook<Types.InternalComputeBalancesForGrace> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Revoke or reinstate grace period`)

  return useTypedMutation<Types.InternalComputeBalancesForGrace>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'compute-balances-for-grace', 'post'],
    mutationFn: (body) => api.internalComputeBalancesForGrace({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'compute-balances-for-grace',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalConsentGetOptions: GetOptionsFunction<Types.InternalConsentGet> = ({
  companyId,
  personId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'consents', query ?? {}],
  queryFn: () => api.internalConsentGet({ companyId, personId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get consents`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalConsentGet = (args: QueryHookArguments<Types.InternalConsentGet>) =>
  useTypedQuery<Types.InternalConsentGet>(getInternalConsentGetOptions(args))

export const useInternalConsentUpdate: MutationHook<Types.InternalConsentUpdate> = ({
  companyId,
  personId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update consents`)

  return useTypedMutation<Types.InternalConsentUpdate>({
    mutationKey: ['companies', companyId, 'people', personId, 'consents', 'put'],
    mutationFn: (body) => api.internalConsentUpdate({ companyId, personId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'consents'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanRefundsGetOptions: GetOptionsFunction<Types.InternalLoanRefundsGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refunds', query ?? {}],
  queryFn: () => api.internalLoanRefundsGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get refunds`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanRefundsGet = (args: QueryHookArguments<Types.InternalLoanRefundsGet>) =>
  useTypedQuery<Types.InternalLoanRefundsGet>(getInternalLoanRefundsGetOptions(args))

export const useInternalLoanRefundsPost: MutationHook<Types.InternalLoanRefundsPost> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create refund`)

  return useTypedMutation<Types.InternalLoanRefundsPost>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refunds', 'post'],
    mutationFn: (body) => api.internalLoanRefundsPost({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'refunds'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getLoanRefundsGetOneOptions: GetOptionsFunction<Types.LoanRefundsGetOne> = ({
  companyId,
  personId,
  loanId,
  loanRefundId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refunds', loanRefundId, query ?? {}],
  queryFn: () => api.loanRefundsGetOne({ companyId, personId, loanId, loanRefundId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get refunds`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useLoanRefundsGetOne = (args: QueryHookArguments<Types.LoanRefundsGetOne>) =>
  useTypedQuery<Types.LoanRefundsGetOne>(getLoanRefundsGetOneOptions(args))

export const useInternalLoanRefundsV2Post: MutationHook<Types.InternalLoanRefundsV2Post> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create refund v2`)

  return useTypedMutation<Types.InternalLoanRefundsV2Post>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'refunds-v2', 'post'],
    mutationFn: (body) => api.internalLoanRefundsV2Post({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'refunds-v2'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalLoanActivate: MutationHook<Types.InternalLoanActivate> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Activate loan`)

  return useTypedMutation<Types.InternalLoanActivate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'activate', 'post'],
    mutationFn: (body) => api.internalLoanActivate({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'activate'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useLoanUpdateMigrationStatus: MutationHook<Types.LoanUpdateMigrationStatus> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update migration status`)

  return useTypedMutation<Types.LoanUpdateMigrationStatus>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'migration-status', 'put'],
    mutationFn: (body) => api.loanUpdateMigrationStatus({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'migration-status'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getExpandUrlOptions: GetOptionsFunction<Types.ExpandUrl> = ({ shortId, query, options }) => ({
  queryKey: ['expand-url', shortId, query ?? {}],
  queryFn: () => api.expandUrl({ shortId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Short URL expander`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useExpandUrl = (args: QueryHookArguments<Types.ExpandUrl>) =>
  useTypedQuery<Types.ExpandUrl>(getExpandUrlOptions(args))

export const getInternalGetStatementsOptions: GetOptionsFunction<Types.InternalGetStatements> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'statements', query ?? {}],
  queryFn: () => api.internalGetStatements({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get statements`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetStatements = (args: QueryHookArguments<Types.InternalGetStatements>) =>
  useTypedQuery<Types.InternalGetStatements>(getInternalGetStatementsOptions(args))

export const useDeleteStatement: MutationHook<Types.DeleteStatement> = ({
  companyId,
  personId,
  loanId,
  statementId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete statement`)

  return useTypedMutation<Types.DeleteStatement>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'statements', statementId, 'delete'],
    mutationFn: (body) => api.deleteStatement({ companyId, personId, loanId, statementId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'statements'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanDrawLocIdGetOptions: GetOptionsFunction<Types.InternalLoanDrawLocIdGet> = ({
  drawId,
  query,
  options,
}) => ({
  queryKey: ['loans', 'draws', drawId, 'line-of-credit-id', query ?? {}],
  queryFn: () => api.internalLoanDrawLocIdGet({ drawId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get Draw Line of Credit ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanDrawLocIdGet = (args: QueryHookArguments<Types.InternalLoanDrawLocIdGet>) =>
  useTypedQuery<Types.InternalLoanDrawLocIdGet>(getInternalLoanDrawLocIdGetOptions(args))

export const getInternalLoanDrawsGetOptions: GetOptionsFunction<Types.InternalLoanDrawsGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'draws', query ?? {}],
  queryFn: () => api.internalLoanDrawsGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get draws`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanDrawsGet = (args: QueryHookArguments<Types.InternalLoanDrawsGet>) =>
  useTypedQuery<Types.InternalLoanDrawsGet>(getInternalLoanDrawsGetOptions(args))

export const useInternalLoanDrawsPost: MutationHook<Types.InternalLoanDrawsPost> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create draw`)

  return useTypedMutation<Types.InternalLoanDrawsPost>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'draws', 'post'],
    mutationFn: (body) => api.internalLoanDrawsPost({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'draws'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalDrawActivate: MutationHook<Types.InternalDrawActivate> = ({
  companyId,
  personId,
  loanId,
  drawId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Activate draw`)

  return useTypedMutation<Types.InternalDrawActivate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'draws', drawId, 'activate', 'post'],
    mutationFn: (body) => api.internalDrawActivate({ companyId, personId, loanId, drawId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'draws',
        drawId,
        'activate',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalLoanDrawsGetWithoutBorrowerOptions: GetOptionsFunction<
  Types.InternalLoanDrawsGetWithoutBorrower
> = ({ companyId, loanId, query, options }) => ({
  queryKey: ['companies', companyId, 'loans', loanId, 'draws', query ?? {}],
  queryFn: () => api.internalLoanDrawsGetWithoutBorrower({ companyId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get draws`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalLoanDrawsGetWithoutBorrower = (
  args: QueryHookArguments<Types.InternalLoanDrawsGetWithoutBorrower>,
) => useTypedQuery<Types.InternalLoanDrawsGetWithoutBorrower>(getInternalLoanDrawsGetWithoutBorrowerOptions(args))

export const useInternalCloseLocIfInactive: MutationHook<Types.InternalCloseLocIfInactive> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Close inactive line`)

  return useTypedMutation<Types.InternalCloseLocIfInactive>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'close-inactive-line', 'post'],
    mutationFn: (body) => api.internalCloseLocIfInactive({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'close-inactive-line',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalDrawChargeVariableDrawFee: MutationHook<Types.InternalDrawChargeVariableDrawFee> = ({
  companyId,
  personId,
  loanId,
  drawId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Charge variable draw fee`)

  return useTypedMutation<Types.InternalDrawChargeVariableDrawFee>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'charge-variable-draw-fee',
      'post',
    ],
    mutationFn: (body) => api.internalDrawChargeVariableDrawFee({ companyId, personId, loanId, drawId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'draws',
        drawId,
        'charge-variable-draw-fee',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalClearMinPayments: MutationHook<Types.InternalClearMinPayments> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh draw min payments during a replay`)

  return useTypedMutation<Types.InternalClearMinPayments>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'clear-min-payments', 'post'],
    mutationFn: (body) => api.internalClearMinPayments({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'clear-min-payments'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useRefreshAmortizedDraw: MutationHook<Types.RefreshAmortizedDraw> = ({
  companyId,
  personId,
  loanId,
  drawId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Refresh payments after purchase`)

  return useTypedMutation<Types.RefreshAmortizedDraw>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'draws', drawId, 'refresh', 'post'],
    mutationFn: (body) => api.refreshAmortizedDraw({ companyId, personId, loanId, drawId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'draws',
        drawId,
        'refresh',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalDrawPurchasesGetOptions: GetOptionsFunction<Types.InternalDrawPurchasesGet> = ({
  companyId,
  personId,
  loanId,
  drawId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'draws', drawId, 'purchases', query ?? {}],
  queryFn: () => api.internalDrawPurchasesGet({ companyId, personId, loanId, drawId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get purchases`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalDrawPurchasesGet = (args: QueryHookArguments<Types.InternalDrawPurchasesGet>) =>
  useTypedQuery<Types.InternalDrawPurchasesGet>(getInternalDrawPurchasesGetOptions(args))

export const useInternalDrawPurchasesCreate: MutationHook<Types.InternalDrawPurchasesCreate> = ({
  companyId,
  personId,
  loanId,
  drawId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create purchase`)

  return useTypedMutation<Types.InternalDrawPurchasesCreate>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'draws', drawId, 'purchases', 'post'],
    mutationFn: (body) => api.internalDrawPurchasesCreate({ companyId, personId, loanId, drawId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'draws',
        drawId,
        'purchases',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalDrawPurchaseGetOneOptions: GetOptionsFunction<Types.InternalDrawPurchaseGetOne> = ({
  companyId,
  personId,
  loanId,
  drawId,
  purchaseId,
  query,
  options,
}) => ({
  queryKey: [
    'companies',
    companyId,
    'people',
    personId,
    'loans',
    loanId,
    'draws',
    drawId,
    'purchases',
    purchaseId,
    query ?? {},
  ],
  queryFn: () => api.internalDrawPurchaseGetOne({ companyId, personId, loanId, drawId, purchaseId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get purchase by ID`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalDrawPurchaseGetOne = (args: QueryHookArguments<Types.InternalDrawPurchaseGetOne>) =>
  useTypedQuery<Types.InternalDrawPurchaseGetOne>(getInternalDrawPurchaseGetOneOptions(args))

export const useInternalDrawPurchaseUpdate: MutationHook<Types.InternalDrawPurchaseUpdate> = ({
  companyId,
  personId,
  loanId,
  drawId,
  purchaseId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update purchase`)

  return useTypedMutation<Types.InternalDrawPurchaseUpdate>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'purchases',
      purchaseId,
      'put',
    ],
    mutationFn: (body) =>
      api.internalDrawPurchaseUpdate({ companyId, personId, loanId, drawId, purchaseId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'draws',
        drawId,
        'purchases',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalDrawPurchaseChargeFees: MutationHook<Types.InternalDrawPurchaseChargeFees> = ({
  companyId,
  personId,
  loanId,
  drawId,
  purchaseId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Charge fees if necessary`)

  return useTypedMutation<Types.InternalDrawPurchaseChargeFees>({
    mutationKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'purchases',
      purchaseId,
      'charge-fees',
      'post',
    ],
    mutationFn: (body) =>
      api.internalDrawPurchaseChargeFees({ companyId, personId, loanId, drawId, purchaseId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries([
        'companies',
        companyId,
        'people',
        personId,
        'loans',
        loanId,
        'draws',
        drawId,
        'purchases',
        purchaseId,
        'charge-fees',
      ])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalCaseEscalationsGetOptions: GetOptionsFunction<Types.InternalCaseEscalationsGet> = ({
  companyId,
  caseId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'cases', caseId, 'escalations', query ?? {}],
  queryFn: () => api.internalCaseEscalationsGet({ companyId, caseId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `List case escalations`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalCaseEscalationsGet = (args: QueryHookArguments<Types.InternalCaseEscalationsGet>) =>
  useTypedQuery<Types.InternalCaseEscalationsGet>(getInternalCaseEscalationsGetOptions(args))

export const useInternalCaseEscalationsCreate: MutationHook<Types.InternalCaseEscalationsCreate> = ({
  companyId,
  caseId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Escalate or de-escalate a case`)

  return useTypedMutation<Types.InternalCaseEscalationsCreate>({
    mutationKey: ['companies', companyId, 'cases', caseId, 'escalations', 'post'],
    mutationFn: (body) => api.internalCaseEscalationsCreate({ companyId, caseId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'cases', caseId, 'escalations'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getLoanStateGetOptions: GetOptionsFunction<Types.LoanStateGet> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'state', query ?? {}],
  queryFn: () => api.loanStateGet({ companyId, personId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get loan state`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useLoanStateGet = (args: QueryHookArguments<Types.LoanStateGet>) =>
  useTypedQuery<Types.LoanStateGet>(getLoanStateGetOptions(args))

export const useLoanStatePost: MutationHook<Types.LoanStatePost> = ({
  companyId,
  personId,
  loanId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update loan state`)

  return useTypedMutation<Types.LoanStatePost>({
    mutationKey: ['companies', companyId, 'people', personId, 'loans', loanId, 'state', 'post'],
    mutationFn: (body) => api.loanStatePost({ companyId, personId, loanId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'people', personId, 'loans', loanId, 'state'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCompanyRotateTokenizationKey: MutationHook<Types.CompanyRotateTokenizationKey> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Rotate a company's encryption keys`)

  return useTypedMutation<Types.CompanyRotateTokenizationKey>({
    mutationKey: ['companies', companyId, 'rotate-tokenization-key', 'post'],
    mutationFn: (body) => api.companyRotateTokenizationKey({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'rotate-tokenization-key'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useCleanPaymentInstruments: MutationHook<Types.CleanPaymentInstruments> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(
    `Clean out tokens of card based payment instruments that have not been used in the past 2 years`,
  )

  return useTypedMutation<Types.CleanPaymentInstruments>({
    mutationKey: ['companies', companyId, 'payment-instruments', 'clean', 'post'],
    mutationFn: (body) => api.cleanPaymentInstruments({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'payment-instruments', 'clean'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getTestSpawnTaskOptions: GetOptionsFunction<Types.TestSpawnTask> = ({ query, options }) => ({
  queryKey: ['internal', 'operations', 'test', query ?? {}],
  queryFn: () => api.testSpawnTask({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Test operation`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useTestSpawnTask = (args: QueryHookArguments<Types.TestSpawnTask>) =>
  useTypedQuery<Types.TestSpawnTask>(getTestSpawnTaskOptions(args))

export const useChargebackReportPost: MutationHook<Types.ChargebackReportPost> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Process chargeback reports`)

  return useTypedMutation<Types.ChargebackReportPost>({
    mutationKey: ['sendgrid', 'webhooks', 'chargeback-report', 'post'],
    mutationFn: (body) => api.chargebackReportPost({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['sendgrid', 'webhooks', 'chargeback-report'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalOperationStatusGetOptions: GetOptionsFunction<Types.InternalOperationStatusGet> = ({
  operationId,
  query,
  options,
}) => ({
  queryKey: ['internal', 'operations', operationId, query ?? {}],
  queryFn: () => api.internalOperationStatusGet({ operationId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get operation status`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalOperationStatusGet = (args: QueryHookArguments<Types.InternalOperationStatusGet>) =>
  useTypedQuery<Types.InternalOperationStatusGet>(getInternalOperationStatusGetOptions(args))

export const getGetMissingSnapshotsOptions: GetOptionsFunction<Types.GetMissingSnapshots> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'snapshots', 'missing', query ?? {}],
  queryFn: () => api.getMissingSnapshots({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get missing snapshots`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetMissingSnapshots = (args: QueryHookArguments<Types.GetMissingSnapshots>) =>
  useTypedQuery<Types.GetMissingSnapshots>(getGetMissingSnapshotsOptions(args))

export const getInternalGetPaymentProcessorsOptions: GetOptionsFunction<Types.InternalGetPaymentProcessors> = ({
  companyId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'payment-processors', query ?? {}],
  queryFn: () => api.internalGetPaymentProcessors({ companyId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get payment processors`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetPaymentProcessors = (args: QueryHookArguments<Types.InternalGetPaymentProcessors>) =>
  useTypedQuery<Types.InternalGetPaymentProcessors>(getInternalGetPaymentProcessorsOptions(args))

export const useInternalCreatePaymentProcessor: MutationHook<Types.InternalCreatePaymentProcessor> = ({
  companyId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create payment processor`)

  return useTypedMutation<Types.InternalCreatePaymentProcessor>({
    mutationKey: ['companies', companyId, 'payment-processors', 'post'],
    mutationFn: (body) => api.internalCreatePaymentProcessor({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'payment-processors'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalGetOnePaymentProcessorOptions: GetOptionsFunction<Types.InternalGetOnePaymentProcessor> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'payment-processors', paymentProcessorId, query ?? {}],
  queryFn: () => api.internalGetOnePaymentProcessor({ companyId, paymentProcessorId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get payment processor`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetOnePaymentProcessor = (args: QueryHookArguments<Types.InternalGetOnePaymentProcessor>) =>
  useTypedQuery<Types.InternalGetOnePaymentProcessor>(getInternalGetOnePaymentProcessorOptions(args))

export const useInternalUpdatePaymentProcessor: MutationHook<Types.InternalUpdatePaymentProcessor> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update payment processor`)

  return useTypedMutation<Types.InternalUpdatePaymentProcessor>({
    mutationKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'put'],
    mutationFn: (body) => api.internalUpdatePaymentProcessor({ companyId, paymentProcessorId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'payment-processors'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const useInternalDeletePaymentProcessor: MutationHook<Types.InternalDeletePaymentProcessor> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Delete payment processor`)

  return useTypedMutation<Types.InternalDeletePaymentProcessor>({
    mutationKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'delete'],
    mutationFn: (body) => api.internalDeletePaymentProcessor({ companyId, paymentProcessorId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'payment-processors'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalGetAchFilesOptions: GetOptionsFunction<Types.InternalGetAchFiles> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'ach-files', query ?? {}],
  queryFn: () => api.internalGetAchFiles({ companyId, paymentProcessorId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get ACH file monitoring records`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetAchFiles = (args: QueryHookArguments<Types.InternalGetAchFiles>) =>
  useTypedQuery<Types.InternalGetAchFiles>(getInternalGetAchFilesOptions(args))

export const useInternalCreateAchFile: MutationHook<Types.InternalCreateAchFile> = ({
  companyId,
  paymentProcessorId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Create ACH file monitoring record`)

  return useTypedMutation<Types.InternalCreateAchFile>({
    mutationKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'ach-files', 'post'],
    mutationFn: (body) => api.internalCreateAchFile({ companyId, paymentProcessorId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'payment-processors', paymentProcessorId, 'ach-files'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getInternalGetOneAchFileOptions: GetOptionsFunction<Types.InternalGetOneAchFile> = ({
  companyId,
  paymentProcessorId,
  achFileId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'ach-files', achFileId, query ?? {}],
  queryFn: () => api.internalGetOneAchFile({ companyId, paymentProcessorId, achFileId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get ACH file upload monitoring record`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useInternalGetOneAchFile = (args: QueryHookArguments<Types.InternalGetOneAchFile>) =>
  useTypedQuery<Types.InternalGetOneAchFile>(getInternalGetOneAchFileOptions(args))

export const useInternalUpdateAchFile: MutationHook<Types.InternalUpdateAchFile> = ({
  companyId,
  paymentProcessorId,
  achFileId,
  query,
  options,
}) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Update ACH file upload monitoring record`)

  return useTypedMutation<Types.InternalUpdateAchFile>({
    mutationKey: ['companies', companyId, 'payment-processors', paymentProcessorId, 'ach-files', achFileId, 'put'],
    mutationFn: (body) => api.internalUpdateAchFile({ companyId, paymentProcessorId, achFileId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'payment-processors', paymentProcessorId, 'ach-files'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const getGetLoanDataOptions: GetOptionsFunction<Types.GetLoanData> = ({
  companyId,
  loanId,
  query,
  options,
}) => ({
  queryKey: ['companies', companyId, 'loans', loanId, 'loan-ingest', query ?? {}],
  queryFn: () => api.getLoanData({ companyId, loanId, query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Get data needed for loan ingest processor.`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetLoanData = (args: QueryHookArguments<Types.GetLoanData>) =>
  useTypedQuery<Types.GetLoanData>(getGetLoanDataOptions(args))

export const getGetSsoTokenOptions: GetOptionsFunction<Types.GetSsoToken> = ({ query, options }) => ({
  queryKey: ['zendesk', 'sso-token', query ?? {}],
  queryFn: () => api.getSsoToken({ query }),
  onError: (e: unknown) => {
    if (isError(e)) {
      e.operation = `Generate Zendesk SSO JWT`
    }
  },
  useErrorBoundary: true,
  ...options,
})

export const useGetSsoToken = (args: QueryHookArguments<Types.GetSsoToken>) =>
  useTypedQuery<Types.GetSsoToken>(getGetSsoTokenOptions(args))

export const useSetSsoSharedSecret: MutationHook<Types.SetSsoSharedSecret> = ({ companyId, query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Set the Zendesk SSO shared secret for this company.`)

  return useTypedMutation<Types.SetSsoSharedSecret>({
    mutationKey: ['companies', companyId, 'zendesk', 'sso-shared-secret', 'post'],
    mutationFn: (body) => api.setSsoSharedSecret({ companyId, body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['companies', companyId, 'zendesk', 'sso-shared-secret'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}

export const usePublishMessage: MutationHook<Types.PublishMessage> = ({ query, options }) => {
  const queryClient = useQueryClient()
  const onError = useErrorHandler(`Publish a message to a pubsub queue,
for internal use only!`)

  return useTypedMutation<Types.PublishMessage>({
    mutationKey: ['publish-message', 'post'],
    mutationFn: (body) => api.publishMessage({ body, query }),
    ...options,
    onSuccess: (data, variables, context) => {
      if (options?.onSuccess?.(data, variables, context)) return

      queryClient.invalidateQueries(['publish-message'])
    },
    onError: (e, variables, context) => {
      options?.onError?.(e, variables, context) || onError(e)
    },
  })
}
