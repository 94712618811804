const mockChatContent = {
  messages: [
    {
      body: 'My handwritten reason.',
    },
  ],
  participants: {
    borrowerParticipantId: {
      attributes: {
        borrowerId: 'borrowerId-123',
        displayName: 'Bob B.',
      },
    },
    agentParticipantId: {
      attributes: {
        employeeId: 'employeeId-123',
        displayName: 'James B.',
      },
    },
  },
}

export default mockChatContent
