import _ from 'lodash'

import mockBaseInteraction from './mockBaseInteraction'
import mockChatContent from './mockChatContent'
import mockEmailContent from './mockEmailContent'
import mockMailContent from './mockMailContent'
import mockTextContent from './mockTextContent'
import mockVoiceContent from './mockVoiceContent'

/**
 * There are four types of channels that can exist in any
 * given interaction. Those include: email, mail, voice
 * and text. All four of these channels is associated with
 * a specific content property object, which are
 * individually imported into this file.
 */

const generateMockEmailContent = (content) => {
  return { ...mockEmailContent, ...content }
}

const generateMockMailContent = (content) => {
  return { ...mockMailContent, ...content }
}

const generateVoiceContent = (content) => {
  return { ...mockVoiceContent, ...content }
}

const generateMockTextContent = (content) => {
  return { ...mockTextContent, ...content }
}

const generateMockChatContent = (content) => {
  return { ...mockChatContent, ...content }
}

const generateMockContent = ({ channel, content }) => {
  switch (channel) {
    case 'email':
      return generateMockEmailContent(content)
    case 'mail':
      return generateMockMailContent(content)
    case 'voice':
      return generateVoiceContent(content)
    case 'text':
      return generateMockTextContent(content)
    case 'chat':
      return generateMockChatContent(content)
    default:
      throw new Error('channel is required for mock')
  }
}

/**
 *
 * @param {Object|null} options
 *
 * The below mock interaction generator will generate a
 * single interaction based primarily on the channel of the
 * option passed in. If no channel is specified it will
 * default to an email interaction. All other options are
 * channel agnostic.
 */
const generateMockInteraction = (options = {}) => {
  const id = options.id || _.uniqueId('IN-AUTO-')
  const newContent = generateMockContent({
    channel: options.channel,
    content: options.content,
  })

  return {
    ...mockBaseInteraction,
    ...options,
    id,
    channel: options.channel,
    content: { ...newContent },
  }
}

export default generateMockInteraction
