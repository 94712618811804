import { useState } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'

/**
 * TODO: Make considerations of whether we want to use the
 * react query devtools. Either uncomment the code and add it
 * or completely remove it. Perhaps wait serveral months and
 * see if we want to use it before making a final decision.
 *
 * Comment date: Jan 2022
 */
// import { ReactQueryDevtools } from 'react-query/devtools'

const QueryProvider = ({ children }: { children: React.ReactNode }) => {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}

export default QueryProvider
