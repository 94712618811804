export default function truncatePageNumbers({
  currentPage,
  totalPages,
  maxPagesVisible,
  numPagesOnEnds,
}: {
  currentPage: number
  totalPages: number
  maxPagesVisible: number
  numPagesOnEnds: number
}) {
  // No truncation required: return an array of sequential numbers
  if (totalPages <= maxPagesVisible) return fillArray(totalPages, (i) => i)

  // Helper for determining if a number should be added to the array when filling
  // * assumes numbers at the start and end (numPagesOnEnd) already exist in array
  // * return false for any numbers that fall in those ranges
  const isPageNumberInRange = (page: number) => page > numPagesOnEnds - 1 && page < totalPages - numPagesOnEnds

  // Produce an array of unique numbers within the allowed range
  // * remove negative numbers
  // * remove numbers that exceed totalPages
  // * remove duplicates: require first index of the number is the current index
  const uniqueNumbersInRange = (page: number, index: number, self: number[]) =>
    page > -1 && page < totalPages && self.indexOf(page) === index

  // Start with an array of unique pages that are always visible
  // * currentPage
  // * numPagesAtEnds range at start (eg. 1, 2, 3)
  // * numPagesAtEnds range at end (eg. 9, 10, 11)
  let pages = [
    currentPage,
    ...fillArray(numPagesOnEnds, (i) => i),
    ...fillArray(numPagesOnEnds, (i) => totalPages - i - 1),
  ].filter(uniqueNumbersInRange)

  // Fill the array of pages evenly until length === maxPagesVisible
  let i = 1
  while (pages.length < maxPagesVisible) {
    // Attempt to fill to the left of currentPage until it exceeds acceptable range
    // Immediately stop filling if we've reached maxPagesVisible
    if (isPageNumberInRange(currentPage - i)) pages.push(currentPage - i)
    if (pages.length === maxPagesVisible) break
    // Attempt to fill to the right of currentPage until it exceeds acceptable range
    // Immediately stop filling if we've reached maxPagesVisible
    if (isPageNumberInRange(currentPage + i)) pages.push(currentPage + i)
    if (pages.length === maxPagesVisible) break
    i++
  }

  // Sort in sequential order
  pages = pages.sort((a, b) => a - b)

  // Return the array with -1 values inserted where truncation has occurred
  return withGaps(pages, numPagesOnEnds)
}

// Helper function to fill an array with sequential values to a specified length
function fillArray(count: number, mapFn: (i: number) => number) {
  return [...Array(count).keys()].map(mapFn)
}

// Helper function to find where truncation has occurred and insert -1 values for UI interpretation
function withGaps(pages: number[], numPagesOnEnds: number) {
  // Start with a copy of the pages array
  const newPages = [...pages]
  // Check if truncation has occurred immediately after the always-visible
  // page numbers at the start of the array
  if (newPages[numPagesOnEnds] - newPages[numPagesOnEnds - 1] > 1) {
    newPages[numPagesOnEnds] = -1
  }
  // Check if truncation has occurred immediately before the always-visible
  // page numbers at the end of the array
  if (newPages[pages.length - numPagesOnEnds] - newPages[pages.length - numPagesOnEnds - 1] > 1) {
    newPages[pages.length - numPagesOnEnds - 1] = -1
  }
  return newPages
}
