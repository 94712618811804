import { FC } from 'react'

import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import TextInput from 'core/components/lib/TextInput'
import deCamelize from 'core/helpers/deCamelize'

import { PeachOverrideId, peachOverridesOptions } from '../config'

interface PeachOverride {
  id: string
  requires?: {
    id: string
    value: any
  }
  requiresAuth?: boolean
  requiresReload?: boolean
  section: 'apiEndpoint' | 'appTypeAndCompany' | 'misc' | 'apiMockOptions' | 'apiMockEndpoints'
  type: 'select' | 'toggle' | 'text'
  resetsOnChange?: string[]
}

export interface PeachOverrideText extends PeachOverride {
  value: string
  placeholder?: string
}

export interface PeachOverrideToggle extends PeachOverride {
  value: boolean
}

export interface PeachOverrideSelect extends PeachOverride {
  value: string
  options: string[]
}

export type PeachOverrideVariant = PeachOverrideText | PeachOverrideToggle | PeachOverrideSelect

export type OverridesFormValues = Record<PeachOverrideId, PeachOverrideVariant['value']>

type OnOverrideChange = (event: any) => void

const Override: FC<PeachOverrideVariant> = (props) => {
  const form = useFormContext<OverridesFormValues>()
  const isVisible = props.requires ? form.watch(props.requires.id as PeachOverrideId) === props.requires.value : true

  if (!isVisible) return null

  const onOverrideChange: OnOverrideChange = (_event) => {
    if (props.resetsOnChange && props.resetsOnChange.length) {
      for (const idToReset of props.resetsOnChange) {
        form.setValue(idToReset as PeachOverrideId, peachOverridesOptions[idToReset].value)
      }
    }
  }

  switch (props.type) {
    case 'select':
      return <SelectOverride {...(props as PeachOverrideSelect)} onChange={onOverrideChange} />
    case 'text':
      return <TextOverride {...(props as PeachOverrideText)} onChange={onOverrideChange} />
    case 'toggle':
      return <ToggleOverride {...(props as PeachOverrideToggle)} onChange={onOverrideChange} />
    default:
      // eslint-disable-next-line no-console
      console.warn(`Unhandled override type: ${props.type}`)
      return null
  }
}

type OverrideProps = {
  onChange: OnOverrideChange
}

const SelectOverride: FC<OverrideProps & PeachOverrideSelect> = ({ id, options, onChange }) => {
  const form = useFormContext<OverridesFormValues>()
  return (
    <>
      <StyledLabel>{deCamelize(id)}</StyledLabel>
      <StyledSelectInput {...form.register(id as PeachOverrideId, { onChange })}>
        {options.map((o) => (
          <option key={`${id}-${o}`} value={o}>
            {deCamelize(o)}
          </option>
        ))}
      </StyledSelectInput>
    </>
  )
}

const TextOverride: FC<OverrideProps & PeachOverrideText> = ({ id, placeholder, onChange }) => {
  const form = useFormContext<OverridesFormValues>()
  return (
    <>
      <StyledLabel>{deCamelize(id)}</StyledLabel>
      <StyledTextInput placeholder={placeholder} {...form.register(id as PeachOverrideId, { onChange })} />
    </>
  )
}

const ToggleOverride: FC<OverrideProps & PeachOverrideToggle> = ({ id, onChange }) => {
  const form = useFormContext<OverridesFormValues>()
  return (
    <StyledLabel htmlFor={id}>
      <input id={id} type='checkbox' {...form.register(id as PeachOverrideId, { onChange })} />
      {deCamelize(id)}
    </StyledLabel>
  )
}

export const renderOverride = (override: PeachOverrideVariant | undefined, index: number) => {
  if (override) {
    return <Override key={`apiEndpoint-${index}`} {...override} />
  }
  return null
}

const inputStyles = css`
  box-sizing: border-box;
  width: 100%;
  padding: 2px 4px;
  font-size: 12px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 1px;
`

const StyledTextInput = styled(TextInput)`
  ${inputStyles}
`

const StyledSelectInput = styled.select`
  ${inputStyles}
`

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 12px;

  input {
    margin-right: 8px;
  }
`
