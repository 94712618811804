import { FC } from 'react'

import AlertDialog, { AlertDialogProps } from 'core/components/lib/AlertDialog'

const PermissionDeniedDialog: FC<Omit<AlertDialogProps, 'content'>> = ({ title = 'Permission', ...props }) => {
  return (
    <AlertDialog
      title={title}
      content='You do not have permission to perform this action. Please contact your supervisor for help.'
      {...props}
    />
  )
}

export default PermissionDeniedDialog
