import React, { Component } from 'react'

import PropTypes from 'prop-types'
import InputMaskBase from 'react-input-mask'
import styled, { css } from 'styled-components'

import disableTrailingSpaces from 'core/helpers/disableTrailingSpaces'
import variables from 'core/styles/variables'

const containerStyles = css`
  display: infline-flex;
  flex: 1 0 0;

  &:focus-visible {
    outline: none;
  }

  width: ${(p) => (p.width ? `${p.width}px` : '100%')};
`

const Amount = styled.div`
  ${containerStyles}
  display: flex;

  input {
    padding-left: 4px;
    border-bottom: 1px solid ${variables.colorBlack30};
  }
`

const Percentage = styled.div`
  ${containerStyles}
`

const NumberInput = styled.input`
  ${containerStyles}
`

const UnderlineInput = styled.input`
  ${containerStyles}
  text-overflow: ${(props) => (props.withEllipsis ? 'ellipsis' : 'inherit')};
  border-bottom: ${(props) => (props.disableUnderline ? 'none' : `1px solid ${variables.colorBlack30}`)};
  ${(p) => (p.fullWidth ? 'width: 100%;' : '')}
`

const AmountPrefix = styled.span`
  align-self: center;
  color: var(--colorBlack60);
`

const InputWrapper = styled.input`
  ${containerStyles}
  text-overflow: ${(props) => (props.withEllipsis ? 'ellipsis' : 'inherit')};
`

const InputMask = styled(InputMaskBase)`
  ${containerStyles}
`

class MaskedInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      cardNumberMask: '9999 9999 9999 9999',
      cvcMask: '9999',
      amountValue: this.props.useValueForAmount ? this.props.value : '',
    }

    this.userInput = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const { value, inputType, disabled, autoFocus } = this.props

    if (!disabled && autoFocus) {
      this.userInput.current.focus()
    }

    // Hack to force amount input type to update the state if
    // the prop value has changed.
    if (inputType === 'amount' && value && prevProps.value !== value) {
      this.handleAmountOnchange({ target: { value } })
    }

    // TODO: have autofocus property instead of the following
    if (this.prop?.autoFocus) {
      this.userInput.current.focus()
    }
  }

  handleAmountOnchange = (e) => {
    this.props.onChange(e)
    const value = e.target.value
    const pattern = /^(\d+)?([.]?\d{0,2})?$/

    if (pattern.test(value)) {
      this.setState({
        amountValue: value,
      })
    } else {
      return false
    }
  }

  // TODO: add input patterns during validaitons
  // NOTE: keys are subject to change depending on real data
  _getInputType = (type) => {
    const { customStyle, placeholder, width, color, withBottomBorder, maxLength, unstyled, inputType } = this.props

    const baseStyle = {
      display: 'inline-flex',
      border: 'none',
      borderBottom: withBottomBorder ? `1px solid ${variables.colorBlack30}` : 'none',
      backgroundColor: 'transparent',
      // TODO: do we want this as a base style?
      // disabling this for now, as it's breaking certain areas
      // padding: '0',
      width: width ? `${width}px` : undefined,
      fontFamily: 'inherit',
      fontSize: '1.6rem',
      color: color ? `${color}` : 'inherit',
    }

    const style = { ...baseStyle, ...customStyle }

    const styleOrUnstyled = unstyled ? undefined : style

    const handleCardOnchange = (e) => {
      this.props.onChange(e)

      const newState = {
        cardNumberMask: '9999 9999 9999 9999',
        cvcMask: '9999',
      }

      if (inputType === 'cardNumber') {
        const value = e.target.value

        // formatted for amex, if it starts with 34 or 37
        // supports 3 or 4 security code
        if (/^3[47]/.test(value)) {
          newState.cardNumberMask = '9999 999999 99999'
          newState.cvcMask = '9999'
        } else {
          newState.cardNumberMask = '9999 9999 9999 9999'
          newState.cvcMask = '999'
        }
        this.setState(newState)
      }
    }

    const inputTypes = {
      tel: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            autoFocus={this.props.autoFocus}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            // alwaysShowMask
            maskPlaceholder={null}
            mask='(999) 999 - 9999'
            value={this.props.value}
            ref={this.props.customInputRef || this.userInput}
            onChange={(e) => this.props.onChange(e)}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            maxLength={maxLength}
          />
        )
      },
      bankCodes: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            alwaysShowMask
            mask='$0.99'
            value={this.props.value || ''}
            onChange={(e) => this.props.onChange(e)}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      sixDigitVerificationCodes: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            // alwaysShowMask
            maskPlaceholder={null}
            mask='999999'
            value={this.props.value}
            onChange={(e) => this.props.onChange(e)}
            style={styleOrUnstyled}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      email: () => {
        return (
          <InputWrapper
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            withEllipsis={this.props.withEllipsis}
            type='email'
            ref={this.props.customRef || this.userInput}
            value={this.props.value}
            onChange={(e) => this.props.onChange(e)}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            placeholder={this.props.placeholder}
            maxLength={maxLength}
          />
        )
      },
      password: () => {
        return (
          <input
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            type='password'
            ref={this.props.customRef || this.userInput}
            value={this.props.value}
            onChange={(e) => this.props.onChange(e)}
            placeholder={this.props.placeholder}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      text: () => {
        return (
          <UnderlineInput
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            type='text'
            withEllipsis={this.props.withEllipsis}
            fullWidth={this.props.fullWidth}
            ref={this.props.customRef || this.userInput}
            value={this.props.value}
            disableUnderline={this.props.disableUnderline}
            onChange={(e) => this.props.onChange(e)}
            style={styleOrUnstyled}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            onKeyDown={(e) => [this.props.onChange(e), disableTrailingSpaces(e)]}
            maxLength={maxLength}
          />
        )
      },
      number: () => {
        return (
          <NumberInput
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            type='number'
            ref={this.userInput}
            value={this.props.value}
            placeholder={placeholder}
            onChange={(e) => this.props.onChange(e)}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      routingNumber: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            maskPlaceholder={null}
            mask='999999999'
            onChange={(e) => this.props.onChange(e)}
            onKeyUp={(e) => this.props.onKeyUp(e)}
            placeholder='Enter routing number'
            value={this.props.value}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      amount: () => {
        return (
          <Amount className='amount-input-wrapper'>
            <AmountPrefix>$</AmountPrefix>
            <InputMask
              data-cy={this.props.dataCyTag}
              data-testid={this.props.testId || null}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              ref={this.props.customInputRef}
              placeholder={placeholder}
              onChange={this.handleAmountOnchange}
              style={styleOrUnstyled}
              value={this.state.amountValue}
              className={this.props.className}
              disabled={this.props.disabled}
              maxLength={maxLength}
            />
          </Amount>
        )
      },
      percent: () => {
        return (
          <Percentage>
            <UnderlineInput
              data-cy={this.props.dataCyTag}
              data-testid={this.props.testId || null}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              type='text'
              ref={this.props.customInputRef}
              placeholder='00.00'
              onChange={(e) => this.props.onChange(e)}
              style={{ ...styleOrUnstyled, width: 'auto' }}
              value={this.props.value}
              className={this.props.className}
              disabled={this.props.disabled}
              maxLength={maxLength}
              size={this.props.value.length || 5}
            />
            <AmountPrefix>%</AmountPrefix>
          </Percentage>
        )
      },
      cardNumber: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            placeholder={placeholder}
            mask={this.state.cardNumberMask}
            onChange={(e) => handleCardOnchange(e)}
            value={this.props.value}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      zipCode: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            placeholder={placeholder}
            mask='99999'
            // TODO(Wilcox): the `onChange` handler should always callback with
            // either `value` or `event`, but not arbitrarily one or the other
            onChange={(e) => this.props.onChange(e.target.value)}
            value={this.props.value}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      expDate: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            placeholder={placeholder}
            mask='99/99'
            onChange={(e) => this.props.onChange(e.target.value)}
            value={this.props.value}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
          />
        )
      },
      cvc: () => {
        return (
          <InputMask
            data-cy={this.props.dataCyTag}
            data-testid={this.props.testId || null}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            placeholder={placeholder}
            mask={this.state.cvcMask}
            onChange={(e) => handleCardOnchange(e)}
            value={this.props.value}
            style={styleOrUnstyled}
            disabled={this.props.disabled}
            maxLength={maxLength}
            maskPlaceholder={null}
          />
        )
      },
    }
    return (inputTypes[type] || inputTypes['text'])()
  }

  render() {
    const rendered = this._getInputType(this.props.inputType)
    return rendered
  }
}

MaskedInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  customInputRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  customRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  customStyle: PropTypes.object,
  // Deprecated. We're going to move away from using data-cy on
  // our elements and instead use a more generic data-testid, where
  // the component prop is testId and, as necessary, will sometimes
  // append it with hyphenated suffixes for additional description
  // to reuse testId on multiple elements.
  dataCyTag: PropTypes.string,
  // Use this instead of dataCyTag moving forward. In this component,
  // testId gets set on the input element directly, using the data-testid
  // attribute, without adding any suffixes.
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  inputType: PropTypes.oneOf([
    'tel',
    'bankCodes',
    'sixDigitVerificationCodes',
    'password',
    'text',
    'amount',
    'cardNumber',
    'zipCode',
    'expDate',
    'cvc',
    'routingNumber',
    'percent',
    'email',
  ]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  useValueForAmount: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.number,
  withEllipsis: PropTypes.bool,
  withBottomBorder: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxLength: PropTypes.number,
  unstyled: PropTypes.bool,
}

export default MaskedInput
