import {
  PeachOverrideSelect,
  PeachOverrideText,
  PeachOverrideToggle,
} from 'core/components/PeachOverrides/lib/Override'

const enableCustomEndpoint: PeachOverrideToggle = {
  id: 'enableCustomEndpoint',
  requiresAuth: true,
  requiresReload: true,
  section: 'apiEndpoint',
  type: 'toggle',
  value: false,
  resetsOnChange: ['customEndpoint'],
}

const customEndpoint: PeachOverrideText = {
  id: 'customEndpoint',
  requires: {
    id: 'enableCustomEndpoint',
    value: true,
  },
  requiresAuth: true,
  requiresReload: true,
  section: 'apiEndpoint',
  type: 'text',
  value: '',
  placeholder: 'https://devapi.peach.finance/api',
}

const appTypeMode: PeachOverrideSelect = {
  id: 'appTypeMode',
  section: 'appTypeAndCompany',
  type: 'select',
  value: 'default',
  options: ['default', 'manual', 'domain'],
  resetsOnChange: ['appDomainType', 'companyId', 'domain'],
}

const appDomainType: PeachOverrideSelect = {
  id: 'appDomainType',
  requires: {
    id: 'appTypeMode',
    value: 'manual',
  },
  section: 'appTypeAndCompany',
  type: 'select',
  value: 'default',
  options: ['default', 'borrower', 'agent', 'admin'],
}

const companyId: PeachOverrideText = {
  id: 'companyId',
  requires: {
    id: 'appTypeMode',
    value: 'manual',
  },
  section: 'appTypeAndCompany',
  type: 'text',
  value: '',
  placeholder: 'CP-L9BN-5J52',
}

const domain: PeachOverrideText = {
  id: 'domain',
  requires: {
    id: 'appTypeMode',
    value: 'domain',
  },
  requiresReload: true,
  section: 'appTypeAndCompany',
  type: 'text',
  value: '',
  placeholder: 'service.globex.capital',
}

const userType: PeachOverrideSelect = {
  id: 'userType',
  section: 'misc',
  type: 'select',
  value: 'default',
  options: ['default', 'borrower', 'agent'],
}

const showCaseId: PeachOverrideToggle = {
  id: 'showCaseId',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const plaidBalanceCheck: PeachOverrideToggle = {
  id: 'plaidBalanceCheck',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const plaidVerification: PeachOverrideToggle = {
  id: 'plaidVerification',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const microdepositVerification: PeachOverrideToggle = {
  id: 'microdepositVerification',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const contactUsPage: PeachOverrideToggle = {
  id: 'contactUsPage',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const forceUnknownTaskTypes: PeachOverrideToggle = {
  id: 'forceUnknownTaskTypes',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const forceUnhandledException: PeachOverrideToggle = {
  id: 'forceUnhandledException',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const forceCarrierViolation: PeachOverrideToggle = {
  id: 'forceCarrierViolation',
  section: 'misc',
  type: 'toggle',
  value: false,
}

const overrideOptions = {
  enableCustomEndpoint,
  customEndpoint,
  appTypeMode,
  appDomainType,
  companyId,
  domain,
  userType,
  showCaseId,
  plaidBalanceCheck,
  plaidVerification,
  microdepositVerification,
  contactUsPage,
  forceUnknownTaskTypes,
  forceUnhandledException,
  forceCarrierViolation,
}

export default overrideOptions
