import _ from 'lodash'

const parseError = (error: unknown, fallback: string = 'Unknown error') =>
  error === undefined || error === null || (_.isEmpty(error) && !(error instanceof Error)) ?
    undefined
  : _.capitalize(
      typeof error === 'string' ? error
      : typeof error !== 'object' ? fallback
      : 'detail' in error && typeof error.detail === 'string' ? error.detail
      : 'message' in error && typeof error.message === 'string' ? error.message
      : fallback,
    )

export default parseError
