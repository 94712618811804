import { useQueryClient } from 'react-query'

import { useToast } from 'core/components/Toaster'
import { MutationHook, Operation } from 'core/system/queries'
import { Error as BaseError } from 'core/types/generated/utilities'

import validateBase from './validate'

export type ArrayElement<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer E> ? E : never

export type PartialPick<T, K extends keyof T> = {
  [P in K]?: T[K]
}

export type Maybe<T, Prop extends PropertyKey, F = T> = T extends { [P in Prop]?: infer R } ? R | undefined : F

export type PickRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K]
}

export type OverrideFirstParameter<F, T> =
  F extends (first: infer A, ...args: infer P) => infer R ? (first: Omit<A, keyof T> & T, ...args: P) => R : never

export type PeachError = BaseError & {
  operation?: string
  peachRequestId?: string
}

export const isError = (e: unknown): e is PeachError =>
  !!e && typeof e === 'object' && 'status' in e && typeof e.status === 'number'

export const useErrorHandler = (operation: string) => {
  const toast = useToast()

  return (e: unknown) => {
    if (isError(e)) {
      e.operation = operation
    }

    if (isError(e) && e.status === 504) {
      toast.error(
        `This request has timed out: ${operation}. Please try again at a later time. Consider refreshing the page.`,
      )
    } else {
      toast.error(`Something went wrong with: ${operation}. If the problem persists contact Peach support.`)
    }
  }
}

export const isClientError = (error: unknown) => isError(error) && error.status >= 400 && error.status < 500

export const isNotFoundError = (error: unknown) => isError(error) && error.status === 404

export const isForbiddenError = (error: unknown) => isError(error) && error.status === 403

export const validate = <T extends object | undefined>(key: string, data: T): T => {
  if (import.meta.env.VITE_ENABLE_RESPONSE_VALIDATION) {
    validateBase(key, data)
  }

  return data
}

export const makeInvalidateHook =
  <Op extends Operation>(
    hook: MutationHook<Op>,
    getKeys: (args: Parameters<MutationHook<Op>>[0]) => Array<Array<string | undefined>>,
  ): MutationHook<Op> =>
  (args) => {
    const queryClient = useQueryClient()

    return hook({
      ...args,
      options: {
        ...args.options,
        onSuccess: (data, variables, context) => {
          if (args.options?.onSuccess?.(data, variables, context)) return

          for (const key of getKeys(args)) {
            queryClient.invalidateQueries(key)
          }
        },
      },
    })
  }
