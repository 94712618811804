import React, { FC, PropsWithChildren, createContext, useContext } from 'react'

import { useOverride } from 'core/components/PeachOverrides/overridesStorage'

const UserTypeContext = createContext<'agent' | 'borrower'>('borrower')

type UserTypeProviderProps = PropsWithChildren<{ userType: 'agent' | 'borrower' }>

const UserTypeProvider: FC<UserTypeProviderProps> = ({ userType, children }) => {
  const $userType = useOverride('userType', userType) as 'agent' | 'borrower'

  return <UserTypeContext.Provider value={$userType}>{children}</UserTypeContext.Provider>
}

const useUserType = () => useContext(UserTypeContext)

export { UserTypeProvider, useUserType }
