const mockEmailContent = {
  content: [
    { type: 'text/plain', value: 'Payment confirmation.' },
    {
      type: 'text/html',
      value: '<html><body>Payment confirmation.</body></html>',
    },
  ],
  from: {
    email: 'support@peach-agent.peach-dev.finance',
    name: 'Peach',
  },
  personalizations: [
    {
      to: [{ email: 'bob.borrower@email.com' }],
    },
  ],
}

export default mockEmailContent
