import { FC } from 'react'

import styled from 'styled-components'

import Icon from 'core/components/Icon'

import Link, { LinkProps } from './Link'

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: none;

    > span:first-child {
      text-decoration: underline;
    }
  }
`

const LinkNewTab: FC<LinkProps> = ({ children, ...rest }) => (
  <StyledLink {...rest}>
    <span>{children}</span>
    <Icon margin='0 0 0 8px' name='open_in_new' />
  </StyledLink>
)

export default LinkNewTab
