import { DefaultBodyType, PathParams, ResponseResolver, RestContext, RestRequest } from 'msw'
import { mswEndpoints } from 'test/msw/handlers'
import { mswResponseHandler } from 'test/msw/helpers/mswResponseHandler'

export const resolveGeneric: (
  id: string, // TODO refactor to allowing typing without circular dependency
) => ResponseResolver<RestRequest<never, PathParams<string>>, RestContext, DefaultBodyType> =
  (id) => (_req, res, ctx) =>
    mswResponseHandler({
      ctx,
      res,
      id: id as keyof typeof mswEndpoints,
      data: {},
    })
