import { checkTimeordate } from '../types'
import mtz from '../utils/mtz'

const makeFormat = (context = {}) => {
  const { timezone } = context

  const format = (timeordate, formatString) => {
    checkTimeordate(timeordate)

    return mtz(timeordate, timezone).format(formatString)
  }

  return format
}

export default makeFormat
