import * as TabsPrimitive from '@radix-ui/react-tabs'

// ? https://www.radix-ui.com/docs/primitives/components/tabs

const Tabs = TabsPrimitive.Root
export default Tabs

export const TabsList = TabsPrimitive.TabsList
export const TabButton = TabsPrimitive.TabsTrigger
export const TabPanel = TabsPrimitive.Content
