import compact from 'lodash/compact'

/** This takes an address object as returned by
 * smarty streets and converts it into an address
 * object used in our own Peach API. The smarty streets
 * address object is called `components` in their jargon.
 * For example, an API response to a lookup for matches will
 * have `components` located here:
 * responseData.lookups[0].result[0].components
 */
const smartyStreetToApiAddress = (smartyStreet) => {
  const {
    primaryNumber,
    streetName,
    streetPredirection,
    streetPostdirection,
    streetSuffix,
    secondaryDesignator,
    secondaryNumber,
    cityName,
    state,
    zipCode,
  } = smartyStreet

  const apiAddress = {}

  if (streetName !== 'PO Box') {
    apiAddress.addressLine1 =
      compact([primaryNumber, streetPredirection, streetName, streetPostdirection, streetSuffix]).join(' ') || ''
  } else {
    apiAddress.addressLine1 = ''
  }

  apiAddress.addressLine2 = compact([secondaryDesignator, secondaryNumber]).join(' ') || ''

  apiAddress.city = cityName || ''

  // We're not worrying about county or region for now, since we are currently
  // only taking US addresses from smarty streets and counties aren't required
  // for precision with those addresses.
  apiAddress.countyOrRegion = ''

  apiAddress.state = state || ''

  apiAddress.postalCode = zipCode

  // We're leaving blank for now (assuming US) until we start doing international
  // addresses with smarty streets.
  apiAddress.country = ''

  apiAddress.POBox = streetName === 'PO Box' ? primaryNumber : ''

  return apiAddress
}

export default smartyStreetToApiAddress
