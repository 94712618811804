import { useMemo } from 'react'

import PropTypes from 'prop-types'

import { useCompanyInfo } from 'core/hooks'

const checkShow = (ui, key = '') => {
  const parts = key.split('.')
  let currentObj = ui || {}
  for (const part of parts) {
    const subObject = currentObj[part]
    if (!subObject) return true
    if (subObject === 'hidden') return false
    currentObj = subObject
  }
  return true
}

const useCompanyVisibility = (uiKey) => {
  const { ui } = useCompanyInfo()

  const show = useMemo(() => checkShow(ui, uiKey), [ui, uiKey])

  return show
}

const CompanyVisibility = ({ uiKey, children }) => {
  const show = useCompanyVisibility(uiKey)

  return show ? children : null
}

CompanyVisibility.propTypes = {
  uiKey: PropTypes.string,
  children: PropTypes.node,
}

export { useCompanyVisibility, CompanyVisibility }

export default CompanyVisibility
