import { getOverride } from 'core/components/PeachOverrides/overridesStorage'

const defaultEndpoint = import.meta.env.VITE_API_ENDPOINT

let APIendpoint = defaultEndpoint

if (import.meta.env.VITE_ENV_LABEL !== 'prod') {
  try {
    const customEndpoint = getOverride('customEndpoint')
    if (customEndpoint) {
      APIendpoint = customEndpoint
    }
  } catch (e) {
    APIendpoint = defaultEndpoint
  }
}

const isCustomEndpoint = APIendpoint !== defaultEndpoint

export { isCustomEndpoint, defaultEndpoint }

export default APIendpoint
