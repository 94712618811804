import { FC, PropsWithChildren, useMemo, useState } from 'react'

import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Popover, PopoverContent, PopoverTrigger } from 'core/components/lib/Popover'
import { variables } from 'core/styles'

import Tabs, { TabButton, TabPanel, TabsList } from '../Tabs'

import { PeachOverrideId, peachOverridesOptions } from './config'
import { OverridesFormValues, PeachOverrideVariant } from './lib/Override'
import { OverridesColumn, OverridesGrid } from './lib/styled'
import { setOverrides } from './overridesStorage'
import { getOverride, getOverrides } from './overridesStorage'
import TabApiMocks from './TabApiMocks'
import TabOverrides from './TabOverrides'

type PeachOverridesProps = {}

const PeachOverrides: FC<PropsWithChildren<PeachOverridesProps>> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const overridesForm = useForm<OverridesFormValues>({
    defaultValues: useMemo(
      () => reduceDefaultValues(peachOverridesOptions, true),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    ),
  })

  const onSubmit = (values: OverridesFormValues) => {
    let isAuthRequired = false
    let isReloadRequired = false

    const currValues = getOverrides()
    const newValues = Object.entries(values)
    const valuesToSave = { ...currValues }

    for (let i = 0; i < newValues.length; i++) {
      const [id, value] = newValues[i]
      const { requiresAuth = false, requiresReload = false, value: defaultValue } = peachOverridesOptions[id]

      if (value === defaultValue) {
        if (valuesToSave[id]) {
          // value is in storage and has been reset to default; remove from the object
          if (requiresAuth) isAuthRequired = true
          if (requiresReload) isReloadRequired = true
          delete valuesToSave[id]
        }
      } else {
        if (!valuesToSave[id] || valuesToSave[id] !== value) {
          // add the override value to the object
          if (requiresAuth) isAuthRequired = true
          if (requiresReload) isReloadRequired = true
          valuesToSave[id] = value
        }
      }
    }

    setOverrides(valuesToSave)

    // force reset form `dirty` state
    overridesForm.reset(values)

    if (isAuthRequired) {
      localStorage.removeItem('secretToken')
    }
    if (isReloadRequired) {
      window.location.reload()
    }

    setIsMenuOpen(false)
  }

  const onReset = () => {
    const defaultValues = reduceDefaultValues(peachOverridesOptions, false)
    overridesForm.reset(defaultValues)
    onSubmit(defaultValues as OverridesFormValues)
  }

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      const defaultValues = reduceDefaultValues(peachOverridesOptions, true)
      overridesForm.reset(defaultValues)
    }
    setIsMenuOpen(isOpen)
  }

  return (
    <Popover open={isMenuOpen} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>

      <StyledPopoverContent align='end' sideOffset={6}>
        <FormProvider {...overridesForm}>
          <StyledForm onSubmit={overridesForm.handleSubmit(onSubmit)}>
            <StyledTabs>
              <StyledTabsList>
                <StyledTabButton value='overrides'>Overrides</StyledTabButton>
                <StyledTabButton value='apiMocks'>API Mocks</StyledTabButton>
              </StyledTabsList>

              <StyledTabPanel value='overrides'>
                <TabOverrides />
              </StyledTabPanel>

              <StyledTabPanel value='apiMocks'>
                <TabApiMocks />
              </StyledTabPanel>

              <StyledButtonWrapper>
                <OverridesColumn>
                  <StyledButton type='submit' disabled={!overridesForm.formState.isDirty}>
                    Save
                  </StyledButton>
                </OverridesColumn>
                <OverridesColumn>
                  <StyledButton type='button' onClick={onReset}>
                    Reset
                  </StyledButton>
                </OverridesColumn>
              </StyledButtonWrapper>
            </StyledTabs>
          </StyledForm>
        </FormProvider>
      </StyledPopoverContent>
    </Popover>
  )
}

export default PeachOverrides

const reduceDefaultValues = (
  overrides: typeof peachOverridesOptions,
  useStoredValue: boolean,
): Record<string, PeachOverrideVariant['value']> => {
  return Object.values(overrides).reduce((defaultValues, override) => {
    if (override) {
      return {
        ...defaultValues,
        [override.id]: useStoredValue ? getOverride(override.id as PeachOverrideId) || override.value : override.value,
      }
    }
    return defaultValues
  }, {})
}

const StyledPopoverContent = styled(PopoverContent)`
  box-sizing: border-box;
  width: 502px;
  height: 600px;
  padding: 0;
  overflow: hidden;
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
`

const StyledForm = styled.form`
  height: inherit;
`

const StyledTabs = styled(Tabs)`
  display: grid;
  grid-template: 'tabs' max-content 'panel' auto 'buttons' max-content / 1fr;
  height: inherit;
`

const StyledTabsList = styled(TabsList)`
  box-sizing: border-box;
  grid-area: tabs;
  align-self: flex-start;
  border-bottom: 1px solid #ccc;
`

const StyledTabPanel = styled(TabPanel)`
  box-sizing: border-box;
  grid-area: panel;
  height: 100%;
  padding: 8px 0;
  overflow-y: auto;
`

const StyledButtonWrapper = styled(OverridesGrid)`
  box-sizing: border-box;
  grid-area: buttons;
  align-self: flex-end;
  border-top: 1px solid #ccc;

  > div {
    padding: 0;
  }

  ${OverridesColumn} + ${OverridesColumn} {
    border-left: 1px solid #ccc;
  }
`

const StyledButton = styled.button`
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  font-weight: 500;
  border: none;

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background: #ddd;
    }
  }
`

const StyledTabButton = styled(TabButton)`
  box-sizing: border-box;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  background: none;
  border: none;

  &[data-state='active'] {
    color: ${variables.colorBluePrimary};
    box-shadow:
      inset 0 -1px 0 0 ${variables.colorBluePrimary},
      0 1px 0 0 ${variables.colorBluePrimary};
  }
`
