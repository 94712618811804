import { FC, HTMLAttributes } from 'react'

import styled from 'styled-components'

const VisuallyHiddenBase: FC<HTMLAttributes<HTMLSpanElement>> = (props) => {
  return <span aria-hidden='false' {...props} />
}

const VisuallyHidden = styled(VisuallyHiddenBase)`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
`

export default VisuallyHidden
