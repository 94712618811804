import { UseMutationOptions, UseMutationResult, useMutation } from 'react-query'

export type RequestType = 'put' | 'post' | 'delete' | 'get' | 'patch'

export type Operation = {
  key: Array<string | undefined>
  requestBody: unknown
  response: unknown
  parameters?: {
    [index: string]: string | undefined
  }
  query?: {
    [index: string]: unknown
  }
}

export type RequestFunction<Op extends Operation> = (
  args: Op['parameters'] & {
    body?: Op['requestBody']
    query?: Op['query']
  },
) => Promise<Op['response']>

export type MutationHook<Op extends Operation, Error = unknown, Context = unknown> = (
  args: Op['parameters'] & {
    options?: Omit<UseTypedMutationOptions<Op>, 'onSuccess' | 'onError'> & {
      onSuccess?: (
        data: Op['response'],
        variables: Op['requestBody'],
        context: Context | undefined,
      ) => Promise<boolean | undefined | void> | boolean | undefined | void
      onError?: (
        error: Error,
        variables: Op['requestBody'],
        context: Context | undefined,
      ) => Promise<boolean | undefined | void> | boolean | undefined | void
    }
  } & (Op extends { query: unknown } ? { query: Op['query'] }
    : Op extends { query?: unknown } ? { query?: Op['query'] }
    : { query?: unknown }),
) => UseMutationResult<Op['response'], unknown, Op['requestBody']>

export type UseTypedMutationOptions<Op extends Operation, Error = unknown, Context = unknown> = UseMutationOptions<
  Op['response'],
  Error,
  Op['requestBody'],
  Context
>

export function useTypedMutation<Op extends Operation, Error = unknown, Context = unknown>(
  options: UseTypedMutationOptions<Op, Error, Context>,
) {
  return useMutation(options)
}
