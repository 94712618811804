import { store, retrieve } from 'admin/hooks/useStoredState/storage'

const MINUTE = 60 * 1000
const HOUR = 60 * MINUTE

const key = 'LOGIN_REDIRECT_PATH'

const TIME_LIMIT = 24 * HOUR

const getPath = () => {
  try {
    return window.location.href.substring(window.location.origin.length)
  } catch (e) {
    return window.location.pathname
  }
}

const goTo = (path) => {
  const currentPath = window.location.pathname
  const _path = path || '/'
  if (currentPath !== _path) {
    window.location.href = _path
  }
}

const getLoginRedirectPath = () => {
  const now = Date.now()
  const { path, setAt } = retrieve(key) || {}
  store(key, null)

  if (path && setAt + TIME_LIMIT > now) {
    return path
  }
}

const setLoginRedirectPath = () => {
  const path = getPath()

  if (path) {
    store(key, { path, setAt: Date.now() })
  } else {
    store(key, null)
  }
}

const LOGOUT_KEYS = ['personIdFromToken', 'secretToken', 'userTypeFromToken', 'username', 'storedResourceList']

const clearSavedSession = () => {
  LOGOUT_KEYS.forEach((key) => localStorage.removeItem(key))
}

// public login methods

const redirectToApp = () => {
  goTo(getLoginRedirectPath())
}

const redirectToLogin = () => {
  clearSavedSession()
  setLoginRedirectPath()
  goTo('/login')
}

const signOut = () => {
  clearSavedSession()
  store(key, null)
  goTo('/login')
}

export { redirectToApp, redirectToLogin, signOut }
