import { FC } from 'react'

import { AgentRoutes, BorrowerRoutes } from 'app/routes'
import AdminWrapper from 'app/routes/AdminWrapper'
import SignOutRoute from 'app/routes/SignOutRoute'
import { useLoginInfo } from 'noauth/pages/Login/LoginWrapper'
import { Route, Switch, Redirect } from 'react-router-dom'
import SysAdmin from 'sysAdmin/pages/SysAdmin'

import LoginRedirect from './loginRedirect/LoginRedirect'

type AppRoutesProp = {
  sessionPersonId: string
  companyTheme: string
}

const AppRoutes: FC<AppRoutesProp> = ({ sessionPersonId, companyTheme }) => {
  const { appDomainType, authType } = useLoginInfo()

  return (
    <Switch>
      <Route path='/sign-out'>
        <SignOutRoute />
      </Route>

      <Route path='/sys'>
        <SysAdmin />
      </Route>

      <Route path='/sys-admin'>
        <Redirect to='/sys' />
      </Route>

      <Route
        render={() => {
          switch (appDomainType) {
            case 'agent':
              return <AgentRoutes companyTheme={companyTheme} authType={authType} />

            case 'borrower':
              return (
                <BorrowerRoutes
                  key={sessionPersonId}
                  basePath=''
                  baseUrl=''
                  borrowerId={sessionPersonId} // the user is the borrower
                  userType='borrower'
                  companyTheme={companyTheme}
                  authType={authType}
                />
              )

            case 'admin':
              return <AdminWrapper />

            default:
              return <LoginRedirect />
          }
        }}
      />
    </Switch>
  )
}

export default AppRoutes
