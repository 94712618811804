import { FC, lazy, ReactNode, Suspense } from 'react'

import styled from 'styled-components'

import { Spinner } from 'core/components'

const LazyAgentRoutes = lazy(() => import('app/routes/AgentRoutes'))
const LazyBorrowerRoutes = lazy(() => import('app/routes/BorrowerRoutes'))

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const defaultFallback = (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
)

type RoutesProps = {
  fallback?: ReactNode
  [index: string]: any
}

const AgentRoutes: FC<RoutesProps> = ({ fallback = defaultFallback, ...rest }) => {
  return (
    <Suspense fallback={fallback}>
      <LazyAgentRoutes {...rest} />
    </Suspense>
  )
}

const BorrowerRoutes: FC<RoutesProps> = ({ fallback = defaultFallback, ...rest }) => {
  return (
    <Suspense fallback={fallback}>
      <LazyBorrowerRoutes {...rest} />
    </Suspense>
  )
}

export { AgentRoutes, BorrowerRoutes }
