import { FC } from 'react'

import * as ToastPrimitive from '@radix-ui/react-toast'
import styled, { keyframes } from 'styled-components'

import { useAppDispatch } from 'core/hooks/useAppStore'
import { variables } from 'core/styles'

import Icon from '../Icon'
import { errorBannerCss, warningBannerCss } from '../lib/Banner'
import VisuallyHidden from '../lib/VisuallyHidden'

import { ToastProps, toastRemoved } from './toastsSlice'

const Toast: FC<ToastProps> = ({ id, message, type }) => {
  const dispatch = useAppDispatch()

  const onOpenChange = (open: boolean) => {
    if (!open) dispatch(() => toastRemoved(id))
  }

  return (
    <ToastPrimitive.Root onOpenChange={onOpenChange} asChild>
      <StyledToast $type={type}>
        <ToastPrimitive.Description>{message}</ToastPrimitive.Description>
        <ToastPrimitive.Close asChild>
          <StyledCloseButton>
            <VisuallyHidden>Dismiss</VisuallyHidden>
            <Icon name='clear' fontSize='20px' />
          </StyledCloseButton>
        </ToastPrimitive.Close>
      </StyledToast>
    </ToastPrimitive.Root>
  )
}

export default Toast

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
const slideIn = keyframes`
  from {
    transform: translateX(calc(100% + 16px));
  }
  to {
    transform: translateX(0);
  }
`

const StyledToast = styled.li<{ $type: ToastProps['type'] }>`
  ${(p) => p.$type === 'error' && errorBannerCss};
  ${(p) => p.$type === 'warning' && warningBannerCss};
  display: flex;
  align-items: flex-start;
  width: 36rem;
  padding: 16px;
  color: ${variables.colorBlack100};
  border-radius: 8px;
  box-shadow:
    ${variables.peachyShadowSmall},
    inset 0 0 3px 0
      ${(p) =>
        p.$type === 'error' ? variables.colorRed
        : p.$type === 'warning' ? variables.colorOrange
        : ''};

  & + & {
    margin-top: 12px;
  }

  /* open and close animation */
  &[data-state='open'] {
    animation: ${slideIn} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  &[data-state='closed'] {
    animation: ${fadeOut} 100ms ease-in;
  }

  /* swipe gesture animation */
  &[data-swipe='cancel'] {
    transition: transform 200ms ease-out;
    transform: translateX(0);
  }
`

const StyledCloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
`
