import { FC } from 'react'

import { peachOverridesOptions } from './config'
import { renderOverride } from './lib/Override'
import { OverridesColumn, OverridesGrid, OverridesSectionTitle } from './lib/styled'

const TabOverrides: FC = () => {
  const allOverrides = Object.values(peachOverridesOptions)
  const apiEndpointOverrides = allOverrides.filter((o) => o.section === 'apiEndpoint')
  const appTypeAndCompanyOverrides = allOverrides.filter((o) => o.section === 'appTypeAndCompany')
  const miscOverrides = allOverrides.filter((o) => o.section === 'misc')

  return (
    <OverridesGrid>
      <OverridesColumn>
        <div>
          <OverridesSectionTitle>API Endpoint</OverridesSectionTitle>
          {apiEndpointOverrides.map(renderOverride)}
        </div>
        <div>
          <OverridesSectionTitle>App Type & Company</OverridesSectionTitle>
          {appTypeAndCompanyOverrides.map(renderOverride)}
        </div>
      </OverridesColumn>
      <OverridesColumn>
        <OverridesSectionTitle>Misc. Overrides</OverridesSectionTitle>
        {miscOverrides.map(renderOverride)}
      </OverridesColumn>
    </OverridesGrid>
  )
}

export default TabOverrides
