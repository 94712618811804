import { useMemo } from 'react'

import styled, { css } from 'styled-components'

import Icon from 'core/components/Icon'
import { variables } from 'core/styles'

import truncatePageNumbers from './truncatePageNumbers'

const PageButton = styled.button<{ active?: boolean }>`
  position: relative;
  display: inline-grid;
  align-items: center;
  justify-content: center;
  width: 40px;
  cursor: pointer;
  background: ${variables.colorWhite};
  border: none;

  &:disabled {
    color: ${variables.colorBlack50};
    cursor: default;
  }

  &:not(:disabled) {
    &:hover {
      background: ${variables.colorBlack10};
    }
  }

  ${(p) =>
    p.active &&
    css`
      font-weight: 700;
      color: ${variables.colorBluePrimary};
      background: ${variables.colorBlack10};

      &::after {
        position: absolute;
        inset: -1px;
        z-index: 1;
        content: '';
        border: 1px solid ${variables.colorBluePrimary};
      }
    `}
`

export const PageNav = styled.nav`
  display: inline-flex;
  height: 40px;
  margin-top: 16px;
  border: 1px solid ${variables.colorBlack20};
  border-radius: 8px;

  ${PageButton} {
    &:first-of-type {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-of-type {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    & + ${PageButton} {
      border-left: 1px solid ${variables.colorBlack20};
    }
  }
`

export default function Pagination({
  currentPage,
  maxPagesVisible,
  numPagesOnEnds,
  totalPages,
  onSelectPage,
}: {
  currentPage: number
  maxPagesVisible: number
  numPagesOnEnds: number
  totalPages: number
  onSelectPage: (page: number) => void
}) {
  const truncatedPages = useMemo(
    () =>
      truncatePageNumbers({
        currentPage,
        totalPages,
        maxPagesVisible,
        numPagesOnEnds,
      }),
    [currentPage, maxPagesVisible, numPagesOnEnds, totalPages],
  )

  if (truncatedPages.length < 2) return null
  return (
    <PageNav>
      <PageButton onClick={() => onSelectPage(currentPage - 1)} disabled={currentPage === 0}>
        <Icon name='arrow_back_ios' />
      </PageButton>
      {truncatedPages.map((page, index) => (
        <PageButton
          key={`page-${index}`}
          active={page === currentPage}
          disabled={page === -1}
          onClick={() => (page === -1 ? null : onSelectPage(page))}
        >
          {page === -1 ? '...' : page + 1}
        </PageButton>
      ))}
      <PageButton onClick={() => onSelectPage(currentPage + 1)} disabled={currentPage === totalPages - 1}>
        <Icon name='arrow_forward_ios' />
      </PageButton>
    </PageNav>
  )
}
