const mockBaseInteraction = {
  id: null,
  channel: null,
  contactId: 'CT-1',
  content: {},
  createdAt: '2019-08-24T14:15:22Z',
  createdBy: {
    id: 'UR-1',
    name: 'Alice Agent',
  },
  deletedAt: '2019-08-24T14:15:22Z',
  direction: 'outbound',
  endedAt: '2019-08-24T14:15:22Z',
  externalId: null,
  isAutodialed: false,
  loanId: 'LN-1',
  loanIds: ['LN-1'],
  object: 'interaction',
  personId: 'BO-1',
  previousInteractionId: null,
  recordingDuration: 30,
  recordingStartedAt: '2019-08-24T14:17:22Z',
  scheduledAtFrom: '2019-08-24T14:15:22Z',
  scheduledAtTo: '2019-08-24T14:15:22Z',
  sensitive: false,
  startedAt: '2019-08-24T14:15:22Z',
  status: 'attempted',
  statusDetails: null,
  statusDetailsUpdatedAt: '2019-08-24T14:15:22Z',
  statusUpdatedAt: '2019-08-24T14:15:22Z',
  subject: 'paymentSuccessful',
  templateVersionId: 'string',
  theme: 'opsServicing',
  updatedAt: '2019-08-24T14:15:22Z',
}

export default mockBaseInteraction
