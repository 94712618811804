import { FC } from 'react'

import * as ToastPrimitive from '@radix-ui/react-toast'
import styled from 'styled-components'

import { useAppSelector } from 'core/hooks/useAppStore'

import Toast from './Toast'
import { selectAllToasts } from './toastsSlice'

// ? https://www.radix-ui.com/docs/primitives/components/toast

// The radix primitives handle most of what is needed from a toast system:
// * toasts are rendered to a portal and can be dismissed manually or automatically
// * gestures are there if we want to use them
// * accessibility is handled, etc

// However - radix toasts are meant to be used declaratively, making it cumbersome
// to implement simple error handling (ie. launch a toast in an onError callback)

// The set of components in this directory builds on top of what radix provides:
// * toast state is managed with redux; updating when toasts are added and removed
// * <Toaster /> can be placed anywhere to render toasts, with isolated re-renders
// * useToast() provides convenient toast creation anywhere

const Toaster: FC = () => {
  const toasts = useAppSelector((state) => selectAllToasts(state))

  return (
    <ToastPrimitive.Provider duration={7000}>
      {toasts.map((toast) => (
        <Toast key={toast.id} {...toast} />
      ))}

      <ToastPrimitive.Viewport asChild>
        <StyledToaster />
      </ToastPrimitive.Viewport>
    </ToastPrimitive.Provider>
  )
}

export default Toaster

const StyledToaster = styled.ol`
  position: fixed;
  right: 24px;
  bottom: 24px;
  z-index: 20;
  padding: 0;
  margin: 0;
  list-style: none;
`
