import { DefaultBodyType, MockedRequest, rest, RestHandler } from 'msw'
import { resolveGeneric } from 'test/msw/resolvers/resolveGeneric'

import { PeachOverrideId } from 'core/components/PeachOverrides/config'
import { getOverride } from 'core/components/PeachOverrides/overridesStorage'

import { resolveGetCases } from './resolvers/resolveCases'
import { resolveGetInteraction, resolveGetInteractions } from './resolvers/resolveInteractions'
import {
  resolvePostCaseNote,
  resolvePostGlobalNote,
  resolvePostLocalNote,
  resolvePutCaseNote,
  resolvePutGlobalNote,
  resolvePutLocalNote,
} from './resolvers/resolveNotes'
import { resolveGetUncompletedTasks, resolvePutTasktypeAvailability } from './resolvers/resolveTwilio'
import { resolveGetZendeskConfig } from './resolvers/resolveZendesk'

const isTestRunner = import.meta.env.VITE_ENV_LABEL === 'test'

export const mswEndpoints = {
  getCases: rest.get('*/people/:personId/cases', resolveGetCases),
  getInteraction: rest.get('*/interactions/:interactionId', resolveGetInteraction),
  getInteractions: rest.get('*/people/:personId/interactions', resolveGetInteractions),
  putTasktypeAvailability: rest.put('*/twilio/tasktype-availability', resolvePutTasktypeAvailability),
  postCaseNote: rest.post('*/people/:personId/cases/:caseId/notes', resolvePostCaseNote),
  putCaseNote: rest.put('*/people/:personId/cases/:caseId/notes/:id', resolvePutCaseNote),
  postSupercaseLocalNote: rest.post('*/supercases/:caseId/notes', resolvePostLocalNote),
  putSupercaseLocalNote: rest.put('*/supercases/:caseId/notes/:id', resolvePutLocalNote),
  postSupercaseGlobalNote: rest.post('*/supercases/:supercaseId/global-notes', resolvePostGlobalNote),
  putSupercaseGlobalNote: rest.put('*/supercases/:supercaseId/global-notes/:id', resolvePutGlobalNote),
  getUncompletedTasks: rest.get('*/companies/:companyId/twilio-unterminated-tasks', resolveGetUncompletedTasks),
  getZendeskConfig: rest.get('*/companies/:companyId/config', resolveGetZendeskConfig),
  getActiveEscalations: rest.get('*/active-escalations', resolveGeneric('getActiveEscalations')),
  postCaseEscalation: rest.post('*/people/:personId/cases/:caseId/escalations', resolveGeneric('postCaseEscalation')),
  postCaseSnooze: rest.post('*/people/:personId/cases/:caseId/snooze', resolveGeneric('postCaseSnooze')),
  postCaseDni: rest.post('*/people/:personId/cases/:caseId/do-not-interacts', resolveGeneric('postCaseDni')),
  putCaseDni: rest.put('*/people/:personId/cases/:caseId/do-not-interacts/:dniId', resolveGeneric('putCaseDni')),
  deleteCaseDni: rest.delete(
    '*/people/:personId/cases/:caseId/do-not-interacts/:dniId',
    resolveGeneric('deleteCaseDni'),
  ),
  postSupercaseDni: rest.post(
    '*/supercases/:supercaseId/bulk-create-do-not-interact',
    resolveGeneric('postSupercaseDni'),
  ),
  deleteSupercaseDni: rest.post(
    '*/supercases/:supercaseId/bulk-delete-do-not-interact',
    resolveGeneric('deleteSupercaseDni'),
  ),
  getZendeskToken: rest.get('*/zendesk/sso-token', resolveGeneric('getZendeskToken')),
  getBorrowerSearch: rest.get('*/people/search', resolveGeneric('getBorrowerSearch')),
  internalEmployeesGet: rest.get('*/employees', resolveGeneric('internalEmployeesGet')),
  internalTeamsGet: rest.get('*/teams', resolveGeneric('internalTeamsGet')),
}

export const handlers: RestHandler<MockedRequest<DefaultBodyType>>[] = Object.entries(mswEndpoints).reduce(
  (enabledHandlers, [id, mockFn]) => {
    if (isTestRunner || getOverride(id as PeachOverrideId)) {
      return [...enabledHandlers, mockFn]
    }
    return enabledHandlers
  },
  [] as RestHandler<MockedRequest<DefaultBodyType>>[],
)
