import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query'
import * as R from 'remeda'

import { useCompanyId } from 'core/SystemProvider/CompanyIdProvider'
import { NewPaginator, SingletonResponse } from 'core/types'

import defaultQueryConfig from './defaultQueryConfig'
import getIsEnabled from './getIsEnabled'
import { Operation } from './useTypedMutation'

export type GetOptionsFunction<Op extends Operation, Options extends object = object> = (
  args: Op['parameters'] & { query?: Op['query']; options?: Options },
) => Options & {
  queryKey: QueryKey<Op>
  queryFn: () => Promise<Op['response']>
  onError: (e: unknown) => void
  useErrorBoundary: boolean
}

export type PaginatedOperation = Operation & {
  response: NewPaginator & { data?: Array<unknown> }
}

export type SingletonOperation = Operation & {
  response: SingletonResponse & { data?: unknown }
}

export type QueryKey<Op extends Operation> = [...Op['key'], {}]

export type UseTypedQueryOptions<Op extends Operation, Error = unknown> = UseQueryOptions<
  Op['response'],
  Error,
  Op['response'],
  QueryKey<Op>
> & { forceEnabled?: boolean }

export type QueryHookArguments<Op extends Operation> = Op['parameters'] & {
  query?: Op['query']
  options?: UseTypedQueryOptions<Op>
}

export function useTypedQuery<Op extends PaginatedOperation, Error = unknown>(
  options: UseTypedQueryOptions<Op, Error>,
): UseQueryResult<Op['response']['data']> & { pagination: NewPaginator }

export function useTypedQuery<Op extends SingletonOperation, Error = unknown>(
  options: UseTypedQueryOptions<Op, Error>,
): UseQueryResult<Op['response']['data']>

export function useTypedQuery<Op extends Operation, Error = unknown>(
  options: UseTypedQueryOptions<Op, Error>,
): UseQueryResult<Op['response']>

export function useTypedQuery<Op extends Operation, Error = unknown>(options: UseTypedQueryOptions<Op, Error>) {
  const companyId = useCompanyId()

  const result = useQuery({
    ...defaultQueryConfig,
    ...options,
    enabled: !!companyId && getIsEnabled(options),
  })

  return result.data && typeof result.data === 'object' && 'data' in result.data ?
      {
        ...result,
        data: result.data.data,
        pagination: R.pick(result.data as any, ['total', 'count', 'nextUrl', 'previousUrl']),
      }
    : result
}
