import styled from 'styled-components'

import { variables } from 'core/styles'

export type FallbackProps = {
  className?: string
}

export const ErrorBox = styled.div`
  position: relative;
  padding: 8px 16px;
  color: ${variables.colorBlack100};

  & + & {
    margin-top: 12px;
  }
`
export const ErrorTitle = styled.h1`
  font-size: 18px;
  font-weight: 500;
`
export const ErrorMessage = styled.p`
  margin-top: 0;
  font-size: 15px;
`

export const WarningBox = styled(ErrorBox)`
  background-color: ${variables.colorOrangeLighten};
`
