import { ChangeEvent, ChangeEventHandler, FC, PropsWithChildren, ReactNode, Ref } from 'react'

import { Amount, CustomMaskedInput, Container, SubLabel, RadioInput, RadioLabel } from './RadioStyles'

type RadioProps = {
  amount?: string | number
  bare?: boolean
  checked?: boolean
  customField?: boolean
  customFieldPlaceholder?: string | number
  customInputRef?: Ref<HTMLInputElement>
  disabled?: boolean
  getCustomAmount?: (value: string) => void
  id?: string
  label?: string
  name?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  subLabel?: ReactNode
  value?: string | number
  width?: string
}

const Radio: FC<PropsWithChildren<RadioProps>> = ({
  amount,
  bare,
  checked,
  children,
  customField,
  customFieldPlaceholder,
  customInputRef,
  disabled,
  getCustomAmount,
  id,
  label,
  name,
  onChange,
  subLabel,
  value,
  width,
}) => {
  return (
    <Container data-cy={`radio-${value}`} className='radio'>
      <RadioInput
        type='radio'
        name={name || label}
        id={id || label}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {bare ?
        <RadioLabel data-testid='label-bare' width={width} htmlFor={id || label}>
          {'\u00A0'}
        </RadioLabel>
      : children ?
        <RadioLabel data-testid='label-with-children' width={width} htmlFor={id || label}>
          {children}
        </RadioLabel>
      : !customField ?
        <>
          <RadioLabel data-testid='label-no-custom-field' htmlFor={id || label} width={width}>
            {label}
          </RadioLabel>
          {subLabel && <SubLabel>{subLabel}</SubLabel>}
          {amount && <Amount>{amount}</Amount>}
        </>
      : <>
          <RadioLabel htmlFor={id || label} width={width}>
            {label}
          </RadioLabel>
          <CustomMaskedInput
            customInputRef={customInputRef}
            inputType='amount'
            onChange={(e: ChangeEvent<HTMLInputElement>) => getCustomAmount?.(e.target.value)}
            placeholder={customFieldPlaceholder}
          />
        </>
      }
    </Container>
  )
}

export default Radio
