import { FC } from 'react'

import styled, { css } from 'styled-components'

import APIendpoint, { isCustomEndpoint } from 'core/actions/helpers/APIendpoint'
import { variables } from 'core/styles'

import { PeachOverrides } from '../PeachOverrides'
import { overridesEnabled } from '../PeachOverrides/overridesStorage'

const envLabelHide = import.meta.env.VITE_ENV_LABEL_HIDE === 'true'
const envLabel = isCustomEndpoint ? 'unknown' : import.meta.env.VITE_ENV_LABEL

const COLORS = {
  local: {
    backgroundColor: variables.colorGreenLighten,
    color: variables.colorGreen,
  },
  dev: {
    backgroundColor: variables.colorBlueLighten,
    color: variables.colorBluePrimary,
  },
  sandbox: {
    backgroundColor: variables.colorOrangeLighten,
    color: variables.colorOrange,
  },
  unknown: {
    backgroundColor: variables.colorRedLighten,
    color: variables.colorRed,
  },
}

const { color, backgroundColor } = COLORS[envLabel as keyof typeof COLORS] || COLORS['unknown']

const Badge: FC = () => {
  if (envLabelHide) return null

  const title = isCustomEndpoint ? `Custom API Base: ${APIendpoint}` : `API Base: ${APIendpoint}`
  const label = envLabel && !isCustomEndpoint ? envLabel : title

  if (!overridesEnabled) {
    return (
      <StyledBadge title={title}>
        <span>{label}</span>
      </StyledBadge>
    )
  }

  return (
    <PeachOverrides>
      <StyledBadgeButton title={title}>
        <span>{label}</span>
      </StyledBadgeButton>
    </PeachOverrides>
  )
}

export default Badge

const badgeStyles = css`
  position: absolute;
  right: 6px;
  bottom: 6px;
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1;
  color: ${color};
  white-space: nowrap;
  cursor: default;
  background-color: ${backgroundColor};
  border: 1px solid ${color};
  border-radius: 3px;
  opacity: 0.8;

  span {
    line-height: 1;
  }
`

const StyledBadge = styled.span`
  ${badgeStyles};
`

const StyledBadgeButton = styled.button`
  ${badgeStyles};
  cursor: pointer;
`
