import { FC, PropsWithChildren } from 'react'

import _ from 'lodash'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import Button from 'core/components/lib/Button'
import { PendingEventHandler } from 'core/components/lib/Button/Button'
import ConfirmDialog from 'core/components/lib/ConfirmDialog'
import Link from 'core/components/lib/Link'
import PermissionDeniedDialog from 'core/components/lib/PermissionDeniedDialog/PermissionDeniedDialog'
import Spinner from 'core/components/lib/Spinner'
import { useHasPermission, useBorrowerUrl } from 'core/hooks'
import { Permission } from 'core/types'

import { useFormDisabled } from 'loan/components/actionCards/shared/DisabledContext'

import CardSection from './CardSection'

type BackButtonProps = PropsWithChildren<{
  color?: string
}>

export const BackButton: FC<BackButtonProps> = ({ color, children = 'Back' }) => {
  const { loanId } = useParams<{ loanId: string }>()
  const url = useBorrowerUrl(loanId ? `/loans/${loanId}/activity` : '/loans')

  return (
    <StyledLink color={color} to={url}>
      {children}
    </StyledLink>
  )
}

export type SubmitButtonProps = {
  disabled: boolean
  loading: boolean
  success?: boolean
  submitText: string
  onSubmit: PendingEventHandler
  permissions?: Permission | Permission[]
  skipConfirm?: boolean
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  disabled,
  loading,
  success = false,
  submitText,
  onSubmit,
  permissions,
  skipConfirm,
}) => {
  const isDisabled = useFormDisabled(disabled)

  const hasPermission = useHasPermission(permissions)

  const common = {
    readOnly: isDisabled || loading,
    secondary: true,
    children: loading ? 'Processing' : submitText,
  }

  return (
    <>
      {!hasPermission ?
        <PermissionDeniedDialog title={submitText}>
          <Button {...common} />
        </PermissionDeniedDialog>
      : success ?
        <BackButton>Done</BackButton>
      : skipConfirm ?
        <Button {...common} onClick={onSubmit} />
      : <ConfirmDialog
          title={submitText}
          content={`Are you sure you want to ${submitText.toLowerCase()}?`}
          onConfirm={onSubmit}
        >
          <Button {...common} />
        </ConfirmDialog>
      }
      {loading && <Spinner />}
    </>
  )
}

type CardSectionSubmitProps = {
  submitText?: string
  cancelText?: string
  onSubmit?: PendingEventHandler
  children?: React.ReactNode
  disabled?: boolean
  loading?: boolean
  success?: boolean
  skipConfirm?: boolean
}

const CardSectionSubmit: FC<CardSectionSubmitProps> = ({
  submitText,
  cancelText,
  onSubmit,
  children,
  disabled = false,
  loading = false,
  success = false,
  skipConfirm,
}) => (
  <CardSection>
    <SubmitButtonsContainer>
      <BackButton color='black'>{cancelText}</BackButton>

      {children ?? (
        <SubmitButton
          disabled={disabled}
          loading={loading}
          onSubmit={onSubmit ?? _.noop}
          submitText={submitText || 'Submit'}
          skipConfirm={skipConfirm}
          success={success}
        />
      )}
    </SubmitButtonsContainer>
  </CardSection>
)

export default CardSectionSubmit

export const SubmitButtonsContainer = styled.div<{ margin?: string }>`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-end;
  margin: ${(p) => p.margin ?? '16px 0 0 0'};
`

const StyledLink = styled(Link)<{ color?: string }>`
  && {
    color: ${(p) => p.color};

    &:hover {
      text-decoration: none;
    }
  }
`
