/* tslint:disable */
/* eslint-disable */

/**
 * This file is generated by `pnpm codegen` from the OpenAPI spec, DO NOT EDIT.
 */

import { peachApi } from 'core/api'
import { RequestFunction } from 'core/system/queries'
import { validate } from 'core/types/utils'
import * as Types from './types'

export const peachy: RequestFunction<Types.Peachy> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/peachy', await peachApi.get({ url: `/peachy`, ...args }))

export const pit: RequestFunction<Types.Pit> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/pit', await peachApi.get({ url: `/peachy/pit`, ...args }))

export const internalGetEvents: RequestFunction<Types.InternalGetEvents> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalGetEvents',
    await peachApi.get({ url: `/companies/${companyId}/events`, ...args }),
  )

export const internalCreateEvent: RequestFunction<Types.InternalCreateEvent> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalCreateEvent',
    await peachApi.post({ url: `/companies/${companyId}/events`, ...args }),
  )

export const internalCreateEventsBulk: RequestFunction<Types.InternalCreateEventsBulk> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCreateEventsBulk',
    await peachApi.post({ url: `/companies/${companyId}/events/bulk`, ...args }),
  )

export const internalUpdateEvent: RequestFunction<Types.InternalUpdateEvent> = async ({
  companyId,
  personId,
  loanId,
  eventId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUpdateEvent',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/events/${eventId}`,
      ...args,
    }),
  )

export const internalLoansGetByCompany: RequestFunction<Types.InternalLoansGetByCompany> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoansGetByCompany',
    await peachApi.get({ url: `/companies/${companyId}/loans`, ...args }),
  )

export const internalLoansGetByCompanyAbridged: RequestFunction<Types.InternalLoansGetByCompanyAbridged> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoansGetByCompanyAbridged',
    await peachApi.get({ url: `/companies/${companyId}/loans-abridged`, ...args }),
  )

export const internalLoansGetCount: RequestFunction<Types.InternalLoansGetCount> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoansGetCount',
    await peachApi.get({ url: `/companies/${companyId}/loan-count`, ...args }),
  )

export const companyLoanConfigurationsGetAll: RequestFunction<Types.CompanyLoanConfigurationsGetAll> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyLoanConfigurationsGetAll',
    await peachApi.get({ url: `/companies/${companyId}/loan-types`, ...args }),
  )

export const companyLoanConfigurationsAdd: RequestFunction<Types.CompanyLoanConfigurationsAdd> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyLoanConfigurationsAdd',
    await peachApi.post({ url: `/companies/${companyId}/loan-types`, ...args }),
  )

export const companyLoanConfigurationsGet: RequestFunction<Types.CompanyLoanConfigurationsGet> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyLoanConfigurationsGet',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}`, ...args }),
  )

export const companyLoanConfigurationsDelete: RequestFunction<Types.CompanyLoanConfigurationsDelete> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyLoanConfigurationsDelete',
    await peachApi.delete({ url: `/companies/${companyId}/loan-types/${loanTypeId}`, ...args }),
  )

export const loansGetByLoanType: RequestFunction<Types.LoansGetByLoanType> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loansGetByLoanType',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}/loans`, ...args }),
  )

export const internalBorrowerPaymentInstrumentGetAll: RequestFunction<
  Types.InternalBorrowerPaymentInstrumentGetAll
> = async ({ companyId, personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalBorrowerPaymentInstrumentGetAll',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/payment-instruments`, ...args }),
  )

export const internalBorrowerPaymentInstrumentCreate: RequestFunction<
  Types.InternalBorrowerPaymentInstrumentCreate
> = async ({ companyId, personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalBorrowerPaymentInstrumentCreate',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/payment-instruments`, ...args }),
  )

export const internalBorrowerPaymentInstrumentGet: RequestFunction<
  Types.InternalBorrowerPaymentInstrumentGet
> = async ({ companyId, personId, paymentInstrumentId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalBorrowerPaymentInstrumentGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}`,
      ...args,
    }),
  )

export const internalBorrowerPaymentInstrumentUpdate: RequestFunction<
  Types.InternalBorrowerPaymentInstrumentUpdate
> = async ({ companyId, personId, paymentInstrumentId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalBorrowerPaymentInstrumentUpdate',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}`,
      ...args,
    }),
  )

export const internalBorrowerPaymentInstrumentDelete: RequestFunction<
  Types.InternalBorrowerPaymentInstrumentDelete
> = async ({ companyId, personId, paymentInstrumentId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalBorrowerPaymentInstrumentDelete',
    await peachApi.delete({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}`,
      ...args,
    }),
  )

export const internalAccountLinkPost: RequestFunction<Types.InternalAccountLinkPost> = async ({
  companyId,
  personId,
  paymentInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalAccountLinkPost',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}/account-link`,
      ...args,
    }),
  )

export const internalAccountLinkDelete: RequestFunction<Types.InternalAccountLinkDelete> = async ({
  companyId,
  personId,
  paymentInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalAccountLinkDelete',
    await peachApi.delete({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}/account-link`,
      ...args,
    }),
  )

export const internalBalancesGet: RequestFunction<Types.InternalBalancesGet> = async ({
  companyId,
  personId,
  paymentInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalBalancesGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}/balance`,
      ...args,
    }),
  )

export const internalBalancesPost: RequestFunction<Types.InternalBalancesPost> = async ({
  companyId,
  personId,
  paymentInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalBalancesPost',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/payment-instruments/${paymentInstrumentId}/balance`,
      ...args,
    }),
  )

export const internalLoansGet: RequestFunction<Types.InternalLoansGet> = async ({ companyId, personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoansGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans`, ...args }),
  )

export const internalLoansAdd: RequestFunction<Types.InternalLoansAdd> = async ({ companyId, personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoansAdd',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans`, ...args }),
  )

export const internalLoanGet: RequestFunction<Types.InternalLoanGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}`, ...args }),
  )

export const internalLoanUpdate: RequestFunction<Types.InternalLoanUpdate> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanUpdate',
    await peachApi.put({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}`, ...args }),
  )

export const internalSyncPayoffStatus: RequestFunction<Types.InternalSyncPayoffStatus> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalSyncPayoffStatus',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/sync-payoff-status`,
      ...args,
    }),
  )

export const loggingDebugger: RequestFunction<Types.LoggingDebugger> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loggingDebugger',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/logging-debugger`,
      ...args,
    }),
  )

export const ledgerDebugger: RequestFunction<Types.LedgerDebugger> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/ledgerDebugger',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/ledger-debugger`, ...args }),
  )

export const internalLoanRatesDelete: RequestFunction<Types.InternalLoanRatesDelete> = async ({
  companyId,
  personId,
  loanId,
  rateId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRatesDelete',
    await peachApi.delete({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/rates/${rateId}`,
      ...args,
    }),
  )

export const internalForecastInterest: RequestFunction<Types.InternalForecastInterest> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalForecastInterest',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/forecast-interest`,
      ...args,
    }),
  )

export const internalLoanRatesGet: RequestFunction<Types.InternalLoanRatesGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRatesGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/rates`, ...args }),
  )

export const internalLoanRatesAdd: RequestFunction<Types.InternalLoanRatesAdd> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRatesAdd',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/rates`, ...args }),
  )

export const internalGetLoanPeriods: RequestFunction<Types.InternalGetLoanPeriods> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetLoanPeriods',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/periods`, ...args }),
  )

export const internalUpdateLoanPeriod: RequestFunction<Types.InternalUpdateLoanPeriod> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUpdateLoanPeriod',
    await peachApi.put({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/periods`, ...args }),
  )

export const internalCreateNextLoanPeriod: RequestFunction<Types.InternalCreateNextLoanPeriod> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCreateNextLoanPeriod',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/periods`, ...args }),
  )

export const internalLoanExpectedPaymentsGet: RequestFunction<Types.InternalLoanExpectedPaymentsGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanExpectedPaymentsGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/expected-payments`,
      ...args,
    }),
  )

export const internalLoanExpectedPaymentsPost: RequestFunction<Types.InternalLoanExpectedPaymentsPost> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanExpectedPaymentsPost',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/expected-payments`,
      ...args,
    }),
  )

export const internalLoanExpectedPaymentsGetOne: RequestFunction<Types.InternalLoanExpectedPaymentsGetOne> = async ({
  companyId,
  personId,
  loanId,
  expectedPaymentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanExpectedPaymentsGetOne',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/expected-payments/${expectedPaymentId}`,
      ...args,
    }),
  )

export const internalLoanAutopayGet: RequestFunction<Types.InternalLoanAutopayGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay`, ...args }),
  )

export const internalLoanAutopayPut: RequestFunction<Types.InternalLoanAutopayPut> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayPut',
    await peachApi.put({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay`, ...args }),
  )

export const internalLoanAutopayPost: RequestFunction<Types.InternalLoanAutopayPost> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayPost',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay`, ...args }),
  )

export const internalLoanAutopayDelete: RequestFunction<Types.InternalLoanAutopayDelete> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayDelete',
    await peachApi.delete({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay`, ...args }),
  )

export const internalLoanAutopayCancelPayments: RequestFunction<Types.InternalLoanAutopayCancelPayments> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayCancelPayments',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay/cancel-payments`,
      ...args,
    }),
  )

export const internalLoanAutopayReschedulePayments: RequestFunction<
  Types.InternalLoanAutopayReschedulePayments
> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayReschedulePayments',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay/reschedule-payments`,
      ...args,
    }),
  )

export const internalLoanAutopayCreateNextAutopay: RequestFunction<
  Types.InternalLoanAutopayCreateNextAutopay
> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayCreateNextAutopay',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay/create-next-autopay`,
      ...args,
    }),
  )

export const internalLoanAutopayUpdateExpectedPaymentAmount: RequestFunction<
  Types.InternalLoanAutopayUpdateExpectedPaymentAmount
> = async ({ companyId, personId, loanId, autopayExpectedPaymentId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAutopayUpdateExpectedPaymentAmount',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/autopay/${autopayExpectedPaymentId}/update-amount`,
      ...args,
    }),
  )

export const internalLoanBalancesGet: RequestFunction<Types.InternalLoanBalancesGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanBalancesGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/balances`, ...args }),
  )

export const internalLoanFutureBalancesGet: RequestFunction<Types.InternalLoanFutureBalancesGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanFutureBalancesGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/future_balances`, ...args }),
  )

export const internalPromoProgramsExercise: RequestFunction<Types.InternalPromoProgramsExercise> = async ({
  companyId,
  personId,
  loanId,
  promoProgramId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalPromoProgramsExercise',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/promo-programs/${promoProgramId}/exercise`,
      ...args,
    }),
  )

export const internalPromoProgramsReverse: RequestFunction<Types.InternalPromoProgramsReverse> = async ({
  companyId,
  personId,
  loanId,
  promoProgramId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalPromoProgramsReverse',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/promo-programs/${promoProgramId}/reverse`,
      ...args,
    }),
  )

export const internalPromoProgramsGetAll: RequestFunction<Types.InternalPromoProgramsGetAll> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalPromoProgramsGetAll',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/promo-programs`, ...args }),
  )

export const internalPromoProgramsGet: RequestFunction<Types.InternalPromoProgramsGet> = async ({
  companyId,
  personId,
  loanId,
  promoProgramId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalPromoProgramsGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/promo-programs/${promoProgramId}`,
      ...args,
    }),
  )

export const internalLoanTypeIndexRateGetAll: RequestFunction<Types.InternalLoanTypeIndexRateGetAll> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanTypeIndexRateGetAll',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}/index-rates`, ...args }),
  )

export const internalLoanTypeIndexRateAdd: RequestFunction<Types.InternalLoanTypeIndexRateAdd> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanTypeIndexRateAdd',
    await peachApi.post({ url: `/companies/${companyId}/loan-types/${loanTypeId}/index-rates`, ...args }),
  )

export const internalLoanTypeIndexRateGet: RequestFunction<Types.InternalLoanTypeIndexRateGet> = async ({
  companyId,
  loanTypeId,
  indexRateId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanTypeIndexRateGet',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}/index-rates/${indexRateId}`, ...args }),
  )

export const internalLoanTypeIndexRateUpdate: RequestFunction<Types.InternalLoanTypeIndexRateUpdate> = async ({
  companyId,
  loanTypeId,
  indexRateId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanTypeIndexRateUpdate',
    await peachApi.put({ url: `/companies/${companyId}/loan-types/${loanTypeId}/index-rates/${indexRateId}`, ...args }),
  )

export const internalLoanTypeIndexRateDelete: RequestFunction<Types.InternalLoanTypeIndexRateDelete> = async ({
  companyId,
  loanTypeId,
  indexRateId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanTypeIndexRateDelete',
    await peachApi.delete({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/index-rates/${indexRateId}`,
      ...args,
    }),
  )

export const internalObligationsGetAll: RequestFunction<Types.InternalObligationsGetAll> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalObligationsGetAll',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/obligations`, ...args }),
  )

export const internalObligationCreate: RequestFunction<Types.InternalObligationCreate> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalObligationCreate',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/obligations`, ...args }),
  )

export const getLoan: RequestFunction<Types.GetLoan> = async ({ companyId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/getLoan',
    await peachApi.get({ url: `/companies/${companyId}/loans/${loanId}`, ...args }),
  )

export const getCompanyLoanObligations: RequestFunction<Types.GetCompanyLoanObligations> = async ({
  companyId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getCompanyLoanObligations',
    await peachApi.get({ url: `/companies/${companyId}/loans/${loanId}/obligations`, ...args }),
  )

export const obligationsRefreshPreviewForTimestamp: RequestFunction<
  Types.ObligationsRefreshPreviewForTimestamp
> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/obligationsRefreshPreviewForTimestamp',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/obligations/refresh-preview`,
      ...args,
    }),
  )

export const internalObligationsRefresh: RequestFunction<Types.InternalObligationsRefresh> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalObligationsRefresh',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/obligations/refresh`,
      ...args,
    }),
  )

export const internalLoanAgentDashboardToolsGet: RequestFunction<Types.InternalLoanAgentDashboardToolsGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAgentDashboardToolsGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/agent-dashboard-tools`,
      ...args,
    }),
  )

export const internalLoanCustomPaymentPlanRefresh: RequestFunction<
  Types.InternalLoanCustomPaymentPlanRefresh
> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanCustomPaymentPlanRefresh',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/custom-payment-plans/refresh`,
      ...args,
    }),
  )

export const obligationsRefresh: RequestFunction<Types.ObligationsRefresh> = async ({ personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/obligationsRefresh',
    await peachApi.post({ url: `/people/${personId}/loans/${loanId}/obligations/refresh`, ...args }),
  )

export const internalLoanTransactionsGetAll: RequestFunction<Types.InternalLoanTransactionsGetAll> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanTransactionsGetAll',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions`, ...args }),
  )

export const internalTransactionsCreate: RequestFunction<Types.InternalTransactionsCreate> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionsCreate',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions`, ...args }),
  )

export const internalTransactionsGetById: RequestFunction<Types.InternalTransactionsGetById> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionsGetById',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}`,
      ...args,
    }),
  )

export const internalTransactionsUpdate: RequestFunction<Types.InternalTransactionsUpdate> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionsUpdate',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}`,
      ...args,
    }),
  )

export const internalTransactionCancel: RequestFunction<Types.InternalTransactionCancel> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionCancel',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/cancel`,
      ...args,
    }),
  )

export const internalTransactionsDeleteChildren: RequestFunction<Types.InternalTransactionsDeleteChildren> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionsDeleteChildren',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/delete-children`,
      ...args,
    }),
  )

export const internalTransactionReverse: RequestFunction<Types.InternalTransactionReverse> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionReverse',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/reverse`,
      ...args,
    }),
  )

export const internalGetChargebacks: RequestFunction<Types.InternalGetChargebacks> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetChargebacks',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/chargebacks`,
      ...args,
    }),
  )

export const internalTransactionChargeback: RequestFunction<Types.InternalTransactionChargeback> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionChargeback',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/chargebacks`,
      ...args,
    }),
  )

export const internalGetChargebackById: RequestFunction<Types.InternalGetChargebackById> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  chargebackId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetChargebackById',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/chargebacks/${chargebackId}`,
      ...args,
    }),
  )

export const internalUpdateChargeback: RequestFunction<Types.InternalUpdateChargeback> = async ({
  companyId,
  personId,
  loanId,
  transactionId,
  chargebackId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUpdateChargeback',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/transactions/${transactionId}/chargebacks/${chargebackId}`,
      ...args,
    }),
  )

export const loanTypeDynamicFeeGetAll: RequestFunction<Types.LoanTypeDynamicFeeGetAll> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loanTypeDynamicFeeGetAll',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}/dynamic-fee-types`, ...args }),
  )

export const loanTypeDynamicFeeTypeAdd: RequestFunction<Types.LoanTypeDynamicFeeTypeAdd> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loanTypeDynamicFeeTypeAdd',
    await peachApi.post({ url: `/companies/${companyId}/loan-types/${loanTypeId}/dynamic-fee-types`, ...args }),
  )

export const internalLoanChargeOff: RequestFunction<Types.InternalLoanChargeOff> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanChargeOff',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/charge-off`, ...args }),
  )

export const internalLoanDueDateDefer: RequestFunction<Types.InternalLoanDueDateDefer> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanDueDateDefer',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/defer-due-date`, ...args }),
  )

export const internalLoanScheduleDefer: RequestFunction<Types.InternalLoanScheduleDefer> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanScheduleDefer',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/defer-schedule`, ...args }),
  )

export const internalLoanChangeTermsScra: RequestFunction<Types.InternalLoanChangeTermsScra> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanChangeTermsScra',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/payment-plan-scra`,
      ...args,
    }),
  )

export const internalLoanAccelerate: RequestFunction<Types.InternalLoanAccelerate> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAccelerate',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/accelerate`, ...args }),
  )

export const internalLoanRefresh: RequestFunction<Types.InternalLoanRefresh> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRefresh',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/refresh`, ...args }),
  )

export const internalLoanScheduleOptionsGet: RequestFunction<Types.InternalLoanScheduleOptionsGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanScheduleOptionsGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/schedule-options`, ...args }),
  )

export const loanTypeDynamicFeeTypeGet: RequestFunction<Types.LoanTypeDynamicFeeTypeGet> = async ({
  companyId,
  loanTypeId,
  dynamicFeeTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loanTypeDynamicFeeTypeGet',
    await peachApi.get({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/dynamic-fee-types/${dynamicFeeTypeId}`,
      ...args,
    }),
  )

export const internalLoanLateFeeCharge: RequestFunction<Types.InternalLoanLateFeeCharge> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanLateFeeCharge',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/charge-late-fee`, ...args }),
  )

export const internalLoanServiceFeeCharge: RequestFunction<Types.InternalLoanServiceFeeCharge> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanServiceFeeCharge',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/charge-service-fee`,
      ...args,
    }),
  )

export const internalLoanNsfFeesCharge: RequestFunction<Types.InternalLoanNsfFeesCharge> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanNsfFeesCharge',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/charge-nsf-fees`, ...args }),
  )

export const internalFeeTypesGet: RequestFunction<Types.InternalFeeTypesGet> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFeeTypesGet',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}/fee-types`, ...args }),
  )

export const internalDynamicFeeGet: RequestFunction<Types.InternalDynamicFeeGet> = async ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDynamicFeeGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/fees/${loanFeeId}`,
      ...args,
    }),
  )

export const internalDynamicFeeUpdate: RequestFunction<Types.InternalDynamicFeeUpdate> = async ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDynamicFeeUpdate',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/fees/${loanFeeId}`,
      ...args,
    }),
  )

export const internalMarkFeeBooked: RequestFunction<Types.InternalMarkFeeBooked> = async ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalMarkFeeBooked',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/fees/${loanFeeId}/mark-fee-booked`,
      ...args,
    }),
  )

export const internalFeesGet: RequestFunction<Types.InternalFeesGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFeesGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/fees`, ...args }),
  )

export const internalFeesPost: RequestFunction<Types.InternalFeesPost> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFeesPost',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/fees`, ...args }),
  )

export const internalFeesCancelOne: RequestFunction<Types.InternalFeesCancelOne> = async ({
  companyId,
  personId,
  loanId,
  loanFeeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFeesCancelOne',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/fees/${loanFeeId}/cancel`,
      ...args,
    }),
  )

export const internalFeesCancel: RequestFunction<Types.InternalFeesCancel> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFeesCancel',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/cancel-fees`, ...args }),
  )

export const internalLoanAccrue: RequestFunction<Types.InternalLoanAccrue> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanAccrue',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/accrue`, ...args }),
  )

export const loanPayoff: RequestFunction<Types.LoanPayoff> = async ({ personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/loanPayoff',
    await peachApi.post({ url: `/people/${personId}/loans/${loanId}/payoff`, ...args }),
  )

export const internalLoanPayoff: RequestFunction<Types.InternalLoanPayoff> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanPayoff',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/payoff`, ...args }),
  )

export const internalLoanPayoffReverse: RequestFunction<Types.InternalLoanPayoffReverse> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanPayoffReverse',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/reverse-payoff`, ...args }),
  )

export const loanPayoffReverse: RequestFunction<Types.LoanPayoffReverse> = async ({ personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/loanPayoffReverse',
    await peachApi.post({ url: `/people/${personId}/loans/${loanId}/reverse-payoff`, ...args }),
  )

export const internalLoanExpectedPaymentsRefresh: RequestFunction<Types.InternalLoanExpectedPaymentsRefresh> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanExpectedPaymentsRefresh',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/expected-payments/refresh`,
      ...args,
    }),
  )

export const loanExpectedPaymentsRefresh: RequestFunction<Types.LoanExpectedPaymentsRefresh> = async ({
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loanExpectedPaymentsRefresh',
    await peachApi.post({ url: `/people/${personId}/loans/${loanId}/expected-payments/refresh`, ...args }),
  )

export const internalLoanExpectedPaymentOverride: RequestFunction<Types.InternalLoanExpectedPaymentOverride> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanExpectedPaymentOverride',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/expected-payments/override`,
      ...args,
    }),
  )

export const internalFeeAdjustForLoc: RequestFunction<Types.InternalFeeAdjustForLoc> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFeeAdjustForLoc',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/adjust-fees`, ...args }),
  )

export const internalFinalizeMinPaymentsForLoc: RequestFunction<Types.InternalFinalizeMinPaymentsForLoc> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalFinalizeMinPaymentsForLoc',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/min-payment`, ...args }),
  )

export const internalLoanExpectedPaymentsOverLimit: RequestFunction<
  Types.InternalLoanExpectedPaymentsOverLimit
> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanExpectedPaymentsOverLimit',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/expected-payments/over-limit`,
      ...args,
    }),
  )

export const emitScheduledInteractionEvents: RequestFunction<Types.EmitScheduledInteractionEvents> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/emitScheduledInteractionEvents',
    await peachApi.post({ url: `/rpc/companies/${companyId}/emit-scheduled-interaction-events`, ...args }),
  )

export const emitExpiredCaseSnoozeEvents: RequestFunction<Types.EmitExpiredCaseSnoozeEvents> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/emitExpiredCaseSnoozeEvents',
    await peachApi.post({ url: `/rpc/companies/${companyId}/emit-expired-case-snooze-events`, ...args }),
  )

export const emitExpiredCaseDniEvents: RequestFunction<Types.EmitExpiredCaseDniEvents> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/emitExpiredCaseDniEvents',
    await peachApi.post({ url: `/rpc/companies/${companyId}/emit-expired-case-dni-events`, ...args }),
  )

export const internalCaseTypeGetAll: RequestFunction<Types.InternalCaseTypeGetAll> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalCaseTypeGetAll',
    await peachApi.get({ url: `/companies/${companyId}/case-types`, ...args }),
  )

export const internalCaseTypeCreateSmartReviewTasks: RequestFunction<
  Types.InternalCaseTypeCreateSmartReviewTasks
> = async ({ companyId, caseTypeId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalCaseTypeCreateSmartReviewTasks',
    await peachApi.post({ url: `/companies/${companyId}/case-types/${caseTypeId}/create-smart-review-tasks`, ...args }),
  )

export const servicesGetAll: RequestFunction<Types.ServicesGetAll> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/servicesGetAll',
    await peachApi.get({ url: `/companies/${companyId}/monitoring/services`, ...args }),
  )

export const serviceCreate: RequestFunction<Types.ServiceCreate> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/serviceCreate',
    await peachApi.post({ url: `/companies/${companyId}/monitoring/services`, ...args }),
  )

export const servicesGet: RequestFunction<Types.ServicesGet> = async ({ companyId, serviceId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/servicesGet',
    await peachApi.get({ url: `/companies/${companyId}/monitoring/services/${serviceId}`, ...args }),
  )

export const dueStatuses: RequestFunction<Types.DueStatuses> = async ({ companyId, serviceId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/dueStatuses',
    await peachApi.get({ url: `/companies/${companyId}/monitoring/services/${serviceId}/due-statuses`, ...args }),
  )

export const allStatuses: RequestFunction<Types.AllStatuses> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/allStatuses', await peachApi.get({ url: `/monitoring-status`, ...args }))

export const updateStatuses: RequestFunction<Types.UpdateStatuses> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/updateStatuses',
    await peachApi.patch({ url: `/monitoring-status`, ...args }),
  )

export const internalCaseSnooze: RequestFunction<Types.InternalCaseSnooze> = async ({
  companyId,
  personId,
  caseId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCaseSnooze',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/cases/${caseId}/snooze`, ...args }),
  )

export const getAddressesInFipsCode: RequestFunction<Types.GetAddressesInFipsCode> = async ({
  state,
  fipsCode,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getAddressesInFipsCode',
    await peachApi.get({ url: `/uscounties/${state}/${fipsCode}/addressesinCounty`, ...args }),
  )

export const internalStatusGetAll: RequestFunction<Types.InternalStatusGetAll> = async ({
  companyId,
  personId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalStatusGetAll',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/monitoring-status`, ...args }),
  )

export const internalStatusUpdate: RequestFunction<Types.InternalStatusUpdate> = async ({
  companyId,
  personId,
  statusId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalStatusUpdate',
    await peachApi.put({ url: `/companies/${companyId}/people/${personId}/monitoring-status/${statusId}`, ...args }),
  )

export const statusGetAll: RequestFunction<Types.StatusGetAll> = async ({ personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/statusGetAll',
    await peachApi.get({ url: `/people/${personId}/monitoring-status`, ...args }),
  )

export const statusCreate: RequestFunction<Types.StatusCreate> = async ({ personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/statusCreate',
    await peachApi.post({ url: `/people/${personId}/monitoring-status`, ...args }),
  )

export const statusGet: RequestFunction<Types.StatusGet> = async ({ personId, statusId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/statusGet',
    await peachApi.get({ url: `/people/${personId}/monitoring-status/${statusId}`, ...args }),
  )

export const statusUpdate: RequestFunction<Types.StatusUpdate> = async ({ personId, statusId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/statusUpdate',
    await peachApi.put({ url: `/people/${personId}/monitoring-status/${statusId}`, ...args }),
  )

export const getAllDisasters: RequestFunction<Types.GetAllDisasters> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/getAllDisasters', await peachApi.get({ url: `/disasters`, ...args }))

export const createDisaster: RequestFunction<Types.CreateDisaster> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/createDisaster', await peachApi.post({ url: `/disasters`, ...args }))

export const getDisasterImpactedAreasCsv: RequestFunction<Types.GetDisasterImpactedAreasCsv> = async ({
  disasterNumber,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getDisasterImpactedAreasCsv',
    await peachApi.get({ url: `/disasters/${disasterNumber}/impacted-areas.csv`, ...args }),
  )

export const findNewUpdatedDisasters: RequestFunction<Types.FindNewUpdatedDisasters> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/findNewUpdatedDisasters',
    await peachApi.post({ url: `/disasters/new-updated`, ...args }),
  )

export const companyRoleGetAll: RequestFunction<Types.CompanyRoleGetAll> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/companyRoleGetAll',
    await peachApi.get({ url: `/companies/${companyId}/roles`, ...args }),
  )

export const companyRoleAdd: RequestFunction<Types.CompanyRoleAdd> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/companyRoleAdd',
    await peachApi.post({ url: `/companies/${companyId}/roles`, ...args }),
  )

export const companyRoleGet: RequestFunction<Types.CompanyRoleGet> = async ({ companyId, roleId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/companyRoleGet',
    await peachApi.get({ url: `/companies/${companyId}/roles/${roleId}`, ...args }),
  )

export const companyRoleUpdate: RequestFunction<Types.CompanyRoleUpdate> = async ({ companyId, roleId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/companyRoleUpdate',
    await peachApi.put({ url: `/companies/${companyId}/roles/${roleId}`, ...args }),
  )

export const companyRoleDelete: RequestFunction<Types.CompanyRoleDelete> = async ({ companyId, roleId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/companyRoleDelete',
    await peachApi.delete({ url: `/companies/${companyId}/roles/${roleId}`, ...args }),
  )

export const companyRolePermissionsElevate: RequestFunction<Types.CompanyRolePermissionsElevate> = async ({
  companyId,
  roleId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyRolePermissionsElevate',
    await peachApi.post({ url: `/companies/${companyId}/roles/${roleId}/permissions`, ...args }),
  )

export const companyRolePermissionsRevoke: RequestFunction<Types.CompanyRolePermissionsRevoke> = async ({
  companyId,
  roleId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyRolePermissionsRevoke',
    await peachApi.delete({ url: `/companies/${companyId}/roles/${roleId}/permissions`, ...args }),
  )

export const companyRolePermissionsRevokeOne: RequestFunction<Types.CompanyRolePermissionsRevokeOne> = async ({
  companyId,
  roleId,
  permissionId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyRolePermissionsRevokeOne',
    await peachApi.delete({ url: `/companies/${companyId}/roles/${roleId}/permissions/${permissionId}`, ...args }),
  )

export const companyPermissionsGet: RequestFunction<Types.CompanyPermissionsGet> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/companyPermissionsGet',
    await peachApi.get({ url: `/companies/${companyId}/permissions`, ...args }),
  )

export const internalUsersGet: RequestFunction<Types.InternalUsersGet> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalUsersGet',
    await peachApi.get({ url: `/companies/${companyId}/users`, ...args }),
  )

export const internalUserCreate: RequestFunction<Types.InternalUserCreate> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalUserCreate',
    await peachApi.post({ url: `/companies/${companyId}/users`, ...args }),
  )

export const internalUserGet: RequestFunction<Types.InternalUserGet> = async ({ companyId, userId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalUserGet',
    await peachApi.get({ url: `/companies/${companyId}/users/${userId}`, ...args }),
  )

export const internalUserUpdate: RequestFunction<Types.InternalUserUpdate> = async ({ companyId, userId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalUserUpdate',
    await peachApi.put({ url: `/companies/${companyId}/users/${userId}`, ...args }),
  )

export const internalUserGetPermissions: RequestFunction<Types.InternalUserGetPermissions> = async ({
  companyId,
  userId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUserGetPermissions',
    await peachApi.get({ url: `/companies/${companyId}/users/${userId}/permissions`, ...args }),
  )

export const internalUserRoleGet: RequestFunction<Types.InternalUserRoleGet> = async ({ companyId, userId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalUserRoleGet',
    await peachApi.get({ url: `/companies/${companyId}/users/${userId}/roles`, ...args }),
  )

export const internalUserRoleUpdate: RequestFunction<Types.InternalUserRoleUpdate> = async ({
  companyId,
  userId,
  roleId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUserRoleUpdate',
    await peachApi.put({ url: `/companies/${companyId}/users/${userId}/roles/${roleId}`, ...args }),
  )

export const internalUserRoleDelete: RequestFunction<Types.InternalUserRoleDelete> = async ({
  companyId,
  userId,
  roleId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUserRoleDelete',
    await peachApi.delete({ url: `/companies/${companyId}/users/${userId}/roles/${roleId}`, ...args }),
  )

export const investorGetAll: RequestFunction<Types.InvestorGetAll> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/investorGetAll',
    await peachApi.get({ url: `/companies/${companyId}/investors`, ...args }),
  )

export const investorCreate: RequestFunction<Types.InvestorCreate> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/investorCreate',
    await peachApi.post({ url: `/companies/${companyId}/investors`, ...args }),
  )

export const internalLoanInvestorsGetAll: RequestFunction<Types.InternalLoanInvestorsGetAll> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanInvestorsGetAll',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/investors`, ...args }),
  )

export const investorGet: RequestFunction<Types.InvestorGet> = async ({ companyId, investorId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/investorGet',
    await peachApi.get({ url: `/companies/${companyId}/investors/${investorId}`, ...args }),
  )

export const investorUpdate: RequestFunction<Types.InvestorUpdate> = async ({ companyId, investorId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/investorUpdate',
    await peachApi.put({ url: `/companies/${companyId}/investors/${investorId}`, ...args }),
  )

export const settlementInstrumentGetAll: RequestFunction<Types.SettlementInstrumentGetAll> = async ({
  companyId,
  investorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/settlementInstrumentGetAll',
    await peachApi.get({ url: `/companies/${companyId}/investors/${investorId}/settlement-instruments`, ...args }),
  )

export const settlementInstrumentCreate: RequestFunction<Types.SettlementInstrumentCreate> = async ({
  companyId,
  investorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/settlementInstrumentCreate',
    await peachApi.post({ url: `/companies/${companyId}/investors/${investorId}/settlement-instruments`, ...args }),
  )

export const settlementInstrumentGet: RequestFunction<Types.SettlementInstrumentGet> = async ({
  companyId,
  investorId,
  settlementInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/settlementInstrumentGet',
    await peachApi.get({
      url: `/companies/${companyId}/investors/${investorId}/settlement-instruments/${settlementInstrumentId}`,
      ...args,
    }),
  )

export const settlementInstrumentUpdate: RequestFunction<Types.SettlementInstrumentUpdate> = async ({
  companyId,
  investorId,
  settlementInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/settlementInstrumentUpdate',
    await peachApi.put({
      url: `/companies/${companyId}/investors/${investorId}/settlement-instruments/${settlementInstrumentId}`,
      ...args,
    }),
  )

export const fundingInstrumentGetAll: RequestFunction<Types.FundingInstrumentGetAll> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/fundingInstrumentGetAll',
    await peachApi.get({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/funding-instruments`,
      ...args,
    }),
  )

export const fundingInstrumentCreate: RequestFunction<Types.FundingInstrumentCreate> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/fundingInstrumentCreate',
    await peachApi.post({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/funding-instruments`,
      ...args,
    }),
  )

export const fundingInstrumentGet: RequestFunction<Types.FundingInstrumentGet> = async ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/fundingInstrumentGet',
    await peachApi.get({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/funding-instruments/${fundingInstrumentId}`,
      ...args,
    }),
  )

export const fundingInstrumentUpdate: RequestFunction<Types.FundingInstrumentUpdate> = async ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/fundingInstrumentUpdate',
    await peachApi.put({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/funding-instruments/${fundingInstrumentId}`,
      ...args,
    }),
  )

export const getRecentFundingAccountBalances: RequestFunction<Types.GetRecentFundingAccountBalances> = async ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getRecentFundingAccountBalances',
    await peachApi.get({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/funding-instruments/${fundingInstrumentId}/balance`,
      ...args,
    }),
  )

export const fundingInstrumentCreateBalance: RequestFunction<Types.FundingInstrumentCreateBalance> = async ({
  companyId,
  paymentProcessorId,
  fundingInstrumentId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/fundingInstrumentCreateBalance',
    await peachApi.post({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/funding-instruments/${fundingInstrumentId}/balance`,
      ...args,
    }),
  )

export const getAllTransferSettlementsForAllCompanies: RequestFunction<
  Types.GetAllTransferSettlementsForAllCompanies
> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/getAllTransferSettlementsForAllCompanies',
    await peachApi.get({ url: `/settlements`, ...args }),
  )

export const getAllTransferSettlements: RequestFunction<Types.GetAllTransferSettlements> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getAllTransferSettlements',
    await peachApi.get({ url: `/companies/${companyId}/settlements`, ...args }),
  )

export const createTransferSettlements: RequestFunction<Types.CreateTransferSettlements> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/createTransferSettlements',
    await peachApi.post({ url: `/companies/${companyId}/settlements`, ...args }),
  )

export const getTransferSettlement: RequestFunction<Types.GetTransferSettlement> = async ({
  companyId,
  transferSettlementId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getTransferSettlement',
    await peachApi.get({ url: `/companies/${companyId}/settlements/${transferSettlementId}`, ...args }),
  )

export const updateTransferSettlement: RequestFunction<Types.UpdateTransferSettlement> = async ({
  companyId,
  transferSettlementId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/updateTransferSettlement',
    await peachApi.put({ url: `/companies/${companyId}/settlements/${transferSettlementId}`, ...args }),
  )

export const updateTransfersExpectingFunding: RequestFunction<Types.UpdateTransfersExpectingFunding> = async ({
  companyId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/updateTransfersExpectingFunding',
    await peachApi.put({ url: `/companies/${companyId}/loans/${loanId}/transfers`, ...args }),
  )

export const endSession: RequestFunction<Types.EndSession> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/endSession', await peachApi.post({ url: `/auth/endsession`, ...args }))

export const renewJwtToken: RequestFunction<Types.RenewJwtToken> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/renewJwtToken', await peachApi.put({ url: `/auth/token`, ...args }))

export const validateAndSendToken: RequestFunction<Types.ValidateAndSendToken> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/validateAndSendToken', await peachApi.post({ url: `/auth/token`, ...args }))

export const logout: RequestFunction<Types.Logout> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/logout', await peachApi.delete({ url: `/auth/token`, ...args }))

export const shortLivedToken: RequestFunction<Types.ShortLivedToken> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/shortLivedToken',
    await peachApi.post({ url: `/auth/token/short-lived`, ...args }),
  )

export const passwordPost: RequestFunction<Types.PasswordPost> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/passwordPost', await peachApi.post({ url: `/auth/password`, ...args }))

export const userPermissions_get: RequestFunction<Types.UserPermissions_get> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/userPermissions_get',
    await peachApi.get({ url: `/auth/permissions`, ...args }),
  )

export const sendOneTimeCode: RequestFunction<Types.SendOneTimeCode> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/sendOneTimeCode',
    await peachApi.post({ url: `/companies/${companyId}/auth/send-one-time-code`, ...args }),
  )

export const idpInitiated: RequestFunction<Types.IdpInitiated> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/idpInitiated',
    await peachApi.post({ url: `/companies/${companyId}/auth/saml/callback`, ...args }),
  )

export const spInitiated: RequestFunction<Types.SpInitiated> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/spInitiated',
    await peachApi.get({ url: `/companies/${companyId}/auth/saml/login`, ...args }),
  )

export const spMetadataXml: RequestFunction<Types.SpMetadataXml> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/spMetadataXml',
    await peachApi.get({ url: `/companies/${companyId}/auth/saml/metadata.xml`, ...args }),
  )

export const idpSetupHelp: RequestFunction<Types.IdpSetupHelp> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/idpSetupHelp',
    await peachApi.get({ url: `/companies/${companyId}/auth/saml/help`, ...args }),
  )

export const userInfoGet: RequestFunction<Types.UserInfoGet> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/userInfoGet', await peachApi.get({ url: `/auth/user`, ...args }))

export const plaidAuthLinkToken: RequestFunction<Types.PlaidAuthLinkToken> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/plaidAuthLinkToken',
    await peachApi.post({ url: `/plaid/auth/link-token`, ...args }),
  )

export const codeGetAll: RequestFunction<Types.CodeGetAll> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/codeGetAll', await peachApi.get({ url: `/code`, ...args }))

export const codeCreate: RequestFunction<Types.CodeCreate> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/codeCreate', await peachApi.post({ url: `/code`, ...args }))

export const exportCodes: RequestFunction<Types.ExportCodes> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/exportCodes', await peachApi.get({ url: `/code/export`, ...args }))

export const importCodes: RequestFunction<Types.ImportCodes> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/importCodes', await peachApi.post({ url: `/code/import`, ...args }))

export const internalImportCodes: RequestFunction<Types.InternalImportCodes> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalImportCodes',
    await peachApi.post({ url: `/companies/${companyId}/compliance/code/import`, ...args }),
  )

export const codeGet: RequestFunction<Types.CodeGet> = async ({ codeId, ...args }) =>
  validate('http://peachfinance.com/schemas/codeGet', await peachApi.get({ url: `/code/${codeId}`, ...args }))

export const codeUpdate: RequestFunction<Types.CodeUpdate> = async ({ codeId, ...args }) =>
  validate('http://peachfinance.com/schemas/codeUpdate', await peachApi.put({ url: `/code/${codeId}`, ...args }))

export const codeExecute: RequestFunction<Types.CodeExecute> = async ({ codeId, ...args }) =>
  validate('http://peachfinance.com/schemas/codeExecute', await peachApi.post({ url: `/code/${codeId}`, ...args }))

export const transactionsGetInternal: RequestFunction<Types.TransactionsGetInternal> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/transactionsGetInternal',
    await peachApi.get({ url: `/transactions`, ...args }),
  )

export const transactionsUpdateInternal: RequestFunction<Types.TransactionsUpdateInternal> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/transactionsUpdateInternal',
    await peachApi.post({ url: `/transactions`, ...args }),
  )

export const transactionsGetScheduledAchInternal: RequestFunction<Types.TransactionsGetScheduledAchInternal> = async ({
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/transactionsGetScheduledAchInternal',
    await peachApi.get({ url: `/transactions/scheduled`, ...args }),
  )

export const transactionsUpdateScheduledAchInternal: RequestFunction<
  Types.TransactionsUpdateScheduledAchInternal
> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/transactionsUpdateScheduledAchInternal',
    await peachApi.post({ url: `/transactions/scheduled`, ...args }),
  )

export const transactionsCreateRefundServiceCreditInternal: RequestFunction<
  Types.TransactionsCreateRefundServiceCreditInternal
> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/transactionsCreateRefundServiceCreditInternal',
    await peachApi.post({ url: `/transactions/refund`, ...args }),
  )

export const microdepositsGetScheduledInternal: RequestFunction<Types.MicrodepositsGetScheduledInternal> = async ({
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/microdepositsGetScheduledInternal',
    await peachApi.get({ url: `/transactions/microdeposits`, ...args }),
  )

export const transactionsUpdateMicrodepositsInternal: RequestFunction<
  Types.TransactionsUpdateMicrodepositsInternal
> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/transactionsUpdateMicrodepositsInternal',
    await peachApi.post({ url: `/transactions/microdeposits`, ...args }),
  )

export const microdepositsSendReminders: RequestFunction<Types.MicrodepositsSendReminders> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/microdepositsSendReminders',
    await peachApi.post({ url: `/transactions/microdeposits/reminders`, ...args }),
  )

export const internalTransactionsGetLoanIds: RequestFunction<Types.InternalTransactionsGetLoanIds> = async ({
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalTransactionsGetLoanIds',
    await peachApi.post({ url: `/transactions/loan-ids`, ...args }),
  )

export const transactionsGetRawInternalHandler: RequestFunction<Types.TransactionsGetRawInternalHandler> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/transactionsGetRawInternalHandler',
    await peachApi.get({ url: `/companies/${companyId}/loan-types/${loanTypeId}/transactions`, ...args }),
  )

export const getReconciliationTransactionsByCompany: RequestFunction<
  Types.GetReconciliationTransactionsByCompany
> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/getReconciliationTransactionsByCompany',
    await peachApi.get({ url: `/companies/${companyId}/transactions`, ...args }),
  )

export const createReconciliation: RequestFunction<Types.CreateReconciliation> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/createReconciliation',
    await peachApi.post({ url: `/companies/${companyId}/transactions`, ...args }),
  )

export const companyTransactionsGetTransitional: RequestFunction<Types.CompanyTransactionsGetTransitional> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyTransactionsGetTransitional',
    await peachApi.get({ url: `/companies/${companyId}/transactions/auto-transition`, ...args }),
  )

export const transactionsGetAllExpiredHoldDays: RequestFunction<Types.TransactionsGetAllExpiredHoldDays> = async ({
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/transactionsGetAllExpiredHoldDays',
    await peachApi.get({ url: `/transactions/expired-hold-days`, ...args }),
  )

export const getRoutingNumber: RequestFunction<Types.GetRoutingNumber> = async ({ routingNumber, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/getRoutingNumber',
    await peachApi.get({ url: `/routing-numbers/${routingNumber}`, ...args }),
  )

export const getBinNumber: RequestFunction<Types.GetBinNumber> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/getBinNumber', await peachApi.post({ url: `/bin-numbers`, ...args }))

export const autopayGetAllInternalCompany: RequestFunction<Types.AutopayGetAllInternalCompany> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/autopayGetAllInternalCompany',
    await peachApi.get({ url: `/companies/${companyId}/autopays`, ...args }),
  )

export const autopayGetAllInternal: RequestFunction<Types.AutopayGetAllInternal> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/autopayGetAllInternal', await peachApi.get({ url: `/autopays`, ...args }))

export const getNotificationDataInternal: RequestFunction<Types.GetNotificationDataInternal> = async ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getNotificationDataInternal',
    await peachApi.get({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/repayment-engine/${repaymentNotificationTypeId}/reminders`,
      ...args,
    }),
  )

export const getNotificationTypesByCompany: RequestFunction<Types.GetNotificationTypesByCompany> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getNotificationTypesByCompany',
    await peachApi.get({ url: `/companies/${companyId}/repayment-engine/notifications`, ...args }),
  )

export const getProductsWithNotifications: RequestFunction<Types.GetProductsWithNotifications> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getProductsWithNotifications',
    await peachApi.get({ url: `/companies/${companyId}/repayment-engine/products-with-notifications`, ...args }),
  )

export const getSubjectsWithNotifications: RequestFunction<Types.GetSubjectsWithNotifications> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getSubjectsWithNotifications',
    await peachApi.get({ url: `/companies/${companyId}/repayment-engine/subjects-with-notifications`, ...args }),
  )

export const getTriggersWithNotifications: RequestFunction<Types.GetTriggersWithNotifications> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getTriggersWithNotifications',
    await peachApi.get({ url: `/companies/${companyId}/repayment-engine/triggers-with-notifications`, ...args }),
  )

export const getNotificationTypesInternal: RequestFunction<Types.GetNotificationTypesInternal> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getNotificationTypesInternal',
    await peachApi.get({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/repayment-engine/notifications`,
      ...args,
    }),
  )

export const createNotificationTypeInternal: RequestFunction<Types.CreateNotificationTypeInternal> = async ({
  companyId,
  loanTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/createNotificationTypeInternal',
    await peachApi.post({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/repayment-engine/notifications`,
      ...args,
    }),
  )

export const getNotificationTypeById: RequestFunction<Types.GetNotificationTypeById> = async ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getNotificationTypeById',
    await peachApi.get({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/repayment-engine/${repaymentNotificationTypeId}`,
      ...args,
    }),
  )

export const updateNotificationTypeInternal: RequestFunction<Types.UpdateNotificationTypeInternal> = async ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/updateNotificationTypeInternal',
    await peachApi.put({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/repayment-engine/${repaymentNotificationTypeId}`,
      ...args,
    }),
  )

export const deleteNotificationTypeInternal: RequestFunction<Types.DeleteNotificationTypeInternal> = async ({
  companyId,
  loanTypeId,
  repaymentNotificationTypeId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/deleteNotificationTypeInternal',
    await peachApi.delete({
      url: `/companies/${companyId}/loan-types/${loanTypeId}/repayment-engine/${repaymentNotificationTypeId}`,
      ...args,
    }),
  )

export const getRepaymentEngineInfo: RequestFunction<Types.GetRepaymentEngineInfo> = async ({
  companyId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/getRepaymentEngineInfo',
    await peachApi.get({ url: `/companies/${companyId}/loans/${loanId}/repayment-engine-info`, ...args }),
  )

export const hasher: RequestFunction<Types.Hasher> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/hasher', await peachApi.post({ url: `/internal/hasher`, ...args }))

export const getPeriods: RequestFunction<Types.GetPeriods> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/getPeriods', await peachApi.get({ url: `/loan-periods`, ...args }))

export const createStatement: RequestFunction<Types.CreateStatement> = async ({ periodId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/createStatement',
    await peachApi.post({ url: `/loan-periods/${periodId}/statement`, ...args }),
  )

export const internalSaveAutopay: RequestFunction<Types.InternalSaveAutopay> = async ({
  personId,
  loanId,
  statementId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalSaveAutopay',
    await peachApi.post({ url: `/people/${personId}/loans/${loanId}/statements/${statementId}/save-autopay`, ...args }),
  )

export const internalGenerateStatement: RequestFunction<Types.InternalGenerateStatement> = async ({
  companyId,
  personId,
  loanId,
  periodId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGenerateStatement',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/periods/${periodId}/generate-statement`,
      ...args,
    }),
  )

export const revokeOrReinstateGrace: RequestFunction<Types.RevokeOrReinstateGrace> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/revokeOrReinstateGrace',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/revoke-or-reinstate-grace`,
      ...args,
    }),
  )

export const internalComputeBalancesForGrace: RequestFunction<Types.InternalComputeBalancesForGrace> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalComputeBalancesForGrace',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/compute-balances-for-grace`,
      ...args,
    }),
  )

export const internalConsentGet: RequestFunction<Types.InternalConsentGet> = async ({ companyId, personId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalConsentGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/consents`, ...args }),
  )

export const internalConsentUpdate: RequestFunction<Types.InternalConsentUpdate> = async ({
  companyId,
  personId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalConsentUpdate',
    await peachApi.put({ url: `/companies/${companyId}/people/${personId}/consents`, ...args }),
  )

export const internalLoanRefundsGet: RequestFunction<Types.InternalLoanRefundsGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRefundsGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/refunds`, ...args }),
  )

export const internalLoanRefundsPost: RequestFunction<Types.InternalLoanRefundsPost> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRefundsPost',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/refunds`, ...args }),
  )

export const loanRefundsGetOne: RequestFunction<Types.LoanRefundsGetOne> = async ({
  companyId,
  personId,
  loanId,
  loanRefundId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loanRefundsGetOne',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/refunds/${loanRefundId}`,
      ...args,
    }),
  )

export const internalLoanRefundsV2Post: RequestFunction<Types.InternalLoanRefundsV2Post> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanRefundsV2Post',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/refunds-v2`, ...args }),
  )

export const internalLoanActivate: RequestFunction<Types.InternalLoanActivate> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanActivate',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/activate`, ...args }),
  )

export const loanUpdateMigrationStatus: RequestFunction<Types.LoanUpdateMigrationStatus> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/loanUpdateMigrationStatus',
    await peachApi.put({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/migration-status`, ...args }),
  )

export const expandUrl: RequestFunction<Types.ExpandUrl> = async ({ shortId, ...args }) =>
  validate('http://peachfinance.com/schemas/expandUrl', await peachApi.get({ url: `/expand-url/${shortId}`, ...args }))

export const internalGetStatements: RequestFunction<Types.InternalGetStatements> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetStatements',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/statements`, ...args }),
  )

export const deleteStatement: RequestFunction<Types.DeleteStatement> = async ({
  companyId,
  personId,
  loanId,
  statementId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/deleteStatement',
    await peachApi.delete({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/statements/${statementId}`,
      ...args,
    }),
  )

export const internalLoanDrawLocIdGet: RequestFunction<Types.InternalLoanDrawLocIdGet> = async ({ drawId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanDrawLocIdGet',
    await peachApi.get({ url: `/loans/draws/${drawId}/line-of-credit-id`, ...args }),
  )

export const internalLoanDrawsGet: RequestFunction<Types.InternalLoanDrawsGet> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanDrawsGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws`, ...args }),
  )

export const internalLoanDrawsPost: RequestFunction<Types.InternalLoanDrawsPost> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanDrawsPost',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws`, ...args }),
  )

export const internalDrawActivate: RequestFunction<Types.InternalDrawActivate> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawActivate',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/activate`,
      ...args,
    }),
  )

export const internalLoanDrawsGetWithoutBorrower: RequestFunction<Types.InternalLoanDrawsGetWithoutBorrower> = async ({
  companyId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalLoanDrawsGetWithoutBorrower',
    await peachApi.get({ url: `/companies/${companyId}/loans/${loanId}/draws`, ...args }),
  )

export const internalCloseLocIfInactive: RequestFunction<Types.InternalCloseLocIfInactive> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCloseLocIfInactive',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/close-inactive-line`,
      ...args,
    }),
  )

export const internalDrawChargeVariableDrawFee: RequestFunction<Types.InternalDrawChargeVariableDrawFee> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawChargeVariableDrawFee',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/charge-variable-draw-fee`,
      ...args,
    }),
  )

export const internalClearMinPayments: RequestFunction<Types.InternalClearMinPayments> = async ({
  companyId,
  personId,
  loanId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalClearMinPayments',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/clear-min-payments`,
      ...args,
    }),
  )

export const refreshAmortizedDraw: RequestFunction<Types.RefreshAmortizedDraw> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/refreshAmortizedDraw',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/refresh`,
      ...args,
    }),
  )

export const internalDrawPurchasesGet: RequestFunction<Types.InternalDrawPurchasesGet> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawPurchasesGet',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/purchases`,
      ...args,
    }),
  )

export const internalDrawPurchasesCreate: RequestFunction<Types.InternalDrawPurchasesCreate> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawPurchasesCreate',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/purchases`,
      ...args,
    }),
  )

export const internalDrawPurchaseGetOne: RequestFunction<Types.InternalDrawPurchaseGetOne> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  purchaseId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawPurchaseGetOne',
    await peachApi.get({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/purchases/${purchaseId}`,
      ...args,
    }),
  )

export const internalDrawPurchaseUpdate: RequestFunction<Types.InternalDrawPurchaseUpdate> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  purchaseId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawPurchaseUpdate',
    await peachApi.put({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/purchases/${purchaseId}`,
      ...args,
    }),
  )

export const internalDrawPurchaseChargeFees: RequestFunction<Types.InternalDrawPurchaseChargeFees> = async ({
  companyId,
  personId,
  loanId,
  drawId,
  purchaseId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDrawPurchaseChargeFees',
    await peachApi.post({
      url: `/companies/${companyId}/people/${personId}/loans/${loanId}/draws/${drawId}/purchases/${purchaseId}/charge-fees`,
      ...args,
    }),
  )

export const internalCaseEscalationsGet: RequestFunction<Types.InternalCaseEscalationsGet> = async ({
  companyId,
  caseId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCaseEscalationsGet',
    await peachApi.get({ url: `/companies/${companyId}/cases/${caseId}/escalations`, ...args }),
  )

export const internalCaseEscalationsCreate: RequestFunction<Types.InternalCaseEscalationsCreate> = async ({
  companyId,
  caseId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCaseEscalationsCreate',
    await peachApi.post({ url: `/companies/${companyId}/cases/${caseId}/escalations`, ...args }),
  )

export const loanStateGet: RequestFunction<Types.LoanStateGet> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/loanStateGet',
    await peachApi.get({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/state`, ...args }),
  )

export const loanStatePost: RequestFunction<Types.LoanStatePost> = async ({ companyId, personId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/loanStatePost',
    await peachApi.post({ url: `/companies/${companyId}/people/${personId}/loans/${loanId}/state`, ...args }),
  )

export const companyRotateTokenizationKey: RequestFunction<Types.CompanyRotateTokenizationKey> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/companyRotateTokenizationKey',
    await peachApi.post({ url: `/companies/${companyId}/rotate-tokenization-key`, ...args }),
  )

export const cleanPaymentInstruments: RequestFunction<Types.CleanPaymentInstruments> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/cleanPaymentInstruments',
    await peachApi.post({ url: `/companies/${companyId}/payment-instruments/clean`, ...args }),
  )

export const testSpawnTask: RequestFunction<Types.TestSpawnTask> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/testSpawnTask',
    await peachApi.get({ url: `/internal/operations/test`, ...args }),
  )

export const chargebackReportPost: RequestFunction<Types.ChargebackReportPost> = async ({ ...args }) =>
  validate(
    'http://peachfinance.com/schemas/chargebackReportPost',
    await peachApi.post({ url: `/sendgrid/webhooks/chargeback-report`, ...args }),
  )

export const internalOperationStatusGet: RequestFunction<Types.InternalOperationStatusGet> = async ({
  operationId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalOperationStatusGet',
    await peachApi.get({ url: `/internal/operations/${operationId}`, ...args }),
  )

export const getMissingSnapshots: RequestFunction<Types.GetMissingSnapshots> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/getMissingSnapshots',
    await peachApi.get({ url: `/companies/${companyId}/snapshots/missing`, ...args }),
  )

export const internalGetPaymentProcessors: RequestFunction<Types.InternalGetPaymentProcessors> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetPaymentProcessors',
    await peachApi.get({ url: `/companies/${companyId}/payment-processors`, ...args }),
  )

export const internalCreatePaymentProcessor: RequestFunction<Types.InternalCreatePaymentProcessor> = async ({
  companyId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCreatePaymentProcessor',
    await peachApi.post({ url: `/companies/${companyId}/payment-processors`, ...args }),
  )

export const internalGetOnePaymentProcessor: RequestFunction<Types.InternalGetOnePaymentProcessor> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetOnePaymentProcessor',
    await peachApi.get({ url: `/companies/${companyId}/payment-processors/${paymentProcessorId}`, ...args }),
  )

export const internalUpdatePaymentProcessor: RequestFunction<Types.InternalUpdatePaymentProcessor> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUpdatePaymentProcessor',
    await peachApi.put({ url: `/companies/${companyId}/payment-processors/${paymentProcessorId}`, ...args }),
  )

export const internalDeletePaymentProcessor: RequestFunction<Types.InternalDeletePaymentProcessor> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalDeletePaymentProcessor',
    await peachApi.delete({ url: `/companies/${companyId}/payment-processors/${paymentProcessorId}`, ...args }),
  )

export const internalGetAchFiles: RequestFunction<Types.InternalGetAchFiles> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetAchFiles',
    await peachApi.get({ url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/ach-files`, ...args }),
  )

export const internalCreateAchFile: RequestFunction<Types.InternalCreateAchFile> = async ({
  companyId,
  paymentProcessorId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalCreateAchFile',
    await peachApi.post({ url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/ach-files`, ...args }),
  )

export const internalGetOneAchFile: RequestFunction<Types.InternalGetOneAchFile> = async ({
  companyId,
  paymentProcessorId,
  achFileId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalGetOneAchFile',
    await peachApi.get({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/ach-files/${achFileId}`,
      ...args,
    }),
  )

export const internalUpdateAchFile: RequestFunction<Types.InternalUpdateAchFile> = async ({
  companyId,
  paymentProcessorId,
  achFileId,
  ...args
}) =>
  validate(
    'http://peachfinance.com/schemas/internalUpdateAchFile',
    await peachApi.put({
      url: `/companies/${companyId}/payment-processors/${paymentProcessorId}/ach-files/${achFileId}`,
      ...args,
    }),
  )

export const getLoanData: RequestFunction<Types.GetLoanData> = async ({ companyId, loanId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/getLoanData',
    await peachApi.get({ url: `/companies/${companyId}/loans/${loanId}/loan-ingest`, ...args }),
  )

export const getSsoToken: RequestFunction<Types.GetSsoToken> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/getSsoToken', await peachApi.get({ url: `/zendesk/sso-token`, ...args }))

export const setSsoSharedSecret: RequestFunction<Types.SetSsoSharedSecret> = async ({ companyId, ...args }) =>
  validate(
    'http://peachfinance.com/schemas/setSsoSharedSecret',
    await peachApi.post({ url: `/companies/${companyId}/zendesk/sso-shared-secret`, ...args }),
  )

export const publishMessage: RequestFunction<Types.PublishMessage> = async ({ ...args }) =>
  validate('http://peachfinance.com/schemas/publishMessage', await peachApi.post({ url: `/publish-message`, ...args }))
