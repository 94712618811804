import { FC, PropsWithChildren } from 'react'

import isPropValid from '@emotion/is-prop-valid'
import DraftContextProvider from 'contexts/DraftContext'
import FileUploadContextProvider from 'contexts/FileUploadContext/provider'
import { LDProvider } from 'launchdarkly-react-client-sdk'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ShouldForwardProp, ThemeProvider as StyledThemeProvider, StyleSheetManager } from 'styled-components'

import store from 'core/store'
import { useErrorLoggingScope } from 'core/system/useErrorLoggingScope'
import { TimeProvider } from 'core/time'
import { FrontendPreauthBootstrap } from 'core/types'

import { CompanyIdProvider } from './CompanyIdProvider'
import { formatTheme } from './customizationsHelpers'
import { CustomizationsProvider } from './CustomizationsProvider'
import useActivityRenewal from './session/useActivityRenewal'
import { UserTypeProvider } from './UserTypeProvider'

// styled-components v6 forwards all props by default. This resores the default behaviour of v5. Moving forward we
// should use transient props (see https://styled-components.com/docs/api#transient-props)
const shouldForwardProp: ShouldForwardProp<'web'> = (propName, target) =>
  typeof target !== 'string' || isPropValid(propName)

type SystemProvidersProps = PropsWithChildren<{
  appDomainType: 'agent' | 'borrower' | 'admin'
  userType: 'agent' | 'borrower'
  timezone: string
  companyId: string
  company: FrontendPreauthBootstrap['company']
}>

const SystemProviders: FC<SystemProvidersProps> = ({
  appDomainType,
  userType,
  companyId,
  timezone,
  company,
  children,
}) => {
  const defaultTheme = formatTheme({})

  useErrorLoggingScope({
    company,
    companyId,
    appDomainType,
    userType,
    timezone,
  })

  useActivityRenewal()

  return (
    <LDProvider
      clientSideID={import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID}
      context={{
        key: companyId,
      }}
    >
      <BrowserRouter>
        <ReduxProvider store={store}>
          <CompanyIdProvider companyId={companyId}>
            <UserTypeProvider userType={userType}>
              <StyleSheetManager shouldForwardProp={shouldForwardProp}>
                <TimeProvider timezone={timezone}>
                  <CustomizationsProvider company={company}>
                    <StyledThemeProvider theme={defaultTheme}>
                      <DraftContextProvider>
                        <FileUploadContextProvider>{children}</FileUploadContextProvider>
                      </DraftContextProvider>
                    </StyledThemeProvider>
                  </CustomizationsProvider>
                </TimeProvider>
              </StyleSheetManager>
            </UserTypeProvider>
          </CompanyIdProvider>
        </ReduxProvider>
      </BrowserRouter>
    </LDProvider>
  )
}

export default SystemProviders
