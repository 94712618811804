/**
 * Mock associations example ----
 * There is no mock file for associations because most
 * individual cases will not have all of these types of
 * associations. This is here more as a reference of what
 * options you can pass into the mock case generator.
 *
 * associations: [
 *  { objectId: 'DD-1', objectType: 'document' },
 *  { objectId: 'DD-2', objectType: 'document' },
 *  { objectId: 'LN-1', objectType: 'loan' },
 *  { objectId: 'LN-2', objectType: 'loan' },
 *  { objectId: 'IN-1', objectType: 'interaction' },
 *  { objectId: 'IN-2', objectType: 'interaction' },
 *  { objectId: 'CS-3', objectType: 'case' },
 *  { objectId: 'CS-4', objectType: 'case' },
 *  { objectId: 'TX-1', objectType: 'transaction' },
 *  { objectId: 'TX-2', objectType: 'transaction' }
 * ]
 */
const mockBaseCase = {
  associations: [],
  attemptsToContactByPhone: {},
  autoCreated: false,
  caseType: 'collection',
  caseTypeMeta: {},
  companyId: 'CP-1',
  createdAt: '2020-09-29T20:21:03.071599+00:00',
  createdByUserId: 'UR-LOKZ-YBV8',
  customFields: {},
  daysOverdue: 0,
  deletedAt: null,
  escalatedAt: null,
  escalatedEmployeeId: null,
  escalatedTeamId: null,
  escalationIsUrgent: null,
  externalId: null,
  id: 'CS-1',
  isFirstTimeCollection: false,
  lastSuccessfulPayment: null,
  lenderAck: false,
  loansReportedToBureaus: 'yes',
  matchToDataSource: false,
  metaData: {},
  name: 'Collection',
  nextPayment: null,
  numberOfLoansOverdueOrAccelerated: 0,
  object: 'case',
  outcome: null,
  outcomeUpdatedAt: '2020-09-29T20:21:03.091200+00:00',
  outcomeUpdatedBy: {
    id: 'UR-01',
    name: 'Bobby Newport',
    descriptor: 'Bobby Newport',
    userType: 'agent',
  },
  paymentCounts: { failed: 0, total: 0 },
  people: ['BO-1'],
  promiseToPayAmount: null,
  promiseToPayDate: null,
  promiseToPayKept: null,
  snoozedAt: null,
  snoozedUntil: null,
  status: 'initiated',
  statusUpdatedAt: null,
  totalAmounts: { expected: 0, overdue: 0, paid: 0 },
  typeId: 'CE-6OB5-XJ2L',
  updatedAt: '2020-09-29T20:21:03.091200+00:00',
  workflowId: null,
}

export default mockBaseCase
