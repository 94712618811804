import * as R from 'remeda'

import {
  CaseCreationProcedure,
  InteractionChannel,
  InteractionStatus,
  InteractionStatusDetails,
  InteractionTheme,
  ParameterStatusDetails,
} from 'core/types'

type Theme = Exclude<InteractionTheme, null>

type Themes = { [k in Theme]?: string }

export const interactionThemes: Themes = {
  inbBug: 'Bug',
  inbEducation: 'Education',
  inbHumanLove: 'Human love',
  inbMissingFeature: 'Missing feature',
  inbOther: 'Other',
  inbRequest: 'Request',
  inbUnknown: 'Unknown',
  opsAccountCredentials: 'Account credentials',
  opsCollContactEmployerNotice: 'Collections: Intention to contact employer notice',
  opsCollDebt: 'Collections: General',
  opsCollLocateBorrower: 'Collections: Locate borrower for collections',
  opsCollVerifyEmployment: 'Collections: Verify borrower employment',
  opsServicing: 'Servicing: General',
  opsServicingDebtValidation: 'Servicing: Debt Validation',
  opsServicingNegativeCreditReportNotice: 'Negative credit report notice',
  opsServicingTimeBarredNotice: 'Time barred notice',
}

export const interactionThemesOptions = R.pipe(
  interactionThemes,
  R.keys,
  R.map((key) => ({ label: interactionThemes[key], value: key })),
)

const outboundInteractionReasons: Array<Theme> = [
  'opsServicing',
  'opsServicingDebtValidation',
  'opsCollDebt',
  'opsCollLocateBorrower',
  'opsCollContactEmployerNotice',
  'opsCollVerifyEmployment',
]
export const outboundInteractionReasonOptions = outboundInteractionReasons.map((value) => ({
  label: interactionThemes[value] ?? value,
  value,
}))

const inboundInteractionReasons: Array<Theme> = [
  'inbMissingFeature',
  'inbEducation',
  'inbBug',
  'inbRequest',
  'inbHumanLove',
  'inbUnknown',
  'inbOther',
]
export const inboundInteractionReasonOptions = inboundInteractionReasons.map((value) => ({
  label: interactionThemes[value] ?? value,
  value,
}))

export const inboundInteractionReasonOptionsForBorrower = inboundInteractionReasonOptions.filter(
  (option) => option.value !== 'inbUnknown',
)

export const creationProcedures: Record<CaseCreationProcedure, string> = {
  automatedCollectionsDetection: 'Automated Collections',
  automatedOfacDetection: 'Compliance Guard',
  automatedBankruptcyDetection: 'Compliance Guard',
  automatedDeceasedDetection: 'Compliance Guard',
  automatedScraDetection: 'Compliance Guard',
  supercasePopulationCommit: 'Supercase',
}

export const missedCallStatusDetails: ParameterStatusDetails = [
  'voiceDisconnectedWhileQueuedOnHold',
  'voiceLenderDisabledInboundCalls',
  'voiceReceivedNoAgentsAvailable',
  'voiceReceivedOutsideOpenHours',
]

export const interactionStatusDetails: { [k in Exclude<InteractionStatusDetails, null>]?: string } = {
  blockedByComplianceGuard: 'Blocked by Compliance Guard',
  chatBorrowerInactivity: 'Chat Borrower Inactivity',
  chatPosted: 'Chat Posted',
  chatRead: 'Chat Read',
  chatReceivedOutsideOpenHours: 'Chat Received Outside Open Hours',
  emailBlocked: 'Email Blocked',
  emailBounced: 'Email Bounced',
  emailClick: 'Email Click',
  emailDelivered: 'Email Delivered',
  emailDropped: 'Email Dropped',
  emailFailedToSend: 'Email Failed to Send',
  emailOpened: 'Email Opened',
  emailReportedAsSpam: 'Email Reported as Spam',
  emailSent: 'Email Sent',
  invalidContact: 'Invalid Contact',
  letterCreated: 'Letter Created',
  letterDeleted: 'Letter Deleted',
  letterDelivered: 'Letter Delivered',
  letterFailedToCreate: 'Letter Failed To Create',
  letterInLocalArea: 'Letter In Local Area',
  letterInTransit: 'Letter In Transit',
  letterProcessedForDelivery: 'Letter Processed For Delivery',
  letterRenderedPdf: 'Letter Rendered Pdf',
  letterRenderedThumbnails: 'Letter Rendered Thumbnails',
  letterRerouted: 'Letter Rerouted',
  letterReturnedToSender: 'Letter Returned To Sender',
  textA2PCampaignUnregistered: 'Text A2P Campaign Unregistered',
  textAccountSuspended: 'Text Account Suspended',
  textCarrierViolation: 'Text Carrier Violation',
  textConvoClosedByAgent: 'Text Convo Closed By Agent',
  textConvoClosedStale: 'Text Convo Closed Stale',
  textConvoTimeoutAutoclose: 'Text Convo Timeout Autoclose',
  textConvoUnknownNumberRejected: 'Text Convo Unknown Number Rejected',
  textDeviceUnreachable: 'Text Device Unreachable',
  textLandlineOrUnreachableCarrier: 'Text Landline Or Unreachable Carrier',
  textLenderDisabledInboundTexts: 'Text Lender Disabled Inbound Texts',
  textMessageBlocked: 'Text Message Blocked',
  textMessagePriceExceedsMax: 'Text Message Price Exceeds Max',
  textMissingSegment: 'Text Missing Segment',
  textNumberDisconnected: 'Text Number Disconnected',
  textQueueOverflow: 'Text Queue Overflow',
  textRegionInvalid: 'Text Region Invalid',
  textUnknownError: 'Text Unknown Error',
  textUnsubscribedRecipient: 'Text Unsubscribed Recipient',
  voiceConnected: 'Voice Connected',
  voiceDisconnectedWhileQueuedOnHold: 'Voice Disconnected While Queued On Hold',
  voiceFailedToInitiate: 'Voice Failed To Initiate',
  voiceLeftVoicemail: 'Voice Left Voicemail',
  voiceLeftVoicemailFirstParty: 'Voice Left Voicemail First Party',
  voiceLeftVoicemailThirdParty: 'Voice Left Voicemail Third Party',
  voiceLenderDisabledInboundCalls: 'Voice Lender Disabled Inbound Calls',
  voiceLineBusyNoRing: 'Voice Line Busy No Ring',
  voiceNumberDisconnected: 'Voice Number Disconnected',
  voiceNumberInvalid: 'Voice Number Invalid',
  voicePickedHungUp: 'Voice Picked Hung Up',
  voiceRangNoPickup: 'Voice Rang No Pickup',
  voiceReachedVoicemailNoMessageLeft: 'Voice Reached Voicemail No Message Left',
  voiceReceivedNoAgentsAvailable: 'Voice Received No Agents Available',
  voiceReceivedOutsideOpenHours: 'Voice Received Outside Open Hours',
  voiceSpokeWithFirstParty: 'Voice Spoke With First Party',
  voiceSpokeWithThirdParty: 'Voice Spoke With Third Party',
  voiceUnexpectedDisconnect: 'Voice Unexpected Disconnect',
}

export const interactionStatusDetailsOptions = R.pipe(
  interactionStatusDetails,
  R.keys,
  R.map((key) => ({ label: interactionStatusDetails[key], value: key })),
)

type Channel = Exclude<InteractionChannel, null | 'fax' | 'gui' | 'document'>

type Channels = { [k in Channel]?: string }

export const interactionChannels: Channels = {
  voice: 'Voice',
  email: 'Email',
  text: 'Text',
  chat: 'Chat',
  mail: 'Mail',
}

export const interactionChannelsOptions = R.pipe(
  interactionChannels,
  R.keys,
  R.map((key) => ({ label: interactionChannels[key], value: key })),
)

type Status = Exclude<InteractionStatus, null | 'fax' | 'gui' | 'document'>

type Statuses = { [k in Status]?: string }

export const interactionStatuses: Statuses = {
  attempted: 'Attempted',
  succeeded: 'Succeeded',
  failed: 'Failed',
  scheduled: 'Scheduled',
  canceled: 'Canceled',
  inProgress: 'In Progress',
}

export const interactionStatusesOptions = R.pipe(
  interactionStatuses,
  R.keys,
  R.map((key) => ({ label: interactionStatuses[key], value: key })),
)
