import SparklesIcon from 'core/components/Icon/internal/custom/SparklesIcon'

import AmexIcon from './internal/custom/AmexIcon'
import DiscoverIcon from './internal/custom/DiscoverIcon'
import MastercardIcon from './internal/custom/MastercardIcon'
import VisaIcon from './internal/custom/VisaIcon'
import MaterialIconRounded from './internal/MaterialIconRounded'

/**
 * Rules for adding new icons to this library:
 *
 * Do not add any new icons to this registry without first obtaining
 * approval from the design team. This way they can control what icons
 * we want to consider as our standard, approved set.
 *
 * Keep the list alphabetized.
 *
 * If adding an icon available in the Material UI icons library, our
 * go-to theme is "rounded". For rounded, set the value for the icon
 * in `names` to the component `MaterialIconRounded`, and make sure
 * the name is exactly the name of the icon as shown in the library:
 * https://material.io/resources/icons/?style=round
 * (Note that it uses snake_case for its naming, e.g. `3d_rotation`.)
 *
 * If making a custom icon, wrap with SvgIcon and store in ./internal,
 * then import here and add to `names`.
 *
 * If at any point we need to use a new library that is not Material UI,
 * just make another component kind of like `MaterialIconRounded` to use
 * for that library, where the props interface can be any subset of the
 * props of `Icon`, i.e. 'name', 'fontSize', 'color', and 'margin'.
 * However, it will most likely not need color or margin, and maybe not
 * fontSize, since those get set on the CSS of `Container` here, which
 * wraps it.
 */

const iconDefinitions = {
  ac_unit: MaterialIconRounded,
  account_balance: MaterialIconRounded,
  account_circle: MaterialIconRounded,
  add_circle: MaterialIconRounded, // identical to mui's control_point icon
  add_ic_call: MaterialIconRounded,
  add: MaterialIconRounded,
  alarm_add: MaterialIconRounded,
  alarm: MaterialIconRounded,
  alternate_email: MaterialIconRounded,
  americanExpress: AmexIcon,
  announcement: MaterialIconRounded,
  arrow_back_ios: MaterialIconRounded, // chevron that is bigger by filling the space more than keyboard_arrow_left
  arrow_back: MaterialIconRounded,
  arrow_drop_down: MaterialIconRounded,
  arrow_drop_up: MaterialIconRounded,
  arrow_forward_ios: MaterialIconRounded, // chevron that is bigger by filling the space more than keyboard_arrow_right
  arrow_upward: MaterialIconRounded,
  article: MaterialIconRounded,
  assignment: MaterialIconRounded,
  attach_file: MaterialIconRounded,
  block: MaterialIconRounded,
  bolt: MaterialIconRounded,
  business: MaterialIconRounded,
  cached: MaterialIconRounded,
  calendar_today: MaterialIconRounded,
  call_end: MaterialIconRounded,
  call_made: MaterialIconRounded,
  camera_alt: MaterialIconRounded,
  check_box: MaterialIconRounded,
  check_circle: MaterialIconRounded,
  check: MaterialIconRounded,
  clear: MaterialIconRounded, // identical to mui's close icon
  close_fullscreen: MaterialIconRounded,
  content_copy: MaterialIconRounded,
  create: MaterialIconRounded,
  credit_card: MaterialIconRounded,
  date_range: MaterialIconRounded,
  delete_forever: MaterialIconRounded,
  description: MaterialIconRounded,
  discover: DiscoverIcon,
  dns: MaterialIconRounded,
  download: MaterialIconRounded,
  email: MaterialIconRounded, // identical to mui's mail icon
  event: MaterialIconRounded, // identical to mui's insert_invitation icon
  exit_to_app: MaterialIconRounded,
  expand: MaterialIconRounded,
  face: MaterialIconRounded,
  fast_forward: MaterialIconRounded,
  fiber_manual_record: MaterialIconRounded,
  filter_list: MaterialIconRounded,
  flag: MaterialIconRounded,
  gavel: MaterialIconRounded,
  help: MaterialIconRounded,
  highlight_off: MaterialIconRounded,
  history: MaterialIconRounded,
  home: MaterialIconRounded,
  hourglass_empty: MaterialIconRounded,
  how_to_vote: MaterialIconRounded,
  image_not_supported: MaterialIconRounded,
  info: MaterialIconRounded,
  keyboard_arrow_down: MaterialIconRounded, // identical to mui's expand_more icon
  keyboard_arrow_left: MaterialIconRounded, // identical to mui's chevron_left icon
  keyboard_arrow_right: MaterialIconRounded, // identical to mui's chevron_right icon
  keyboard_arrow_up: MaterialIconRounded, // identical to mui's expand_less icon
  launch: MaterialIconRounded,
  link: MaterialIconRounded,
  lock_open: MaterialIconRounded,
  lock: MaterialIconRounded,
  mail: MaterialIconRounded,
  markunread_mailbox: MaterialIconRounded,
  mastercard: MastercardIcon,
  maximize: MaterialIconRounded,
  mic_off: MaterialIconRounded,
  mic: MaterialIconRounded,
  minimize: MaterialIconRounded,
  mobile_screen_share: MaterialIconRounded,
  monetization_on: MaterialIconRounded,
  north_east: MaterialIconRounded,
  notification_important: MaterialIconRounded,
  open_in_full: MaterialIconRounded,
  open_in_new: MaterialIconRounded,
  pause: MaterialIconRounded,
  payments: MaterialIconRounded,
  people: MaterialIconRounded,
  person: MaterialIconRounded,
  phone_callback: MaterialIconRounded,
  phone_in_talk: MaterialIconRounded,
  phone_missed: MaterialIconRounded,
  phone_paused: MaterialIconRounded,
  phone: MaterialIconRounded, // identical to mui's call icon
  play_arrow: MaterialIconRounded,
  portrait: MaterialIconRounded,
  preview: MaterialIconRounded,
  priority_high: MaterialIconRounded,
  push_pin: MaterialIconRounded,
  question_answer: MaterialIconRounded,
  redo: MaterialIconRounded,
  reduce: MaterialIconRounded,
  refresh: MaterialIconRounded,
  remove_circle: MaterialIconRounded,
  remove: MaterialIconRounded,
  replay: MaterialIconRounded,
  reply_all: MaterialIconRounded,
  reply: MaterialIconRounded,
  report_gmailerrorred: MaterialIconRounded,
  report: MaterialIconRounded,
  request_page: MaterialIconRounded,
  schedule: MaterialIconRounded, // identical to mui's query_builder, also what you'd probably call "clock"
  screen_share: MaterialIconRounded,
  search: MaterialIconRounded,
  settings: MaterialIconRounded,
  smartphone: MaterialIconRounded,
  snooze: MaterialIconRounded,
  star: MaterialIconRounded, // identical to mui's star_rate icon
  sparkles: SparklesIcon,
  supervisor_account: MaterialIconRounded,
  sync: MaterialIconRounded,
  text_format: MaterialIconRounded,
  thumb_down: MaterialIconRounded,
  thumb_up: MaterialIconRounded,
  trending_down: MaterialIconRounded,
  undo: MaterialIconRounded,
  unfold_less: MaterialIconRounded,
  unfold_more: MaterialIconRounded,
  upload: MaterialIconRounded,
  vertical_align_bottom: MaterialIconRounded,
  visa: VisaIcon,
  visibility_off: MaterialIconRounded,
  visibility: MaterialIconRounded,
  voicemail: MaterialIconRounded,
  warning: MaterialIconRounded,
  waves: MaterialIconRounded,
  work: MaterialIconRounded,
}

export default iconDefinitions

export type IconName = keyof typeof iconDefinitions
