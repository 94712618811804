const mockMailContent = {
  content: 'base64_string',
  from_address: {
    addressLine1: '1333 Broadway',
    addressLine2: 'Suite 1016',
    city: 'Oakland',
    postalCode: '94612',
    state: 'CA',
  },
  from_name: 'PeachyLoans',
  num_pages: 1,
  to_address: {
    POBox: null,
    addressLine1: '490 43rd St',
    addressLine2: '',
    city: 'Oakland',
    country: 'US',
    countyOrRegion: '',
    postalCode: '94609',
    state: 'CA',
  },
  to_name: {
    createdAt: '2020-09-22T22:35:44.466835+00:00',
    current: true,
    deletedAt: null,
    effectiveAt: null,
    firstName: 'Bob',
    id: 'BO-1',
    lastName: 'Borrower',
    maidenLastName: null,
    middleName: 'Bubs',
    object: 'name',
    originalValue: {},
    preferredFirstName: null,
    prefix: null,
    source: 'lender',
    status: 'active',
    suffix: null,
    updatedAt: null,
  },
}

export default mockMailContent
