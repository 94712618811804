import { DefaultBodyType, PathParams, ResponseResolver, RestContext, RestRequest } from 'msw'

import { generateMockInteraction } from 'core/hooks/__mocks__/internal/interactions'

import { mswResponseHandler } from '../helpers/mswResponseHandler'

const channels = ['chat', 'email', 'mail', 'text', 'voice']

const contentForChannel = (channel: (typeof channels)[number], contentContains?: string) => {
  const content = contentContains || 'Dummy content'
  switch (channel) {
    case 'chat':
      return { messages: [{ body: content }] }
    case 'email':
    case 'mail':
      return { content: [{ type: 'text/plain', value: content }] }
    case 'text':
    case 'voice':
      return { body: content }
    default:
      // eslint-disable-next-line no-console
      console.warn('Unhandled channel in interactions API mock')
      return {}
  }
}

// TODO: We should include every possible combination of status + statusDetails for every channel
const statusForChannel = (channel: (typeof channels)[number]) => {
  if (channel === 'email') {
    return 'failed'
  }
  return 'succeeded'
}

const statusDetailsForChannel = (channel: (typeof channels)[number]) => {
  if (channel === 'email') {
    return 'emailReportedAsSpam'
  }
  return null
}

const interactionPayload = (caseIds: string[], channel: (typeof channels)[number], contentContains: string | null) => {
  const associatedCaseIds = caseIds || []
  return {
    channel,
    ...(associatedCaseIds.length ?
      {
        associations: associatedCaseIds.map((caseId) => [
          { relation: 'related', objectType: 'case', objectId: caseId },
        ]),
      }
    : {}),
    ...(contentContains ? { content: contentForChannel(channel, contentContains) } : {}),
    status: statusForChannel(channel),
    statusDetails: statusDetailsForChannel(channel),
  }
}

export const resolveGetInteraction: ResponseResolver<
  RestRequest<never, PathParams<string>>,
  RestContext,
  DefaultBodyType
> = (_req, res, ctx) => {
  // This is just enough to test the current feature I need
  // This should be built out to support different channels if needed
  const mockInteraction = generateMockInteraction(interactionPayload([], 'email', null))
  return mswResponseHandler({
    ctx,
    res,
    id: 'getInteraction',
    data: mockInteraction,
  })
}

export const resolveGetInteractions: ResponseResolver<
  RestRequest<never, PathParams<string>>,
  RestContext,
  DefaultBodyType
> = (req, res, ctx) => {
  const caseIds = req.url.searchParams.get('caseIds')?.split(',') ?? []
  const contentContains = req.url.searchParams.get('contentContains')

  const interactionCount = caseIds.length || 50
  const mockInteractions = [...new Array(interactionCount)].map((_val, i) =>
    generateMockInteraction(interactionPayload(caseIds, channels[i % channels.length], contentContains)),
  )

  return mswResponseHandler({
    ctx,
    res,
    id: 'getInteractions',
    data: mockInteractions,
  })
}
