import _ from 'lodash'
import { mswEndpoints } from 'test/msw/handlers'

import { PeachOverrideSelect } from 'core/components/PeachOverrides/lib/Override'

const apiMocksOptions = {
  enableApiMocking: {
    id: 'enableApiMocking',
    section: 'apiMockOptions',
    type: 'toggle',
    value: false,
    requiresReload: true,
  },
  responseDelayMilliseconds: {
    id: 'responseDelayMilliseconds',
    section: 'apiMockOptions',
    type: 'text',
    value: '',
    placeholder: '0',
    requiresReload: true,
  },
  ...generateEndpointSelects(),
}

export default apiMocksOptions

function generateEndpointSelects() {
  return _.mapValues<typeof mswEndpoints, PeachOverrideSelect>(mswEndpoints, (value, key) => ({
    id: key,
    section: 'apiMockEndpoints',
    type: 'select',
    value: 'disabled',
    options: ['disabled', 'success', 'forbidden', 'badRequest', 'internalServerError', 'serviceUnavailable', 'timeout'],
    requiresReload: true,
  }))
}
