import _ from 'lodash'

import mockAttemptsToContactByPhone from './mockAttemptsToContactByPhone'
import mockBaseCase from './mockBaseCase'
import mockCaseTypeMeta from './mockCaseTypeMeta'

const generateMockCaseTypeMeta = (meta) => {
  return { ...mockCaseTypeMeta, ...meta }
}

const generateAttemptsToContactByPhone = (attempts) => {
  return { ...mockAttemptsToContactByPhone, ...attempts }
}

const generateMockCase = (options = {}) => {
  const { associations } = mockBaseCase
  const id = options.id || _.uniqueId('CS-AUTO-')
  const caseTypeMeta = generateMockCaseTypeMeta(options.caseTypeMeta)
  const attemptsToContactByPhone = generateAttemptsToContactByPhone(options.attemptsToContactByPhone)
  let newAssociations
  if (options.associations) {
    newAssociations = [...associations, ...options.associations]
  } else {
    newAssociations = associations
  }

  return {
    ...mockBaseCase,
    ...options,
    id,
    associations: newAssociations,
    caseTypeMeta,
    attemptsToContactByPhone,
  }
}

export const generateMockCreditBureauDisputeCase = (options = {}) => {
  const _options = {
    caseType: 'creditBureauDispute',
    responseDueDate: '2020-01-01',
    disputeReceivedDate: '2020-01-02',
    disputeCode1: 'notLiable',
    disputeCode2: 'other',
    fcraInfo: 'This is the FCRA info. I lack creativity.',
    responseCode: 'informationAccurateAsOfReportDate',
    /** Let caller override any options, including the special
     * ones that are set here. */
    ...options,
  }

  return generateMockCase(_options)
}

export const generateMockDisputeOfCreditBureauCase = (options = {}) => {
  const _options = {
    caseType: 'disputeOfCreditBureau',
    responseDueDate: '2020-01-01',
    disputeReceivedDate: '2020-01-02',
    disputeCode1: 'notLiable',
    disputeCode2: 'other',
    fcraInfo: 'This is the FCRA info. I lack creativity.',
    responseCode: 'informationAccurateAsOfReportDate',
    /** Let caller override any options, including the special
     * ones that are set here. */
    ...options,
  }

  return generateMockCase(_options)
}

export const generateMockOfacCase = (options = {}) => {
  const _options = {
    caseType: 'oFACSanctionedIndividual',
    matchToDataSource: true,
    sdnAddressList: [
      {
        address: '123 Money St, Springfield OH',
        cityStateProvincePostalCode: '11111',
        country: 'United States',
      },
      {
        address: '',
        cityStateProvincePostalCode: '12345',
        country: 'United States',
      },
      {
        address: 'Oakland CA',
        cityStateProvincePostalCode: '99999',
        country: '',
      },
    ],
    sdnAkaList: ['SMITH, John', 'PALMER, David', 'SEINFELD, Jerry'],
    sdnDateOfBirthList: ['10 Dec 1948', '1959'],
    sdnName: {
      firstName: 'Corbin',
      lastName: 'Dallas',
    },
    sdnNationalityList: ['United States', 'Canada'],
    sdnPlaceOfBirthList: ['United States', 'Cuba'],
    sdnProgramList: ['SDNTK', 'SDGT'],
    sdnRemarks:
      'DOB 17 May 1960; Cedula No. 16646582 (Colombia); Linked To: SERVIAUTOS UNO A 1A LIMITADA; Linked To: INVERSIONES EL PENON S.A.; Linked To: CONSTRUVIDA S.A.; Linked To: IMPORTADORA Y COMERCIALIZADORA LTDA.; Linked To: CONSTRUCTORA DIMISA LTDA.; Linked To: PROCESADORA DE POLLOS SUPERIOR S.A.; Linked To: CRIADERO DE POLLOS EL ROSAL S.A.',
    /** Let caller override any options, including the special
     * ones that are set here for ofac. */
    ...options,
  }

  return generateMockCase(_options)
}

export default generateMockCase
