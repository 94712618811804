import { DropdownIndicatorProps, GroupBase, OptionProps, components } from 'react-select'
import styled from 'styled-components'

import Icon, { IconProps } from 'core/components/Icon/Icon'
import { variables } from 'core/styles'

export type SelectOption<T = string> = {
  label: string | JSX.Element
  sublabel?: string | JSX.Element
  value: T
  icon?: IconProps['name']
  isDisabled?: boolean
}

export const DropdownIndicator = <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <Icon name='arrow_drop_down' color={variables.colorBlack90} fontSize='24px' />
  </components.DropdownIndicator>
)

export const Option = <Value, IsMulti extends boolean, Group extends GroupBase<SelectOption<Value>>>(
  props: OptionProps<SelectOption<Value>, IsMulti, Group>,
) => (
  <components.Option {...props}>
    {props.data.icon && <Icon name={props.data.icon} fontSize='24px' margin='0 8px 0 0' />}
    <div>
      {props.data.label}
      {props.data.sublabel && <Sublabel>{props.data.sublabel}</Sublabel>}
    </div>
  </components.Option>
)

const Sublabel = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${variables.colorBlack60};
  text-transform: capitalize;
`
