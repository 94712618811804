import { DefaultBodyType, PathParams, ResponseResolver, RestContext, RestRequest } from 'msw'

import { mswResponseHandler } from '../helpers/mswResponseHandler'

export const resolveGetZendeskConfig: ResponseResolver<
  RestRequest<any, PathParams<string>>,
  RestContext,
  DefaultBodyType
> = (req, res, ctx) => {
  if (req.url.searchParams.get('paths') === 'zendesk') {
    return mswResponseHandler({
      ctx,
      res,
      id: 'getZendeskConfig',
      data: {},
    })
  }

  return req.passthrough()
}
