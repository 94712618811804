import _ from 'lodash'

import { Permission } from 'core/types'

import usePermissions from './usePermissions'

const useHasPermission = (value?: Permission | Permission[]) => {
  const permissionsHash = usePermissions()

  if (value === undefined || value.length === 0) return true

  if (_.isEmpty(permissionsHash)) return undefined // TODO can we just return false here?

  const list = Array.isArray(value) ? value : [value]

  return list.every((key) => permissionsHash[key])
}

export default useHasPermission
