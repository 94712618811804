import { ResponseResolver, RestRequest, PathParams, RestContext, DefaultBodyType } from 'msw'

import { mswResponseHandler } from '../helpers/mswResponseHandler'

export const resolvePutTasktypeAvailability: ResponseResolver<
  RestRequest<any, PathParams<string>>,
  RestContext,
  DefaultBodyType
> = (_req, res, ctx) => {
  const mockResponse = {
    availableByDefault: {
      answerInboundVoiceCall: true,
      handleInboundInteraction: true,
      handleUnboundInboundInteraction: true,
      makeScheduledCall: true,
      replyToAbandonedWebChat: true,
      replyToNewConvo: true,
      replyToNewText: true,
      replyToNewWebChat: true,
      reviewAutoCreatedCase: true,
      reviewAutoUpdatedCase: true,
      reviewCaseInstructions: true,
      reviewDocumentUpload: true,
      reviewEscalatedCaseAgent: true,
      reviewEscalatedCaseAgentUrgent: true,
      reviewEscalatedCaseTeam: true,
      reviewEscalatedCaseTeamUrgent: true,
      reviewSnoozedCase: true,
      reviewVoicemail: true,
    },
    availableByUserConfig: {
      answerInboundVoiceCall: true,
      replyToNewWebChat: true,
    },
    permitted: {
      answerInboundVoiceCall: true,
      handleInboundInteraction: true,
      handleUnboundInboundInteraction: true,
      makeScheduledCall: true,
      replyToAbandonedWebChat: true,
      replyToNewConvo: true,
      replyToNewText: true,
      replyToNewWebChat: true,
      reviewAutoCreatedCase: true,
      reviewAutoUpdatedCase: true,
      reviewCaseInstructions: true,
      reviewDocumentUpload: true,
      reviewEscalatedCaseAgent: true,
      reviewEscalatedCaseAgentUrgent: true,
      reviewEscalatedCaseTeam: true,
      reviewEscalatedCaseTeamUrgent: true,
      reviewSnoozedCase: true,
      reviewVoicemail: true,
    },
  }
  return mswResponseHandler({
    ctx,
    res,
    id: 'putTasktypeAvailability',
    data: mockResponse,
  })
}

export const resolveGetUncompletedTasks: ResponseResolver<
  RestRequest<any, PathParams<string>>,
  RestContext,
  DefaultBodyType
> = (_req, res, ctx) => {
  return mswResponseHandler({
    ctx,
    res,
    id: 'getUncompletedTasks',
    data: [],
  })
}
