// Error import: keep at top
import './polyfill'
import 'core/system/ErrorLogging'
// -------------------------
import 'index.scss'
import 'core/hooks/useLog'
import { datadogLogs } from '@datadog/browser-logs'
import ReactDOM from 'react-dom'

import Badge from 'core/components/Badge/Badge'
import { getOverride } from 'core/components/PeachOverrides/overridesStorage'
import Root from 'core/SystemProvider/Root'

if (import.meta.env.VITE_ENABLE_API_MOCKING && getOverride('enableApiMocking')) {
  import('test/msw/worker').then((lib) => lib.worker.start({ onUnhandledRequest: 'bypass' }))
}

// Disabled on local builds
if (!!import.meta.env.VITE_DATADOG_CLIENT_TOKEN) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    sessionSampleRate: 100,
    env: import.meta.env.VITE_ENV_LABEL ?? 'unknown',
    version: import.meta.env.VITE_SENTRY_RELEASE,
  })
}

ReactDOM.render(<Badge />, document.getElementById('badgeRoot'))

ReactDOM.render(<Root />, document.getElementById('root'))
