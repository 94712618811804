import jwt_decode from 'jwt-decode'

import { PUTwithToken } from 'core/api/legacyApi'
import ErrorLogging from 'core/system/ErrorLogging'

import APIendpoint from './APIendpoint'

const TOKEN = 'secretToken'
const SESSION_EXPIRED = 'sessionExpired'
const SAML_IN_PROGRESS = 'samlInProgress'

interface IDecodedToken {
  exp: number
  personId: string
  userType: string
}

const getStoredToken = () => {
  try {
    const val = localStorage.getItem(TOKEN)
    if (val) {
      return JSON.parse(val) || undefined
    }
    return undefined
  } catch (e) {
    return undefined
  }
}

const setStoredToken = (token: string) => {
  if (token) {
    try {
      localStorage.setItem(TOKEN, JSON.stringify(token))
    } catch (e) {
      localStorage.removeItem(TOKEN)
    }
  } else {
    localStorage.removeItem(TOKEN)
  }
}

const getIsSamlInProgress = () => {
  try {
    const val = localStorage.getItem(SAML_IN_PROGRESS)
    if (val) {
      return val === 'true'
    }
    return false
  } catch (e) {
    return false
  }
}

const setIsSamlInProgress = (isStarted: boolean) => {
  if (isStarted) {
    try {
      localStorage.setItem(SAML_IN_PROGRESS, 'true')
    } catch (e) {
      localStorage.removeItem(SAML_IN_PROGRESS)
    }
  } else {
    localStorage.removeItem(SAML_IN_PROGRESS)
  }
}

const getSessionExpired = () => {
  try {
    const val = localStorage.getItem(SESSION_EXPIRED)
    if (val) {
      return val === 'true'
    }
    return false
  } catch (e) {
    return false
  }
}

const setSessionExpired = (sessionExpired: boolean) => {
  if (sessionExpired) {
    try {
      localStorage.setItem(SESSION_EXPIRED, 'true')
    } catch (e) {
      localStorage.removeItem(SESSION_EXPIRED)
    }
  } else {
    localStorage.removeItem(SESSION_EXPIRED)
  }
}

const setSessionExpiredIfRecent = () => {
  const token = getStoredToken()
  const { exp } = decodeToken(token)
  if (exp > Math.round(Date.now() / 1000) - 12 * 3600) {
    // token expired within the past 12 hours; show session expired message
    setSessionExpired(true)
  }
}

const decodeToken = (token: string) => {
  try {
    const decoded = jwt_decode(token)
    return decoded ? (decoded as IDecodedToken) : ({} as IDecodedToken)
  } catch {
    return {} as IDecodedToken
  }
}

const formatTimestamp = (ts: number) => {
  try {
    return ts ? new Date(ts).toLocaleString('en-US') : '--'
  } catch {
    return ''
  }
}

const logBreadcrumb = (msg: string) => {
  ErrorLogging.addBreadcrumb({ category: 'auth', message: msg })
}

const renewSession = async () => {
  const token = getStoredToken()
  const { exp } = decodeToken(token)
  const endpoint = `${APIendpoint}/auth/token`

  logBreadcrumb(`renewing session, expires ${formatTimestamp(exp * 1000)}`)

  try {
    const resp = await fetch(endpoint, PUTwithToken(token, {}))

    if (resp.ok) {
      const { token: newToken } = await resp.json()
      setStoredToken(newToken)
    } else {
      setSessionExpiredIfRecent()
      setStoredToken('')
      logBreadcrumb('non-200 response from renewal request')
    }
  } catch (err: any) {
    if (getIsSamlInProgress()) {
      // The user is being directed through a SAML flow
      // Do not clear stored session + token
    } else {
      setSessionExpiredIfRecent()
      setStoredToken('')
      logBreadcrumb('error while renewing')
    }
  }

  // Reset SAML flag and allow future renewals to fail
  setIsSamlInProgress(false)
}

const isTokenExpired = (token: string) => {
  return token && decodeToken(token).exp * 1000 <= Date.now()
}

const isTokenValid = (token: string) => {
  const expiresAt = decodeToken(token).exp
  return !!(expiresAt && expiresAt * 1000 >= Date.now())
}

const isLoggedIn = () => isTokenValid(getStoredToken())

export {
  getIsSamlInProgress,
  setIsSamlInProgress,
  getStoredToken,
  setStoredToken,
  getSessionExpired,
  setSessionExpiredIfRecent,
  setSessionExpired,
  renewSession,
  decodeToken,
  isTokenExpired,
  isTokenValid,
  APIendpoint,
  isLoggedIn,
}
