import { FC } from 'react'

import styled from 'styled-components'

import { useNow } from 'core/hooks'
import { useTime } from 'core/time'
import { prettyMap } from 'core/time/internal/utils/timezoneMap'

type CurrentTimestampProps = {
  align?: 'left' | 'center' | 'right'
  margin?: string
}

const CurrentTimestamp: FC<CurrentTimestampProps> = ({ align, margin }) => {
  const { format, getNow } = useTime()

  const now = useNow()
  const timezone = format(getNow(), 'z')

  return (
    <StyledTimeStamp align={align} margin={margin}>
      {`${format(now, 'MMM D, h:mm A')} ${prettyMap[timezone] ?? timezone}`}
    </StyledTimeStamp>
  )
}

export default CurrentTimestamp

export const StyledTimeStamp = styled.span<{ margin?: string; align?: string }>`
  display: block;
  margin: ${(p) => (p.margin ? p.margin : undefined)};
  font-size: 14px;
  font-weight: var(--fontWeight500);
  line-height: 24px;
  color: var(--colorBlack50);
  text-align: ${(p) => (p.align ? p.align : undefined)};
`
