import styled from 'styled-components'

export const OverridesColumn = styled.div`
  div + div {
    padding-top: 6px;
    margin-top: 12px;
    border-top: 1px solid #ccc;
  }

  input + input,
  input + label,
  input + select,
  label + input,
  label + label,
  label + select,
  select + input,
  select + label,
  select + select {
    margin-top: 6px;
  }
`

export const OverridesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 100%;

  > div {
    padding: 0 8px;
  }
`

export const OverridesGridSingle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100%;

  > div {
    padding: 0 8px;
  }
`

export const OverridesSectionTitle = styled.h1`
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
`
