import React, { ChangeEventHandler, Children, ReactChild } from 'react'

import _ from 'lodash'

import Icon from 'core/components/Icon'
import Checkbox from 'core/components/lib/Checkbox'
import SrOnly from 'core/components/lib/SrOnly'
import variables from 'core/styles/variables'

import {
  StyledTable,
  StyledTableButtonWrapper,
  StyledTableCell,
  StyledTableHeaderCell,
  StyledTableHeaderContent,
  StyledTableRow,
  StyledTableValue,
  StyledTableWrapper,
} from './styles'
import { TableLoadingOverlay, TableTextOverlay } from './TableOverlay'

type TableProps<T extends string> = {
  bordered?: boolean
  children: React.ReactNode
  columns?: {
    label: ReactChild
    key?: T
    sortable?: boolean
    toggle?: {
      checked: boolean
      indeterminate: boolean
      onChange: ChangeEventHandler<HTMLInputElement>
      screenReaderLabel: string
    }
  }[]
  entitiesLabel?: string
  errorMessage?: string
  hideHeader?: boolean
  handleSortClick?: (key: T) => void
  isError?: boolean
  isLoading?: boolean
  sortBy?: Array<string>
}

const Table = <T extends string>({
  bordered = false,
  children,
  columns = [],
  entitiesLabel = 'results',
  errorMessage,
  hideHeader = false,
  handleSortClick = () => null,
  isError = false,
  isLoading = false,
  sortBy,
}: TableProps<T>) => {
  const isEmpty = React.Children.count(children) === 0
  const [sortKey] = sortBy ?? []

  return (
    <StyledTableWrapper bordered={bordered}>
      <StyledTable>
        {!hideHeader && (
          <thead>
            <tr>
              {columns?.map(({ key, label, sortable, toggle: { screenReaderLabel, ...toggle } = {} }, index) => {
                const sorted = sortKey === key || sortKey.substring(1) === key
                return (
                  <StyledTableHeaderCell
                    {...(sortable && key ? { onClick: () => handleSortClick(key) } : {})}
                    key={`${key}-${index}`}
                    sorted={sorted}
                    clickable={!!sortable}
                  >
                    <StyledTableHeaderContent>
                      {!_.isEmpty(toggle) && (
                        <Checkbox
                          {...toggle}
                          label={<SrOnly>{screenReaderLabel}</SrOnly>}
                          value='all'
                          margin='-20px 0 0 0'
                        />
                      )}
                      {label}
                      {sortable && (
                        <Icon
                          color={sorted ? variables.colorBlack90 : variables.colorBlack40}
                          name={
                            sorted ?
                              sortKey.startsWith('-') ?
                                'arrow_drop_down'
                              : 'arrow_drop_up'
                            : 'arrow_drop_up'
                          }
                          fontSize='24px'
                          margin='0 8px 0 0'
                        />
                      )}
                    </StyledTableHeaderContent>
                  </StyledTableHeaderCell>
                )
              })}
            </tr>
          </thead>
        )}
        <tbody>
          {isEmpty ?
            <StyledTableRow bordered>
              <StyledTableCell>&nbsp;</StyledTableCell>
            </StyledTableRow>
          : children}
        </tbody>
      </StyledTable>

      {isLoading ?
        <TableLoadingOverlay />
      : isError ?
        <TableTextOverlay>{errorMessage ?? `Error loading ${entitiesLabel}`}</TableTextOverlay>
      : isEmpty ?
        <TableTextOverlay>No {entitiesLabel} found</TableTextOverlay>
      : null}
    </StyledTableWrapper>
  )
}

const TableRow = ({
  bordered = true,
  summary = false,
  onClick,
  children,
}: {
  bordered?: boolean
  summary?: boolean
  onClick?: React.MouseEventHandler<HTMLTableRowElement>
  children: React.ReactNode
}) => (
  <StyledTableRow onClick={onClick} bordered={bordered} $summary={summary}>
    {Children.map(children, (child, index) => (
      <StyledTableCell
        paddingTop={summary ? '12px' : undefined}
        paddingBottom={summary ? '12px' : undefined}
        key={`wrapped-cell-${index}`}
      >
        {child}
      </StyledTableCell>
    ))}
  </StyledTableRow>
)

const TableValueWithEmphasis = ({
  children,
  textAlign = 'left',
}: {
  children: React.ReactNode
  textAlign?: 'left' | 'center' | 'right'
}) => {
  return (
    <StyledTableValue $deemphasized={!children || Number(children) === 0} $textAlign={textAlign}>
      {children ?? '—'}
    </StyledTableValue>
  )
}

Table.Row = TableRow
Table.CellActions = StyledTableButtonWrapper
Table.ValueWithEmphasis = TableValueWithEmphasis

export default Table
