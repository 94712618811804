import { useAppDispatch } from 'core/hooks/useAppStore'

import { ToastAddedPayload, toastAdded } from './toastsSlice'

// Usage: toast.error("Something has gone wrong")
// This should be extended in the future with more toast types as desired:
// * toast.success, toast.warning, toast.loading, etc

export default function useToast() {
  const dispatch = useAppDispatch()

  return {
    error: (message: ToastAddedPayload['message']) => dispatch(toastAdded({ message, type: 'error' })),
    warning: (message: ToastAddedPayload['message']) => dispatch(toastAdded({ message, type: 'warning' })),
  }
}
