import { FC } from 'react'

import styled from 'styled-components'

import { ErrorBox, ErrorMessage, ErrorTitle, FallbackProps } from './defaultErrorBoundaries'

const StatusCode = styled.span`
  font-size: 12px;
  opacity: 0.5;
`

export type ErrorFallbackProps = FallbackProps & {
  error?: any
  isAgent: boolean
  enableSupportMessaging?: boolean
}

const DefaultErrorFallback: FC<ErrorFallbackProps> = ({
  className,
  error,
  isAgent,
  enableSupportMessaging = false,
}) => (
  <ErrorBox className={className}>
    {isAgent && error && 'status' in error && typeof error.status === 'number' && (
      <StatusCode>{error.status}</StatusCode>
    )}
    <ErrorTitle>Something went wrong</ErrorTitle>
    <ErrorMessage>
      Our team has been notified of the issue.
      {enableSupportMessaging && ' If the problem persists, please contact support.'}
    </ErrorMessage>
  </ErrorBox>
)

export default DefaultErrorFallback
