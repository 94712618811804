import { FC } from 'react'

import { ErrorTitle, ErrorMessage, WarningBox, FallbackProps } from './defaultErrorBoundaries'

const DefaultPermissionsErrorFallback: FC<FallbackProps> = ({ className }) => (
  <WarningBox className={className}>
    <ErrorTitle>You do not have permission to view this resource</ErrorTitle>
    <ErrorMessage>Contact your supervisor if this is a mistake.</ErrorMessage>
  </WarningBox>
)

export default DefaultPermissionsErrorFallback
