import styled from 'styled-components'

import { variables } from 'core/styles'

export const StyledTableWrapper = styled.div<{ bordered?: boolean }>`
  position: relative;
  display: inline-block;
  ${(p) =>
    p.bordered &&
    `border-radius: 8px;
  border: 1px solid ${variables.colorBlack20};`}
  max-width: 100%;
  overflow: auto;
`

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const StyledTableRow = styled.tr<{
  bordered: boolean
  $summary?: boolean
  onClick?: React.MouseEventHandler<HTMLTableRowElement>
}>`
  ${(p) => p.bordered && `border-bottom: 1px solid ${variables.colorBlack20};`}
  ${(p) => p.$summary && `background: ${variables.colorBlack10};`}

  &:last-child {
    border-bottom: none;
  }

  ${(p) =>
    p.onClick &&
    `&:hover {
    background-color: ${variables.colorBlack10};
    cursor: pointer;
  }`}
`

export const StyledTableCell = styled.td<{
  paddingTop?: string
  paddingBottom?: string
  textAlign?: 'left' | 'right' | 'center'
}>`
  position: relative;
  width: auto;
  padding: ${(p) => p.paddingTop || '24px'} 24px ${(p) => p.paddingBottom || '24px'};
  text-align: ${(p) => p.textAlign || 'left'};
  vertical-align: top;

  &:first-child {
    border-left: none;
  }
`

export const StyledTableHeaderCell = styled.th<{
  clickable: boolean
  sorted: boolean
}>`
  padding: 8px 24px;
  font-weight: normal;
  color: ${variables.colorBlack90};
  text-align: left;
  border-bottom: 1px solid ${variables.colorBlack20};

  &:first-child {
    border-left: none;
  }

  ${(p) =>
    p.clickable &&
    `&:hover {
    background-color: ${variables.colorBlack10};
    cursor: pointer;
  }`}

  ${(p) => p.sorted && 'font-weight: var(--fontWeight500);'}
`

export const StyledTableHeaderContent = styled.span`
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const StyledTableButtonWrapper = styled.span<{ justifyContent?: string }>`
  display: flex;
  justify-content: ${(p) => p.justifyContent ?? 'flex-end'};
  white-space: nowrap;
`

export const StyledTableValue = styled.span<{
  $deemphasized: boolean
  $textAlign: 'left' | 'center' | 'right'
}>`
  display: block;
  text-align: ${(p) => p.$textAlign};
  white-space: nowrap;
  ${(p) => p.$deemphasized && `color: ${variables.colorBlack50};`};
`
