import { useSelector } from 'react-redux'

import { useCompanyId } from 'core/SystemProvider/CompanyIdProvider'

const defaultValue = {}

const usePermissions = () => {
  const companyId = useCompanyId()

  return useSelector((state) => {
    return state.permissions.hashByCompanyId[companyId] || defaultValue
  })
}

export default usePermissions
