import { FC } from 'react'

import { peachOverridesOptions } from './config'
import { renderOverride } from './lib/Override'
import { OverridesColumn, OverridesGridSingle, OverridesSectionTitle } from './lib/styled'

const TabApiMocks: FC = () => {
  const allOverrides = Object.values(peachOverridesOptions)
  const apiMocksOptions = allOverrides.filter((o) => o.section === 'apiMockOptions')
  const apiMockEndpoints = allOverrides.filter((o) => o.section === 'apiMockEndpoints')

  return (
    <OverridesGridSingle>
      <OverridesColumn>
        <div>
          <OverridesSectionTitle>API Mocks Options</OverridesSectionTitle>
          {apiMocksOptions.map(renderOverride)}
        </div>
        <div>
          <OverridesSectionTitle>Endpoints</OverridesSectionTitle>
          {apiMockEndpoints.map(renderOverride)}
        </div>
      </OverridesColumn>
    </OverridesGridSingle>
  )
}

export default TabApiMocks
