import { ResponseComposition, RestContext } from 'msw'

import { PeachOverrideId } from 'core/components/PeachOverrides/config'
import { getOverride } from 'core/components/PeachOverrides/overridesStorage'

import { mswEndpoints } from '../handlers'

type MswResponseHandler = ({
  id,
  data,
  ctx,
  res,
}: {
  id: keyof typeof mswEndpoints
  data: any
  ctx: RestContext
  res: ResponseComposition<any>
}) => any

export const mswResponseHandler: MswResponseHandler = ({ id, data, ctx, res }) => {
  const mockStatusCode = getMswStatusCode(id)
  const responseDelayMilliseconds = getMswResponseDelay()
  return res(
    ctx.delay(responseDelayMilliseconds),
    ctx.status(mockStatusCode),
    ctx.json({
      ...(mockStatusCode === 200 ? { data, status: 200 } : { message: `Mock ${mockStatusCode} response` }),
    }),
  )
}

const getMswStatusCode = (id: keyof typeof mswEndpoints) => {
  const responseType = getOverride(id as PeachOverrideId)
  switch (responseType) {
    case 'badRequest':
      return 400
    case 'forbidden':
      return 403
    case 'internalServerError':
      return 500
    case 'serviceUnavailable':
      return 503
    case 'timeout':
      return 504
    default:
      return 200
  }
}

const getMswResponseDelay = () => {
  const responseDelayMilliseconds = getOverride('responseDelayMilliseconds')
  return responseDelayMilliseconds ? Number(responseDelayMilliseconds) : 0
}
