import { ResponseResolver, RestRequest, PathParams, RestContext, DefaultBodyType } from 'msw'

import deCamelize from 'core/helpers/deCamelize'
import { generateMockCase } from 'core/hooks/__mocks__/internal/cases'

import { mswResponseHandler } from '../helpers/mswResponseHandler'

const caseTypes = [
  'generic',
  'bankruptcy',
  'ceaseCommunication',
  'collection',
  'deceased',
  'disputeOfDept',
  'federalEmergency',
  'identityTheft',
  'legalAction',
  'militaryDuty',
  'debtValidation',
  'oFACSanctionedIndividual',
  'disputeOfPurchase',
  'creditBureauDispute',
]

const mockedCases = [
  ...caseTypes.map((caseType) => generateMockCase({ caseType, name: deCamelize(caseType) })),
  ...caseTypes.map((caseType) =>
    generateMockCase({
      caseType,
      name: deCamelize(caseType),
      status: 'canceled',
    }),
  ),
]

export const resolveGetCases: ResponseResolver<RestRequest<never, PathParams<string>>, RestContext, DefaultBodyType> = (
  _req,
  res,
  ctx,
) => {
  return mswResponseHandler({
    ctx,
    res,
    id: 'getCases',
    data: mockedCases,
  })
}
